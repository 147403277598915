import React, { useEffect, useMemo, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { Input, Button, Form, Pagination, Select, Popconfirm, Drawer, Radio, Switch, Row, Col } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { deleteApi } from 'redux/sagas/deleteApiSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { SERVER_IP } from 'assets/Config';
import { getDateFormat } from 'services/Utils';
import { putApi } from 'redux/sagas/putApiSaga';
import { API_STATUS } from 'constants/app-constants';
import TableComponent from 'components/table-component';
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { generatePagination } from 'helpers';

const initialPageSize = 10;
const initialPageSizeOptions = [10, 15, 20];

const Items = React.memo((props) => {
	const itemRedux = useSelector((state) => state.itemRedux);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [tableData, setTableData] = useState(itemRedux.items);
	const [showAddItemModal, setShowAddItemModal] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const [isStock, setIsStock] = useState(false);
	const dispatch = useDispatch();

	const [showEditItemModal, setShowEditItemModal] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const [formInstance] = Form.useForm();

	const getItems = React.useCallback(() => {
		let url = `${SERVER_IP}item?orgId=${globalRedux?.selectedBranch?.id}`;
		dispatch(getApi('GET_ITEMS', url));
	}, [dispatch, globalRedux?.selectedBranch?.id]);

	useEffect(() => {
		!showAddItemModal && setIsStock(false);
	}, [showAddItemModal]);

	useEffect(() => {
		getItems();
	}, [getItems]);

	useEffect(() => {
		generatePagination(tableData);
	}, [tableData]);

	useEffect(() => {
		setTableData(itemRedux.items);
	}, [itemRedux.items]);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_ITEM === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_ITEM'));
			doIt = true;
		} else if (globalRedux.apiStatus.ADD_ITEM === 'SUCCESS') {
			setShowAddItemModal(false);
			formInstance.resetFields();
			doIt = true;
			dispatch(resetApiStatus('ADD_ITEM'));
		} else if (globalRedux.apiStatus.EDIT_ITEM === 'SUCCESS') {
			setShowEditItemModal(false);
			formInstance.resetFields();
			doIt = true;
			dispatch(resetApiStatus('EDIT_ITEM'));
		}
		if (doIt) {
			let url = `${SERVER_IP}item?orgId=${globalRedux?.selectedBranch?.id}`;
			dispatch(getApi('GET_ITEMS', url));
		}
	}, [globalRedux.apiStatus, dispatch, globalRedux?.selectedBranch?.id, formInstance]);

	const column = [
		{
			title: '#',
			dataIndex: 'itemNumber',
			sorter: (a, b) => a?.itemNumber - b?.itemNumber,
			// sorter: {
			// 	compare: (a, b) => {
			// 		if (a.itemNumber < b.itemNumber) {
			// 			return -1;
			// 		}
			// 		if (a.itemNumber > b.itemNumber) {
			// 			return 1;
			// 		}
			// 		return 0;
			// 	},
			// },
			align: 'right',
			// fixed: 'left',
		},
		{
			title: 'Type',
			dataIndex: 'itemType',
			sorter: (a, b) => a.itemType?.localeCompare(b.itemType),
			// sorter: {
			// 	compare: (a, b) => {
			// 		if (a.itemType < b.itemType) {
			// 			return -1;
			// 		}
			// 		if (a.itemType > b.itemType) {
			// 			return 1;
			// 		}
			// 		return 0;
			// 	},
			// },
			align: 'left',
		},
		// {
		// 	title: 'Item Category',
		// 	dataIndex: 'itemCategory',
		// 	sorter: {
		// 		compare: (a, b) => {
		// 			if (a.itemType < b.itemType) {
		// 				return -1;
		// 			}
		// 			if (a.itemType > b.itemType) {
		// 				return 1;
		// 			}
		// 			return 0;
		// 		},
		// 	},
		// 	align: 'left',
		// },
		{
			title: 'Item Code',
			dataIndex: 'barCode',
			sorter: (a, b) => a.barCode?.localeCompare(b.barCode),
			// sorter: {
			// 	compare: (a, b) => {
			// 		if (a.barCode < b.barCode) {
			// 			return -1;
			// 		}
			// 		if (a.barCode > b.barCode) {
			// 			return 1;
			// 		}
			// 		return 0;
			// 	},
			// },
			align: 'left',
		},
		{
			title: 'Item Name',
			dataIndex: 'itemName',
			align: 'left',
			sorter: (a, b) => a.itemName?.localeCompare(b.itemName),
			// sorter: {
			// 	compare: (a, b) => {
			// 		if (a.itemName < b.itemName) {
			// 			return -1;
			// 		}
			// 		if (a.itemName > b.itemName) {
			// 			return 1;
			// 		}
			// 		return 0;
			// 	},
			// },
		},
		{
			title: 'Print Name',
			dataIndex: 'printName',
			align: 'left',
		},
		{
			title: 'HsnSac',
			dataIndex: 'hsnSac',
			align: 'left',
		},
		{
			title: 'Unit',
			dataIndex: 'unitName',
			align: 'left',
		},
		{
			title: 'GST %',
			dataIndex: 'taxRate',
			align: 'left',
		},
		{
			title: 'Selling Rate',
			dataIndex: 'sellingPrice',
			align: 'left',
			sorter: (a, b) => a?.sellingPrice - b?.sellingPrice,
		},
		{
			title: 'MRP',
			dataIndex: 'mrp',
			align: 'left',
			sorter: (a, b) => a?.mrp - b?.mrp,
		},
		// {
		// 	title: 'Stock',
		// 	dataIndex: 'contact',
		// 	align: 'left',
		// },
		{
			title: 'Created Date',
			dataIndex: 'created_at',
			align: 'left',
			sorter: {
				compare: (a, b) => new Date(a.created_at) - new Date(b.created_at),
			},
			render: (value) => getDateFormat(value),
		},
		{
			title: 'Action',
			dataIndex: 'itemName',
			render: (value, row, index) => {
				return (
					<Row>
						<Col
							className="edit_icon"
							onClick={() => {
								setSelectedItem(row);
								formInstance.setFieldsValue(row);
								setShowEditItemModal(true);
							}}>
							<EditOutlined />
						</Col>
						<Col className="delete_icon">
							<Popconfirm
								title={`Are You Sure to Delete ${value} ?`}
								okText="Delete"
								cancelText="No"
								onConfirm={() => {
									let url = `${SERVER_IP}item/${row._id}?orgId=${globalRedux?.selectedBranch?.id}`;
									dispatch(deleteApi('DELETE_ITEM', url));
								}}>
								<CloseOutlined />
							</Popconfirm>
						</Col>
					</Row>
				);
			},
			fixed: 'right',
		},
	];

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const FormCol = {
		labelCol: {
			span: 24,
		},
		wrapperCol: {
			span: 24,
		},
	};

	const addItem = (values) => {
		let data = {
			...values,
			// openingStock: 0,
			orgId: globalRedux?.selectedBranch?.id,
		};
		dispatch(postApi(data, 'ADD_ITEM'));
	};

	const editItem = (values) => {
		console.log(values);
		let data = {
			...values,
			// itemCategory: 'as',
			// openingStock: 0,
			orgId: globalRedux?.selectedBranch?.id,
		};

		let url = `${SERVER_IP}item/${selectedItem._id}?orgId=${globalRedux?.selectedBranch?.id}`;
		dispatch(putApi(data, 'EDIT_ITEM', url));
	};

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_ITEMS === API_STATUS.PENDING, [globalRedux.apiStatus]);
	const isLoading = globalRedux.apiStatus.ADD_ITEM === 'PENDING';
	// console.log('🚀 ~ file: Items.jsx:300 ~ Items ~ ADD_ITEM', isLoading, globalRedux.apiStatus.ADD_ITEM);

	return (
		<>
			<Row>
				<Col span={24} style={{ padding: 20 }}>
					<TableComponent
						className="custom-table"
						style={{ width: '100%' }}
						scroll={{ x: 'auto' }}
						columns={column}
						loading={tableLoading}
						rowKey={(record) => record._id}
						dataSource={tableData}
						title={() => (
							<Row style={{ justifyContent: 'space-between' }}>
								<Col span={6}>
									<Input
										placeholder="Search"
										suffix={<AiOutlineSearch />}
										style={{ height: '30px' }}
										onChange={(e) => {
											if (e.target.value.length > 0) {
												let filteredData = itemRedux.items.filter(
													(data) =>
														`${data.salutation}. ${data.firstName} ${data.lastName}`
															.toUpperCase()
															.includes(e.target.value.toUpperCase()) ||
														Object.values(data.billingDetails)
															.join(',')
															.toUpperCase()
															.includes(e.target.value.toUpperCase()) ||
														data.contact.toUpperCase().includes(e.target.value.toUpperCase()) ||
														data.gstin.toUpperCase().includes(e.target.value.toUpperCase()) ||
														getDateFormat(data.created_at).toUpperCase().includes(e.target.value.toUpperCase())
												);
												setTableData(filteredData);
											} else {
												setTableData(itemRedux.items);
											}
										}}
									/>
								</Col>
								<Col>
									<Button
										// icon={<PlusOutlined />}
										type="primary"
										style={{ height: '30px', width: '100%' }}
										onClick={() => {
											setShowAddItemModal(true);
										}}>
										Create Item
									</Button>
								</Col>
							</Row>
						)}
						pagination={{
							current: currentPage,
							pageSize: pageSize,
							position: ['none', 'none'],
						}}
						footer={() => (
							<Row>
								<Col>
									<div>
										{!!tableData.length && `Showing ${getStartingValue()} - ${getEndingValue()} of ${tableData.length} Data`}
									</div>
								</Col>

								<Col md={8}>
									<div style={{ textAlign: 'right' }}>
										<Pagination
											pageSizeOptions={initialPageSizeOptions}
											defaultPageSize={initialPageSize}
											showSizeChanger={true}
											total={tableData.length}
											onChange={handleTableChange}
											responsive
										/>
									</div>
								</Col>
							</Row>
						)}
						// onChange={handleTableChange}
					/>
				</Col>
			</Row>
			<Drawer
				title={selectedItem ? 'Edit Item' : 'Add New Item'}
				placement="right"
				width={'40%'}
				visible={showAddItemModal || showEditItemModal}
				destroyOnClose
				closable={false}
				maskClosable={false}
				onClose={() => setShowAddItemModal(false)}>
				<Form
					style={{ padding: '0 20px' }}
					// requiredMark={false}
					colon={true}
					layout="vertical"
					labelAlign="left"
					form={formInstance}
					onFinish={selectedItem ? editItem : addItem}
					{...FormCol}>
					<Row gutter={[10, 10]}>
						<Col span={12}>
							<Form.Item
								label="Goods/Service"
								name="itemType"
								rules={[
									{
										required: true,
										message: 'This Field is Required!',
									},
								]}>
								<Radio.Group>
									<Radio value={'Goods'}>Goods</Radio>
									<Radio value={'Services'}>Services</Radio>
								</Radio.Group>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label="Category"
								name="itemCategory"
								rules={[
									{
										required: true,
										message: 'This Field is Required!',
									},
								]}>
								<Select placeholder="Select">
									<Select.Option value="General">General</Select.Option>
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={[10, 10]}>
						<Col span={12}>
							<Form.Item
								label="Bar Code"
								name="barCode"
								rules={[
									{
										required: true,
										message: 'This Field is Required!',
									},
								]}>
								<Input />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label="Item Name"
								name="itemName"
								rules={[
									{
										required: true,
										message: 'This Field is Required!',
									},
								]}>
								<Input />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={[10, 10]}>
						<Col span={12}>
							<Form.Item
								label="Print Name"
								name="printName"
								rules={[
									{
										required: true,
										message: 'This Field is Required!',
									},
								]}>
								<Input />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label="Hsn/Sac Code"
								name="hsnSac"
								rules={[
									{
										required: true,
										message: 'This Field is Required!',
									},
								]}>
								<Input />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={[10, 10]}>
						<Col span={12}>
							<Form.Item
								label="MRP"
								name="mrp"
								rules={[
									{
										required: true,
										message: 'This Field is Required!',
									},
								]}>
								<Input />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label="Selling Price"
								name="sellingPrice"
								rules={[
									{
										required: true,
										message: 'This Field is Required!',
									},
								]}>
								<Input />
								{/* <InputNumber style={{ width: '100%' }} /> */}
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={[10, 10]}>
						<Col span={12}>
							<Form.Item
								label="Unit"
								name="unitName"
								rules={[
									{
										required: true,
										message: 'This Field is Required!',
									},
								]}>
								<Select placeholder="Select">
									<Select.Option value="nos">Nos</Select.Option>
									<Select.Option value="m3">M3</Select.Option>
									<Select.Option value="pcs">Pcs</Select.Option>
								</Select>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label="GST"
								name="taxRate"
								rules={[
									{
										required: true,
										message: 'This Field is Required!',
									},
								]}>
								<Select placeholder="Select">
									<Select.Option value="0">GST 0%</Select.Option>
									<Select.Option value="5">GST 5%</Select.Option>
									<Select.Option value="12">GST 12%</Select.Option>
									<Select.Option value="18">GST 18%</Select.Option>
									<Select.Option value="28">GST 28%</Select.Option>
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={[10, 10]}>
						<Col span={12}>
							<Form.Item
								label="Inventory"
								name="isStock"
								initialValue={false}
								valuePropName="checked"
								rules={[
									{
										required: true,
										message: 'This Field is Required!',
									},
								]}>
								<Switch
									onChange={(checked) => setIsStock(checked)}
									checkedChildren={<CheckOutlined />}
									unCheckedChildren={<CloseOutlined />}
								/>
							</Form.Item>
						</Col>
						{isStock && (
							<Col span={12}>
								<Form.Item
									label="Serial?"
									name="isSerial"
									initialValue={false}
									valuePropName="checked"
									rules={[
										{
											required: true,
											message: 'This Field is Required!',
										},
									]}>
									<Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
								</Form.Item>
							</Col>
						)}
					</Row>
					{isStock && (
						<Row gutter={[10, 10]}>
							<Col span={12}>
								<Form.Item
									label="Opening Stock"
									name="openingStock"
									rules={[
										{
											required: true,
											message: 'This Field is Required!',
										},
									]}>
									<Input />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									label="Opening Stock Rate"
									name="openingStockValue"
									rules={[
										{
											required: true,
											message: 'This Field is Required!',
										},
									]}>
									<Input />
								</Form.Item>
							</Col>
						</Row>
					)}
					<Form.Item
						wrapperCol={{
							offset: 0,
							span: 24,
						}}>
						<Row gutter={[10, 10]}>
							<Col span={12}>
								<Button style={{ width: '100%' }} loading={isLoading} disabled={isLoading} type="primary" htmlType="submit">
									Create
								</Button>
							</Col>
							<Col span={12}>
								<Button
									style={{ width: '100%' }}
									onClick={() => {
										showAddItemModal && setShowAddItemModal(false);
										showEditItemModal && setShowEditItemModal(false);
										formInstance.resetFields();
									}}
									danger>
									Cancel
								</Button>
							</Col>
						</Row>
					</Form.Item>
				</Form>
			</Drawer>
		</>
	);
});

export default Items;
