import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col, Image } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { SERVER_IP } from 'assets/Config';
import HighlightComponent from 'components/HighlightComponent';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { convertToIndianRupees, formQueryStringFromObject, generatePagination } from 'helpers';
import { API_STATUS } from 'constants/app-constants';
import { getDateFormat } from 'services/Utils';
import ProductListPresentational from './product-list-presentational';
import ProteinSVG from '../../../../assets/images/protein.svg';

const initialPageSize = 10;
const initialPageSizeOptions = [10, 15, 20];

const ProductListFunctional = React.memo(() => {
	const productRedux = useSelector((state) => state.productRedux);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const dispatch = useDispatch();

	const [productAddModal, setProductAddModal] = useState(false);
	const [searchKey, setSearchKey] = useState('');
	const [tableData, setTableData] = useState(productRedux.products);
	const [editProduct, setEditProduct] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);

	const getProducts = useCallback(() => {
		const string = formQueryStringFromObject({
			branchId: globalRedux?.selectedBranch?.id,
		});
		let url = `${SERVER_IP}api/products?${string}`;
		dispatch(getApi('GET_PRODUCTS', url));
	}, [dispatch, globalRedux?.selectedBranch?.id]);

	useEffect(() => {
		getProducts();
	}, [getProducts]);

	useEffect(() => {
		generatePagination(tableData);
	}, [tableData]);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_PRODUCT === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_PRODUCT'));
			setSelectedRowKeys([]);
			doIt = true;
		}
		if (doIt) {
			getProducts();
		}
	}, [globalRedux.apiStatus.DELETE_PRODUCT, dispatch, getProducts]);

	useEffect(() => {
		setTableData(productRedux.products);
	}, [productRedux.products]);

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((product) => {
			return (product?.productName || '')?.toLowerCase().includes(searchKey.toLowerCase());
		});
	}, [tableData, searchKey]);

	const handleDrawer = (rowData) => {
		setEditProduct(rowData);
		setProductAddModal(true);
	};

	const handleAddProduct = () => {
		setEditProduct(null);
		setProductAddModal(true);
	};

	const column = [
		{
			title: '#',
			dataIndex: 'productNumber',
			key: 'productNumber',
			// fixed: 'left',
			render: (value, record, index) => <div>{index + 1}</div>,
		},
		{
			title: 'Product Name',
			dataIndex: 'productName',
			key: 'productName',
			sorter: (a, b) => a?.productName?.localeCompare(b?.productName),
			render: (value, record) => (
				<Row align={'middle'}>
					<Col
						style={{
							paddingRight: '10px',
						}}>
						{record?.productImages?.[0]?.url ? (
							<Image
								src={SERVER_IP + record?.productImages[0]?.url}
								alt="Avatar"
								style={{ width: '40px', height: '40px', borderRadius: 5 }}
							/>
						) : (
							<Image src={ProteinSVG} alt="Avatar" style={{ width: '40px', height: '40px', borderRadius: 5 }} />
						)}
					</Col>
					<div style={{ fontWeight: 'bold' }}>
						<HighlightComponent
							highlightClassName="highlightClass"
							searchWords={[searchKey]}
							autoEscape={true}
							textToHighlight={value || '-'}
						/>
					</div>
				</Row>
			),
		},
		{
			title: 'Count In Stock',
			dataIndex: 'countInStock',
			align: 'left',
			width: '15%',
		},
		{
			title: 'Original Price',
			dataIndex: 'originalPrice',
			align: 'left',
			width: '15%',
			render: (value) => <span>{convertToIndianRupees(value || 0)}</span>,
		},
		{
			title: 'Offer Price',
			dataIndex: 'offerPrice',
			align: 'left',
			width: '15%',
			render: (value) => <span>{convertToIndianRupees(value || 0)}</span>,
		},
		{
			title: 'Created Date',
			dataIndex: 'created_at',
			align: 'left',
			width: '15%',
			render: (value) => getDateFormat(value),
		},
		{
			title: 'Action',
			align: 'center',
			dataIndex: 'productName',
			render: (value, row, index) => {
				return (
					<Row justify="center">
						<Col className="edit_icon" onClick={() => handleDrawer(row)}>
							<EditOutlined />
						</Col>
					</Row>
				);
			},
		},
	];

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_PRODUCTS === API_STATUS.PENDING, [globalRedux.apiStatus]);

	const onSelectChange = (selectedRowKeys) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		// fixed: true,
	};

	const handleClose = useCallback(() => {
		setProductAddModal(false);
		setEditProduct(null);
	}, [setProductAddModal, setEditProduct]);

	return (
		<ProductListPresentational
			{...{
				filteredData,
				column,
				tableLoading,
				rowSelection,
				selectedRowKeys,
				handleAddProduct,
				currentPage,
				pageSize,
				initialPageSizeOptions,
				initialPageSize,
				handleTableChange,
				setSearchKey,
				getStartingValue,
				getEndingValue,
				productAddModal,
				setProductAddModal,
				refreshList: getProducts,
				editProduct,
				handleClose,
			}}
		/>
	);
});

export default ProductListFunctional;
