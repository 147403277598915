import React from 'react';
import { Route, Routes as Switch } from 'react-router-dom';
import PageLayout from 'components/page-layout';
import Register from 'pages/pre_login_pages/register';
import Login from 'pages/pre_login_pages/login';
import { ROUTES, SUPER_ADMIN_EMAILS, SUPER_ADMIN_ROUTES } from 'constants/app-constants';
import OrganizationList from '../pages/organization';
import NoAccess from 'components/no-access';
import ProtectedRoute from './protected-route';
import { useSelector } from 'react-redux';

const Routes = () => {
	const loginRedux = useSelector((state) => state.loginRedux);
	return (
		<div
			className="app_container"
			style={
				{
					// overflowY: 'auto',
					// overflowX: 'hidden',
					// minHeight: '100vh',
				}
			}>
			<Switch>
				<Route exact path="/login" element={<Login />} />
				<Route exact path="/register" element={<Register />} />
				<Route exact path="/branches" element={<ProtectedRoute><OrganizationList /></ProtectedRoute>} />
				<Route exact element={<PageLayout />}>
					{(SUPER_ADMIN_EMAILS.includes(loginRedux.email) ? SUPER_ADMIN_ROUTES : ROUTES).map(({ route, Component }, index) => {
						return (
							<Route
								exact
								key={index}
								path={route}
								element={
									<ProtectedRoute>
										<Component />
									</ProtectedRoute>
								}
							/>
						);
					})}
					{/* <Route path="/loading" element={<Master />} /> */}
					<Route exact path="*" element={<NoAccess />} />
				</Route>
			</Switch>
		</div>
	);
};

export default Routes;
