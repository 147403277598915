let initialState = {
    access : [],
};

const accessReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_COACH_ACCESS" : 
      return {
        ...state,
        access : action.payload
      }   
    default : return state
  }

}

export { accessReducer }
