import React from 'react';
import { Button, Col, DatePicker, Divider, Form, Input, Row, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { TbFileInvoice } from 'react-icons/tb';
import TableComponent from 'components/table-component';
import AddDrawer from 'components/drawer-component';
import { DATE_FORMAT } from 'constants/app-constants';

const layer1FormCol = {
	labelCol: {
		span: 12,
	},
	wrapperCol: {
		span: 12,
	},
};

const AddInvoicePresentational = ({
	columns,
	dataSource,
	customers,
	customerAddModal,
	setCustomerAddModal,
	getCustomers,
	itemAddModal,
	setItemAddModal,
	getItems,
	DraggableContainer,
	DraggableBodyRow,
}) => {
	const [form] = Form.useForm();
	return (
		<Row className="new_invoice_container">
			<Col span={24}>
				<Col span={24} className="header">
					<TbFileInvoice className="icon" />
					<span className="title">New Invoice</span>
				</Col>
			</Col>
			<Col span={24}>
				<Form name="add-vehicle" style={{}} colon={true} labelAlign="left" form={form} {...layer1FormCol}>
					<Row>
						<Col span={12}>
							<Form.Item
								wrapperCol={{
									span: 12,
								}}
								labelCol={{
									span: 8,
								}}
								className="ele_padding"
								label="Customer Name"
								name="customerName"
								// initialValue={editVehicle?.vehicleName}
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<Select
									placeholder="select customer"
									showSearch
									optionFilterProp="children"
									filterOption={(input, option) => option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
									filterSort={(optionA, optionB) => optionA.children?.toLowerCase().localeCompare(optionB.children?.toLowerCase())}
									dropdownRender={(menu) => (
										<div>
											{menu}
											<Divider />
											<div style={{ display: 'flex', flexWrap: 'nowrap' }}>
												<a
													href
													style={{ flex: 'none', color: '#188dfa', padding: '8px', display: 'block', cursor: 'pointer' }}
													onClick={() => setCustomerAddModal(true)}>
													<PlusOutlined /> Customer
												</a>
											</div>
										</div>
									)}>
									{customers?.map((customer) => (
										<Select.Option value={customer?._id}>{customer?.displayName}</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								label="Invoice #"
								name="invoiceNumber"
								wrapperCol={{
									span: 12,
								}}
								labelCol={{
									span: 8,
								}}
								// initialValue={editVehicle?.vehicleType}
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<Input placeholder="enter invoice number" />
							</Form.Item>
							<Form.Item
								label="Invoice date"
								name="invoiceDate"
								wrapperCol={{
									span: 12,
								}}
								labelCol={{
									span: 8,
								}}
								initialValue={moment()}
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<DatePicker format={DATE_FORMAT.DD_MM_YYYY} style={{ width: '100%' }} placeholder="enter invoice date" />
							</Form.Item>
							<Form.Item
								label="Due date"
								name="dueDate"
								wrapperCol={{
									span: 12,
								}}
								labelCol={{
									span: 8,
								}}
								initialValue={moment()}
								rules={[
									{
										required: false,
										message: 'This Field is required!',
									},
								]}>
								<DatePicker format={DATE_FORMAT.DD_MM_YYYY} style={{ width: '100%' }} placeholder="enter due date" />
							</Form.Item>
							<Form.Item
								label="Order Number"
								name="orderNumber"
								wrapperCol={{
									span: 12,
								}}
								labelCol={{
									span: 8,
								}}
								// initialValue={editVehicle?.vehicleType}
								rules={[
									{
										required: false,
										message: 'This Field is required!',
									},
								]}>
								<Input placeholder="enter order number" />
							</Form.Item>
							<Form.Item
								wrapperCol={{
									span: 12,
								}}
								labelCol={{
									span: 8,
								}}
								label="Sales Person"
								name="salesPerson"
								// initialValue={editVehicle?.vehicleName}
								rules={[
									{
										required: false,
										message: 'This Field is required!',
									},
								]}>
								<Select
									placeholder="select sales person"
									showSearch
									optionFilterProp="children"
									filterOption={(input, option) => option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
									filterSort={(optionA, optionB) => optionA.children?.toLowerCase().localeCompare(optionB.children?.toLowerCase())}
									dropdownRender={(menu) => (
										<div>
											{menu}
											<Divider />
											<div style={{ display: 'flex', flexWrap: 'nowrap' }}>
												<a
													href
													style={{ flex: 'none', color: '#188dfa', padding: '8px', display: 'block', cursor: 'pointer' }}
													// onClick={() => setSupplierAddModal(true)}
												>
													<PlusOutlined /> Sales Person
												</a>
											</div>
										</div>
									)}>
									{customers?.map((customer) => (
										<Select.Option value={customer?._id}>{customer?.displayName}</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								label="Subject"
								name="subject"
								wrapperCol={{
									span: 12,
								}}
								labelCol={{
									span: 8,
								}}
								// initialValue={editVehicle?.vehicleType}
								rules={[
									{
										required: false,
										message: 'This Field is required!',
									},
								]}>
								<Input.TextArea placeholder="Let your customer know what this Invoice is for" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label="Billing Address"
								name="billingAddress"
								wrapperCol={{
									span: 12,
								}}
								labelCol={{
									span: 8,
								}}
								// initialValue={editVehicle?.vehicleType}
								rules={[
									{
										required: false,
										message: 'This Field is required!',
									},
								]}>
								<Input.TextArea placeholder="enter billing address" />
							</Form.Item>
							<Form.Item
								label="Shipping Address"
								name="shippingAddress"
								wrapperCol={{
									span: 12,
								}}
								labelCol={{
									span: 8,
								}}
								// initialValue={editVehicle?.vehicleType}
								rules={[
									{
										required: false,
										message: 'This Field is required!',
									},
								]}>
								<Input.TextArea placeholder="enter shipping address" />
							</Form.Item>
						</Col>
					</Row>
					{/* <Form.Item
						wrapperCol={{
							offset: 0,
							span: 24,
						}}>
						<Row className="space-between" style={{ paddingTop: 20, width: '100%', margin: 0 }}>
							<Button danger style={{ width: '49%' }}>
								Cancel
							</Button>
							<Button loading={false} style={{ width: '49%' }} type="primary" htmlType="submit">
								Save
							</Button>
						</Row>
					</Form.Item> */}
					<Col span={18}>
						<TableComponent
							pagination={false}
							columns={columns}
							dataSource={dataSource}
							// components={{
							// 	body: {
							// 		wrapper: DraggableContainer,
							// 		row: DraggableBodyRow,
							// 	},
							// }}
						/>
					</Col>
					<Col span={24} style={{ paddingTop: 20 }}>
						<Form.Item
							label="Customer Notes"
							name="notes"
							wrapperCol={{
								span: 6,
							}}
							labelCol={{
								span: 4,
							}}
							// initialValue={editVehicle?.vehicleType}
							rules={[
								{
									required: false,
									message: 'This Field is required!',
								},
							]}>
							<Input.TextArea placeholder="enter your notes id any.." />
						</Form.Item>
					</Col>
				</Form>
			</Col>
			<Col span={24} className={'footer'}>
				{/* <Button type="ghost" style={{ marginRight: 10 }}>
					Save as Draft
				</Button> */}
				<Button type="primary" style={{ marginRight: 10 }} onClick={form?.submit}>
					Save and Send
				</Button>
				<Button type="ghost">Close</Button>
			</Col>

			<AddDrawer {...{ customerAddModal, setCustomerAddModal, getCustomers, itemAddModal, setItemAddModal, getItems }} />
		</Row>
	);
};

export default AddInvoicePresentational;
