let initialState = {
    services : [],
};

const serviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SERVICES" : 
      return {
        ...state,
        services : action.payload
      }   
    default : return state
  }

}

export { serviceReducer }
