import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { SERVER_IP } from 'assets/Config';
import HighlightComponent from 'components/HighlightComponent';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { formQueryStringFromObject, generatePagination } from 'helpers';
import { API_STATUS, DATE_FORMAT } from 'constants/app-constants';
import { getDateFormat } from 'services/Utils';
import CustomerReportListPresentational from './customer-report-list-presentational';
import moment from 'moment';

const initialPageSize = 10;
const initialPageSizeOptions = [10, 15, 20];

const CustomerReportListFunctional = React.memo(() => {
	const [filterOptions, setFilterOptions] = useState({
		fromDate: '',
		toDate: '',
	});
	const customerReportRedux = useSelector((state) => state.customerReportRedux);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [searchKey, setSearchKey] = useState('');
	const [tableData, setTableData] = useState(customerReportRedux.customerReports);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const dispatch = useDispatch();

	const getReports = useCallback(() => {
		const string = formQueryStringFromObject({
			branchId: globalRedux?.selectedBranch?.id,
			...filterOptions,
			type: 'customer',
		});
		let url = `${SERVER_IP}api/report?${string}`;
		dispatch(getApi('GET_CUSTOMER_REPORTS', url));
	}, [dispatch, globalRedux?.selectedBranch?.id, filterOptions]);

	useEffect(() => {
		getReports();
	}, [getReports, filterOptions]);

	useEffect(() => {
		generatePagination(tableData);
	}, [tableData]);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_COACH === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_COACH'));
			setSelectedRowKeys([]);
			doIt = true;
		}
		if (doIt) {
			getReports();
		}
	}, [globalRedux.apiStatus.DELETE_COACH, dispatch, getReports]);

	useEffect(() => {
		setTableData(customerReportRedux.customerReports);
	}, [customerReportRedux.customerReports]);

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((report) => {
			return (
				(report?.reportName || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(report?.mobile || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(report?.email || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(report?.address || '')?.toString().toLowerCase().includes(searchKey.toLowerCase())
				// (report?.outstandingBalance || '')?.toString().toLowerCase().includes(searchKey.toLowerCase())
			);
		});
	}, [tableData, searchKey]);

	const handleFilterChange = (key, value) => {
		console.log('🚀 ~ file: customer-report-list-functional.js:80 ~ handleFilterChange ~ key, value:', key, value);
		if (key === 'date') {
			setFilterOptions({
				fromDate: value ? moment(value?.[0]).toISOString() : '',
				toDate: value ? moment(value?.[1]).toISOString() : '',
			});
		}
	};

	const column = useMemo(
		() => [
			{
				title: '#',
				dataIndex: 'customerNumber',
				key: 'customerNumber',
				// // fixed: 'left',
				render: (value, record, index) => <div>{index + 1}</div>,
			},
			{
				title: 'Customer Name',
				dataIndex: 'customerName',
				key: 'customerName',
				sorter: (a, b) => a?.customerName?.localeCompare(b?.customerName),
				// // fixed: 'left',
				width: '15%',
				render: (value) => (
					<div style={{ fontWeight: 'bold' }}>
						<HighlightComponent
							highlightClassName="highlightClass"
							searchWords={[searchKey]}
							autoEscape={true}
							textToHighlight={value || '-'}
						/>
					</div>
				),
			},
			{
				title: 'Mobile',
				dataIndex: 'mobile',
				align: 'left',
				render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />,
			},
			{
				title: 'Email',
				dataIndex: 'email',
				sorter: (a, b) => a?.email?.localeCompare(b?.email),
				align: 'left',
				width: '15%',
				render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />,
			},
			{
				title: 'Address',
				dataIndex: 'address',
				sorter: (a, b) => a?.address?.localeCompare(b?.address),
				align: 'left',
				width: '15%',
				render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={value || '-'} />,
			},
			{
				title: 'Joined on',
				dataIndex: 'joiningDate',
				align: 'left',
				width: '10%',
				render: (value) => getDateFormat(value, DATE_FORMAT.DD_MM_YYYY),
			},
			{
				title: 'Onboarded By',
				dataIndex: 'coach_ma',
				align: 'left',
				width: '12%',
				render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={value?.coachName || '-'} />,
			},
			{
				title: 'Next Bill Date',
				dataIndex: 'paymentId',
				align: 'left',
				width: '15%',
				render: (value) => getDateFormat(value?.nextBillDate, DATE_FORMAT.DD_MM_YYYY),
			},
			{
				title: 'Created Date',
				dataIndex: 'created_at',
				align: 'left',
				width: '15%',
				render: (value) => getDateFormat(value),
			},
		],
		[searchKey]
	);

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_CUSTOMER_REPORTS === API_STATUS.PENDING, [globalRedux.apiStatus]);

	// const onSelectChange = (selectedRowKeys) => {
	// 	setSelectedRowKeys(selectedRowKeys);
	// };

	// const rowSelection = {
	// 	selectedRowKeys,
	// 	onChange: onSelectChange,
	// 	// fixed: true,
	// };

	const exportData = useMemo(() => {
		return {
			headers: column?.map((col) => col?.title) || [],
			data:
				filteredData.map((stock, index) => ({
					[column[0]?.title]: index + 1,
					[column[1]?.title]: stock?.customerName,
					[column[2]?.title]: stock?.mobile,
					[column[3]?.title]: stock?.email,
					[column[4]?.title]: stock?.address,
					[column[5]?.title]: moment(stock?.joiningDate).format(DATE_FORMAT.DD_MM_YYYY),
					[column[6]?.title]: stock?.onBoardedBy?.coachName,
					[column[7]?.title]: moment(stock?.paymentId?.nextBillDate).format(DATE_FORMAT.DD_MM_YYYY),
					[column[8]?.title]: moment(stock?.created_at).format(DATE_FORMAT.DD_MM_YYYY),
				})) || [],
		};
	}, [filteredData, column]);

	return (
		<CustomerReportListPresentational
			{...{
				filteredData,
				column,
				tableLoading,
				// rowSelection,
				selectedRowKeys,
				currentPage,
				pageSize,
				initialPageSizeOptions,
				initialPageSize,
				handleTableChange,
				setSearchKey,
				getStartingValue,
				getEndingValue,
				handleFilterChange,
				exportData,
			}}
		/>
	);
});

export default CustomerReportListFunctional;
