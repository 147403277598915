function setDashboardData(data) {
	return {
		type: 'GET_DASHBOARD_DATA',
		payload: data,
	};
}

function setProductsSalesData(data) {
	return {
		type: 'GET_PRODUCTS_SALE',
		payload: data,
	};
}

function setMembershipSalesData(data) {
	return {
		type: 'GET_MEMBERSHIP_SALE',
		payload: data,
	};
}

export { setDashboardData, setProductsSalesData, setMembershipSalesData };
