import { call, takeEvery, put } from 'redux-saga/effects';
import { setDashboardData, setMembershipSalesData, setProductsSalesData } from 'redux/reducers/dashboard/dashboardAction';
import { setDailyTransaction } from 'redux/reducers/daily-transaction/dailyTransactionAction';
import { setProduction } from 'redux/reducers/production/productionAction';
import { setInvoices } from 'redux/reducers/invoices/invoiceAction';
import { setCustomerDetails, setCustomers, setCustomersGenderWise, setCustomersPagination } from '../reducers/customers/customerAction';
import { setSuppliers } from '../reducers/suppliers/supplierAction';
import { ACTIONS } from '../../constants/app-constants';
import { store } from '../store';
import { RESTCallError, sendGetRequest } from './utils';
import {
	setApiStatus,
	setBranches,
	setAccountBooks,
	setClasses,
	setAssets,
	setBatch,
	setVoucherHead,
	setVehicles,
	setPartNumbers,
	setStates,
	setVendors,
	setCredentials,
	setUsers,
	setSalesPerson,
} from '../reducers/globals/globalActions';
import { setItems } from '../reducers/items/action';
import { setProjects, setSelectedProject } from '../reducers/projects/action';
import { setAgents } from '../reducers/agent/agentAction';
import { setStudents } from 'redux/reducers/students/studentsActions';
import { setReceipts } from 'redux/reducers/receipts/receiptsActions';
import { setVouchers } from 'redux/reducers/vouchers/voucherAction';
import { setStudentsFees } from 'redux/reducers/studentFees/studentFeesActions';
import { setLoadIn } from 'redux/reducers/load-in/loadInActions';
import { setTicketDetails, setTickets } from 'redux/reducers/tickets/ticketAction';
import { setCoaches } from 'redux/reducers/coaches/coachAction';
import { setMemberships } from 'redux/reducers/memberships/membershipAction';
import { setEditPayment, setPayments, setPaymentsPagination } from 'redux/reducers/payments/paymentAction';
import { setVisitors } from 'redux/reducers/visitors/visitorAction';
import { setProducts } from 'redux/reducers/products/productAction';
import { setCustomerReports } from 'redux/reducers/customer-reports/customerReportAction';
import { setPaymentReports } from 'redux/reducers/payment-reports/paymentReportAction';
import { setServices } from 'redux/reducers/services/serviceAction';
import { setOutstanding } from 'redux/reducers/outstanding/outstandingAction';
import { setGyms } from 'redux/reducers/gyms/gymAction';
import { setCoachAccess } from 'redux/reducers/access/accessAction';
import { setEditPersonalTrainingPayment, setPersonalTraining } from 'redux/reducers/personal-training/personalTrainingAction';
import { GET_EDIT_GYM_PAYMENT, GET_EDIT_PAYMENT, GET_EDIT_PERSONAL_TRAINING_PAYMENT, GET_GYM_SB } from 'redux/reducers/constants';
import { setEditGymPayment, setGymPayments, setGymPaymentsPagination } from 'redux/reducers/gym-payments/paymentAction';
import { setGymsSB } from 'redux/reducers/users/action';
import { setPeriods } from 'redux/reducers/periods/periodsAction';
import { setDietPlan } from 'redux/reducers/diet-plan/dietPlanAction';
import { setFoods } from 'redux/reducers/foods/foodsAction';
import { setDietPlanTemplate } from 'redux/reducers/diet-plan-template/dietPlanTemplateAction';

function getApi(apiName, url, extras) {
	return {
		type: 'GET_API_DATA',
		apiName: apiName,
		url: url,
		extras: extras,
	};
}

function* getApiDataSaga() {
	yield takeEvery('GET_API_DATA', getApiDataWorker);
}

function* getApiDataWorker(param) {
	let url = param.url;
	let apiName = param.apiName;
	try {
		store.dispatch(setApiStatus(apiName, 'PENDING'));
		const result = yield call(sendGetRequest, apiName, url);
		if (result.status === 200) {
			yield getApiDataSuccess(result.data, apiName, param.extras);
			if (result?.data?.paginationDetails) {
				yield getApiDataPagination(result?.data?.paginationDetails, apiName, param.extras);
			}
		} else if (result.status === 'Error') {
			RESTCallError(result, apiName);
		}
	} catch (error) {
		console.log({
			...error,
		});
	}
}

function* getApiDataPagination(paginationDetails, apiName, extras) {
	if (paginationDetails) {
		switch (apiName) {
			case 'GET_CUSTOMERS':
				yield put(setCustomersPagination(paginationDetails));
				break;
			case 'GET_PAYMENTS':
				yield put(setPaymentsPagination(paginationDetails));
				break;
			case 'GET_GYM_PAYMENTS':
				yield put(setGymPaymentsPagination(paginationDetails));
				break;
			default:
				break;
		}
	}
}

function* getApiDataSuccess(response, apiName, extras) {
	switch (apiName) {
		case 'GET_ALL_BRANCHES':
			yield put(setBranches(response?.data || []));
			break;
		case 'GET_ALL_PROJECTS':
			yield put(setProjects(response?.data || []));
			break;
		case 'GET_PROJECT_BY_ID':
			yield put(setSelectedProject(response));
			break;
		case 'SET_SELECTED_CUSTOMER_DETAILS':
			yield put(setCustomerDetails(response?.data || []));
			break;
		case 'GET_CUSTOMERS':
			yield put(setCustomers(response?.data || []));
			break;
		case 'GET_CUSTOMERS_GENDER_WISE':
			yield put(setCustomersGenderWise(response?.data || []));
			break;
		case 'GET_COACHES':
			yield put(setCoaches(response?.data || []));
			break;
		case 'GET_ACCESS_FOR_COACH':
			yield put(setCoachAccess(response?.data || []));
			break;
		case 'GET_GYMS':
			yield put(setGyms(response?.data || []));
			break;
		case 'GET_GYM_PAYMENTS':
			yield put(setGymPayments(response?.data || []));
			break;
		case 'GET_CUSTOMER_REPORTS':
			yield put(setCustomerReports(response?.data || []));
			break;
		case 'GET_PAYMENT_REPORTS':
			yield put(setPaymentReports(response?.data || []));
			break;
		case 'GET_PAYMENTS':
			yield put(setPayments(response?.data || []));
			break;
		case GET_EDIT_PAYMENT:
			yield put(setEditPayment(response?.data || {}));
			break;
		case GET_EDIT_GYM_PAYMENT:
			yield put(setEditGymPayment(response?.data || {}));
			break;
		case 'GET_OUTSTANDING':
			yield put(setOutstanding(response?.data || []));
			break;
		case 'GET_MEMBERSHIPS':
			yield put(setMemberships(response?.data || []));
			break;
		case 'GET_SERVICES':
			yield put(setServices(response?.data || []));
			break;
		case 'GET_PERIODS':
			yield put(setPeriods(response?.data || []));
			break;
		case 'GET_FOODS':
			yield put(setFoods(response?.data || []));
			break;
		case 'GET_PRODUCTS':
			yield put(setProducts(response?.data || []));
			break;
		case 'GET_PERSONAL_TRAINING':
			yield put(setPersonalTraining(response?.data || []));
			break;
		case 'GET_DIET_PLAN':
			yield put(setDietPlan(response?.data || []));
			break;
		case 'GET_DIET_PLAN_TEMPLATE':
			yield put(setDietPlanTemplate(response?.data || []));
			break;
		case GET_EDIT_PERSONAL_TRAINING_PAYMENT:
			yield put(setEditPersonalTrainingPayment(response?.data || []));
			break;
		case 'GET_VISITORS':
			yield put(setVisitors(response?.data || []));
			break;
		case 'GET_SUPPLIERS':
			yield put(setSuppliers(response?.data || []));
			break;
		case 'GET_ITEMS':
			yield put(setItems(response?.data || []));
			break;
		case 'GET_PROJECTS':
			yield put(setProjects(response?.data || []));
			break;
		case 'GET_CLASSES':
			yield put(setClasses(response?.data || []));
			break;
		case 'GET_SUB_ITEMS':
			yield put(setItems(response?.data || []));
			break;
		case 'GET_AGENTS':
			yield put(setAgents(response?.data || []));
			break;
		case 'GET_DAILY_TRANSACTION_LIST':
			yield put(setDailyTransaction(response?.data || []));
			break;
		case 'GET_PRODUCTION_LIST':
			yield put(setProduction(response?.data || []));
			break;
		case 'GET_INVOICES':
			yield put(setInvoices(response?.data || []));
			break;
		case 'GET_VOUCHERS_HEAD':
			yield put(setVoucherHead(response?.data || []));
			break;
		case ACTIONS.GET_ACCOUNT_BOOKS:
			yield put(setAccountBooks(response?.data || []));
			break;
		case ACTIONS.GET_SALES_PERSONS:
			yield put(setSalesPerson(response?.data || []));
			break;
		case 'GET_ASSETS':
			yield put(setAssets(response?.data || []));
			break;
		case 'GET_VEHICLES':
			yield put(setVehicles(response?.data || []));
			break;
		case 'GET_VENDORS':
			yield put(setVendors(response?.data || []));
			break;
		case 'GET_CREDENTIALS':
			yield put(setCredentials(response?.data || []));
			break;
		case 'GET_PART_NUMBERS':
			yield put(setPartNumbers(response?.data || []));
			break;
		case 'GET_USERS':
			yield put(setUsers(response?.data || []));
			break;
		case 'GET_TICKETS':
			yield put(setTickets(response?.data || []));
			break;
		case 'GET_TICKETS_DETAILS':
			yield put(setTicketDetails(response || {}));
			break;
		case 'GET_STUDENTS':
			yield put(setStudents(response?.data || []));
			break;
		case 'GET_STUDENTS_FEES':
			yield put(setStudentsFees(response?.data || []));
			break;
		case 'GET_VOUCHERS':
			yield put(setVouchers(response?.data || []));
			break;
		case 'GET_RECEIPTS':
			yield put(setReceipts(response?.data || []));
			break;
		case 'GET_LOAD_IN':
			yield put(setLoadIn(response?.data || []));
			break;
		case 'GET_BATCHES':
			yield put(setBatch(response?.data || []));
			break;
		case 'GET_DASHBOARD_DATA':
			yield put(setDashboardData(response || []));
			break;
		case 'GET_PRODUCTS_SALE':
			yield put(setProductsSalesData(response || []));
			break;
		case 'GET_MEMBERSHIP_SALE':
			yield put(setMembershipSalesData(response || []));
			break;
		case 'GET_STATES':
			// console.log(response)
			yield put(setStates(response?.data || []));
			break;
		case GET_GYM_SB:
			// console.log(response)
			yield put(setGymsSB(response?.data || []));
			break;
		default:
			break;
	}
	yield put(setApiStatus(apiName, 'SUCCESS'));
}

export { getApi, getApiDataSaga };
