let initialState = {
    coaches : [],
};

const coachReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_COACHES" : 
      return {
        ...state,
        coaches : action.payload
      }   
    default : return state
  }

}

export { coachReducer }
