import React, { useMemo } from 'react';
import { Row, Col, Tabs } from 'antd';
import PaymentListFunctional from './payment-list-functional';
import { useSelector } from 'react-redux';
import { API_STATUS } from 'constants/app-constants';

const { TabPane } = Tabs;

const PaymentsTab = () => {
	const globalRedux = useSelector((state) => state.globalRedux);
	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_PAYMENTS === API_STATUS.PENDING, [globalRedux.apiStatus]);
	return (
		<Row className="payments-tab-container" style={{ height: '80%' }}>
			<Col xl={24} className="container">
				<Tabs  defaultActiveKey="All" destroyInactiveTabPane>
					<TabPane disabled={tableLoading} tab={'Memberships'} key="Memberships">
						<PaymentListFunctional paymentType="membership" />
					</TabPane>
					<TabPane disabled={tableLoading} tab={'Products'} key="Products">
						<PaymentListFunctional paymentType="product" />
					</TabPane>
					<TabPane disabled={tableLoading} tab={'Personal Training'} key="Personal Training">
						<PaymentListFunctional paymentType="personalTraining" />
					</TabPane>
				</Tabs>
			</Col>
		</Row>
	);
};

export default PaymentsTab;
