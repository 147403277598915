import React, { useCallback, useEffect, useMemo } from 'react';
import { Row, Col, Button, Form, Drawer, Radio, Select, InputNumber, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { SERVER_IP } from 'assets/Config';
import { API_STATUS, DATE_FORMAT, PAYMENT_METHOD, PAYMENT_TYPE } from 'constants/app-constants';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { formQueryStringFromObject } from 'helpers';
import { postApi } from 'redux/sagas/postApiDataSaga';
import DatePicker from 'components/date-picker';

const PayNowModal = ({ paynowAddModal, width = '40%', paynowDetails, setPaynowAddModal, refreshList, handleClose }) => {
	const [form] = Form.useForm();
	const { apiStatus, selectedBranch } = useSelector((state) => state.globalRedux);
	const coaches = useSelector((state) => state.coachRedux?.coaches);

	const dispatch = useDispatch();
	const branchId = selectedBranch?.id;
	const paymentMethodValue = Form.useWatch('paymentMethod', form);
	const serviceIdValue = Form.useWatch('serviceId', form);
	const membershipIdValue = Form.useWatch('membershipId', form);
	const amountValue = Form.useWatch('amount', form);
	const paidValue = Form.useWatch('paid', form);

	const string = useMemo(() => formQueryStringFromObject({ branchId }), [branchId]);

	const getMemberships = useCallback(
		(serviceId) => {
			const query = formQueryStringFromObject({ serviceId });
			const membershipUrl = `${SERVER_IP}api/memberships?${string}&${query}`;
			dispatch(getApi('GET_MEMBERSHIPS', membershipUrl));
		},
		[dispatch, string]
	);

	const getServices = useCallback(() => {
		const serviceUrl = `${SERVER_IP}api/services?${string}`;
		dispatch(getApi('GET_SERVICES', serviceUrl));
	}, [dispatch, string]);

	const getProducts = useCallback(() => {
		const productUrl = `${SERVER_IP}api/products?${string}`;
		dispatch(getApi('GET_PRODUCTS', productUrl));
	}, [dispatch, string]);

	useEffect(() => {
		if (paynowAddModal) {
			getServices();
			getProducts();
		} else {
			form.resetFields();
		}
	}, [getServices, getProducts, paynowAddModal, form]);

	useEffect(() => {
		if (serviceIdValue) {
			form.setFieldsValue({ membershipId: '', amount: '' });
			getMemberships(serviceIdValue);
		}
	}, [serviceIdValue, getMemberships, form]);

	useEffect(() => {
		if (membershipIdValue) {
			const amount = paynowDetails?.amount || 0;
			form.setFieldsValue({ amount });
		}
	}, [membershipIdValue, form, paynowDetails]);

	useEffect(() => {
		const balance = amountValue - (paidValue || 0);
		form.setFieldsValue({ balance });
	}, [amountValue, form, paidValue]);

	useEffect(() => {
		if (paynowDetails) {
			form.setFieldsValue({
				...paynowDetails,
				paidOn: moment(paynowDetails?.paidOn) || moment(),
				nextBillDate: moment(paynowDetails?.nextBillDate) || moment(),
			});
		} else {
			form.resetFields();
		}
	}, [paynowDetails, form]);

	const handleSubmit = (values) => {
		const data = {
			...(paynowDetails?.personalTrainingId && {
				personalTrainingId: paynowDetails?.personalTrainingId,
				paymentFor: PAYMENT_TYPE.personalTraining
			}),
			...(paynowDetails?.paymentFor === PAYMENT_TYPE.membership && {
				membershipId: paynowDetails?.membershipId,
				serviceId: paynowDetails?.serviceId,
				nextBillDate: paynowDetails?.nextBillDate,
			}),
			...(paynowDetails?.paymentFor === PAYMENT_TYPE.product && {
				productId: paynowDetails?.productId,
			}),
			...(!Boolean(paynowDetails?.personalTrainingId) && {
				groupId: paynowDetails?.groupId,
				paymentFor: paynowDetails?.paymentFor === PAYMENT_TYPE.membership ? PAYMENT_TYPE.membership : PAYMENT_TYPE.product,
			}),
			amount: values?.amount,
			paid: values?.paid,
			customerId: paynowDetails?.customerId,
			balance: values?.balance,
			paidOn: values?.paidOn,
			paymentMethod: values?.paymentMethod,
			...(paymentMethodValue === PAYMENT_METHOD[1]?.value && { upiTransactionId: values?.upiTransactionId || '' }),
			receivedBy: values?.receivedBy,
			branchId,
			remarks: values?.remarks,
		};
		delete data.id;
		dispatch(postApi(data, 'ADD_PAYMENT'));
	};

	useEffect(() => {
		if ([API_STATUS.SUCCESS].includes(apiStatus.ADD_PAYMENT) || apiStatus.EDIT_PAYMENT === API_STATUS.SUCCESS) {
			dispatch(resetApiStatus('ADD_PAYMENT'));
			refreshList?.();
			handleClose?.();
			form.resetFields();
		}
	}, [apiStatus, dispatch, refreshList, handleClose, form]);

	const loading = apiStatus.ADD_PAYMENT === API_STATUS.PENDING || apiStatus.EDIT_PAYMENT === API_STATUS.PENDING;

	return (
		<Drawer
			placement="right"
			title="Pay Balance"
			width={width}
			open={paynowAddModal}
			onClose={() => {
				setPaynowAddModal(false);
				refreshList();
			}}
			destroyOnClose>
			<Row>
				<Col span={24}>
					<Form form={form} onFinish={handleSubmit} labelAlign="left" {...layer1FormCol}>
						<RenderStaticFields paynowDetails={paynowDetails} />
						<Form.Item label="Remaining Amount" name="amount" rules={[{ required: true, message: 'Required!' }]}>
							<InputNumber disabled style={{ width: '100%' }} />
						</Form.Item>
						<Form.Item label="Paid" name="paid" rules={[{ required: true, message: 'Required!' }]}>
							<InputNumber style={{ width: '100%' }} />
						</Form.Item>
						<Form.Item label="Balance" name="balance" rules={[{ required: true, message: 'Required!' }]}>
							<InputNumber disabled style={{ width: '100%' }} />
						</Form.Item>
						<Form.Item label="Paid On" name="paidOn" rules={[{ required: true, message: 'Required!' }]}>
							<DatePicker style={{ width: '100%' }} format={DATE_FORMAT.DD_MM_YYYY} />
						</Form.Item>
						<Form.Item label="Payment Method" name="paymentMethod" rules={[{ required: true, message: 'Required!' }]}>
							<Radio.Group options={PAYMENT_METHOD} optionType="button" buttonStyle="solid" />
						</Form.Item>
						{paymentMethodValue === PAYMENT_METHOD[1]?.value && (
							<Form.Item label="UPI TransactionId" name="upiTransactionId" rules={[{ required: true, message: 'Required!' }]}>
								<InputNumber style={{ width: '100%' }} />
							</Form.Item>
						)}
						<Form.Item label="Received By" name="receivedBy" rules={[{ required: true, message: 'Required!' }]}>
							<Select placeholder="Received By">
								{coaches?.map((coach) => (
									<Select.Option key={coach.id} value={coach.id}>
										{coach.coachName}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item
							wrapperCol={{
								offset: 0,
								span: 24,
							}}>
							<Row className="space-between">
								<Button danger style={{ width: '49%' }} onClick={() => setPaynowAddModal(false)}>
									Cancel
								</Button>
								<Button loading={loading} type="primary" htmlType="submit" style={{ width: '49%' }}>
									Pay
								</Button>
							</Row>
						</Form.Item>
					</Form>
				</Col>
			</Row>
		</Drawer>
	);
};

const RenderStaticFields = ({ paynowDetails }) => {
	if (paynowDetails?.paymentFor === PAYMENT_TYPE.membership) {
		return (
			<>
				<Form.Item label="Service type" name="serviceName" initialValue={paynowDetails?.serviceName}>
					<Input disabled />
				</Form.Item>
				<Form.Item label="Membership" name="membershipName" initialValue={paynowDetails?.membershipName}>
					<Input disabled />
				</Form.Item>
			</>
		);
	}

	if (paynowDetails?.paymentFor === PAYMENT_TYPE.product) {
		return (
			<Form.Item label="Product" name="productName" initialValue={paynowDetails?.productName}>
				<Input disabled />
			</Form.Item>
		);
	}

	return (
		<>
			<Form.Item label="Customer Name" name="customerName" initialValue={paynowDetails?.customerName}>
				<Input disabled />
			</Form.Item>
			<Form.Item label="Trainer Name" name="coachName" initialValue={paynowDetails?.coachName}>
				<Input disabled />
			</Form.Item>
		</>
	);
};

const layer1FormCol = {
	labelCol: { span: 12 },
	wrapperCol: { span: 12 },
};

export default PayNowModal;
