import React from 'react'

const Summary = () => {
    return (
        <div>
           Summary 
        </div>
    )
}

export default Summary