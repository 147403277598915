import React, { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

export function LineChartGradient({ labels = ['Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'], data = [3500, 4000, 4500, 4200, 4700, 4800] }) {
	// Memoize the chart data
	const renderData = useMemo(
		() => ({
			labels,
			datasets: [
				{
					label: 'Amount collected',
					data,
					fill: 'start',
					backgroundColor: (context) => {
						const ctx = context.chart.ctx;
						const gradient = ctx.createLinearGradient(0, 0, 0, 200);
						gradient.addColorStop(0, '#4d7cfe');
						gradient.addColorStop(1, 'rgba(253, 253, 253, 0)');
						return gradient;
					},
					borderColor: '#4d69eb',
				},
			],
		}),
		[labels, data]
	);

	// Memoize the chart options
	const options = useMemo(
		() => ({
			maintainAspectRatio: false,
			responsive: true,
			plugins: {
				legend: {
					display: false,
				},
			},
			scales: {
				x: {
					grid: {
						display: false,
					},
					ticks: {
						font: {
							size: 14,
						},
					},
				},
				y: {
					ticks: {
						callback: (value) => '₹' + value,
						font: {
							size: 14,
							weight: 'bold',
						},
					},
				},
			},
			elements: {
				line: {
					tension: 0.35,
				},
			},
			interaction: {
				intersect: true,
			},
		}),
		[]
	);

	return <Line data={renderData} options={options} height={400} width="100%" />;
}
