import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import App from './App';
import { persistor, store } from './redux/store';
import './style.scss'

ReactDOM.render(
	<Provider store={store}>
		<React.StrictMode>
			<PersistGate persistor={persistor}>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</PersistGate>
		</React.StrictMode>
	</Provider>,
	document.getElementById('root')
);
