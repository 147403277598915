import React from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { Input, Button, Pagination, Row, Col, Select } from 'antd';
import TableComponent from 'components/table-component';
import AddTicket from 'pages/tickets/add-ticket';
import { camelize } from 'helpers';
import TicketDetail from 'pages/tickets/ticket-details';
import AssignToModal from './assignto-modal';

const STATUS_DROPDOWN = ['Open', 'Assigned', 'Accepted', 'On the way', 'In Progress', 'Completed', 'Cancelled', 'Pending'].map((status) => ({
	label: status,
	value: status,
}));

const TicketListPresentational = ({
	column,
	filteredData,
	visible,
	toggleVisible,
	handleTableChange,
	getStartingValue,
	getEndingValue,
	pageSize,
	initialPageSizeOptions,
	initialPageSize,
	currentPage,
	setSearchKey,
	refreshList,
	tableLoading,
	rowSelection,
	users,
	setSelectedAssignedTo,
	selectedStatus,
	setSelectedStatus,
	selectedTicket,
	setSelectedTicket,
	selectedTicketId,
	navigate,
}) => {
	return (
		<Row>
			<Col span={24} style={{ padding: 20 }}>
				<TableComponent
					rowSelection={rowSelection}
					loading={tableLoading}
					className="custom-table ticket"
					style={{ width: '100%' }}
					columns={column}
					rowKey={(record) => record._id}
					dataSource={filteredData}
					scroll={{ x: 1300 }}
					rowClassName={(record, index) => {
						return camelize(record?.status);
					}}
					title={() => (
						<Row gutter={[10, 10]}>
							<Col md={8}>
								<Row gutter={[10, 10]}>
									<Col>
										<Input
											placeholder="Search by ticket type, description, ticket no."
											suffix={<AiOutlineSearch />}
											style={{ height: '30px' }}
											onChange={({ target: { value } }) => setSearchKey(value)}
										/>
									</Col>
									<Col>
										<Select
											allowClear
											style={{ width: 170 }}
											placeholder="assigned to"
											onChange={(value) => setSelectedAssignedTo(value)}>
											{users?.map((user) => (
												<Select.Option value={user._id}>
													{user?.firstName} {user?.lastName}
												</Select.Option>
											))}
										</Select>
									</Col>
								</Row>
							</Col>
							<Col md={3}>
								<Select style={{ width: '100%' }} allowClear placeholder="status" onChange={(value) => setSelectedStatus(value)}>
									{STATUS_DROPDOWN?.map((status) => (
										<Select.Option value={status.value}>{status?.label}</Select.Option>
									))}
								</Select>
							</Col>
							<Col md={9}>
								<AssignToModal {...{ users, rowSelection, refreshList }} />
							</Col>
							<Col md={4}>
								<Button
									type="primary"
									// icon={<PlusOutlined />}
									style={{ width: '100%' }}
									onClick={() => {
										toggleVisible(true);
									}}>
									Create Ticket
								</Button>
							</Col>
						</Row>
					)}
					pagination={{
						current: currentPage,
						pageSize: pageSize,
						position: ['none', 'none'],
					}}
					footer={() => (
						<Row>
							<Col>
								<div>
									{!!filteredData?.length && `Showing ${getStartingValue()} - ${getEndingValue()} of ${filteredData?.length} Data`}
								</div>
							</Col>
							<Col md={8}>
								<div style={{ textAlign: 'right' }}>
									<Pagination
										pageSizeOptions={initialPageSizeOptions}
										defaultPageSize={initialPageSize}
										showSizeChanger={true}
										total={filteredData?.length}
										onChange={handleTableChange}
										responsive
									/>
								</div>
							</Col>
						</Row>
					)}
					onChange={handleTableChange}
				/>
			</Col>
			<AddTicket {...{ visible, toggleVisible, refreshList }} />
			<TicketDetail {...{ visible: !!selectedTicketId, closeModal: () => navigate(-1) }} />
		</Row>
	);
};

export default TicketListPresentational;
