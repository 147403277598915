import React from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { Popconfirm, Input, Button, Pagination, Row, Col, Select, Switch } from 'antd';
import { CheckOutlined, CloseOutlined, ReloadOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { SERVER_IP } from 'assets/Config';
import TableComponent from 'components/table-component';
import { deleteApi } from 'redux/sagas/deleteApiSaga';
import AddCustomer from 'pages/customers/add-customer';
import PayNowModal from 'pages/customers/pay-now';
// import ExcelDownload from 'components/excel-download';
import { getRowColor } from './customer-list-functional';

const CustomerListPresentational = ({
	filteredData,
	column,
	tableLoading,
	rowSelection,
	onTableChange,
	selectedRowKeys,
	handleAddCustomer,
	currentPage,
	pageSize,
	initialPageSizeOptions,
	initialPageSize,
	handlePaginationChange,
	paginationDetails,
	setSearchKey,
	getStartingValue,
	getEndingValue,
	customerAddModal,
	setCustomerAddModal,
	payNowAddModal,
	setPayNowAddModal,
	refreshList,
	editCustomer,
	handleClose,
	services,
	selectedService,
	setSelectedService,
	memberships,
	selectedMembership,
	setSelectedMembership,
	isExpiredCheck,
	setIsExpiredCheck,
	isInActiveCheck,
	setIsInActiveCheck,
	exportData,
}) => {
	const dispatch = useDispatch();
	return (
		<>
			<Row className="customer_list_page">
				<Col span={24} style={{ padding: 0 }}>
					<TableComponent
						loading={tableLoading}
						className="custom-table"
						style={{ width: '100%' }}
						columns={column}
						bordered
						showSorterTooltip
						rowClassName={(record) => {
							return getRowColor(record?.membership?.nextBillDate);
						}}
						rowKey={(record) => record.id}
						dataSource={filteredData}
						rowSelection={rowSelection}
						title={() => (
							<Row style={{ justifyContent: 'space-between' }}>
								<Col xl={24}>
									<Row gutter={[10, 10]}>
										<Col xl={24}>
											<Row gutter={[10, 10]} align="middle">
												<Col sm={4} xl={4}>
													<Input
														placeholder="Search"
														suffix={<AiOutlineSearch />}
														style={{ width: '100%' }}
														onChange={({ target: { value } }) => setSearchKey(value)}
													/>
												</Col>
												<Col sm={4} xl={4}>
													<Select
														allowClear
														placeholder="Select services"
														style={{ width: '100%' }}
														value={selectedService}
														onChange={(value) => setSelectedService(value)}>
														{services?.map((service, index) => (
															<Select.Option key={index} value={service.id}>
																{service?.serviceName}
															</Select.Option>
														))}
													</Select>
												</Col>
												<Col sm={4} xl={4}>
													<Select
														allowClear
														placeholder="Select membership"
														style={{ width: '100%' }}
														value={selectedMembership}
														onChange={(value) => setSelectedMembership(value)}>
														{memberships?.map((membership, index) => (
															<Select.Option key={index} value={membership.id}>
																{membership?.membershipName} - {membership?.offerPrice || membership?.originalPrice}
															</Select.Option>
														))}
													</Select>
												</Col>
												<Col sm={3} xl={3}>
													<Switch
														style={{
															width: '100%',
														}}
														checked={isExpiredCheck}
														onChange={(ev) => setIsExpiredCheck(ev)}
														checkedChildren={
															<div>
																<CheckOutlined />
																Expired
															</div>
														}
														unCheckedChildren={
															<div>
																<CloseOutlined />
																Show Expired
															</div>
														}
													/>
												</Col>
												<Col sm={3} xl={3}>
													<Switch
														style={{
															width: '100%',
														}}
														checked={isInActiveCheck}
														onChange={(ev) => setIsInActiveCheck(ev)}
														checkedChildren={
															<div>
																<CheckOutlined />
																Inactive
															</div>
														}
														unCheckedChildren={
															<div>
																<CloseOutlined />
																Show Inactive
															</div>
														}
													/>
												</Col>
												<Col
													xl={6}
													style={{
														display: 'flex',
														justifyContent: 'flex-end',
														// alignItems: 'center',
													}}>
														<Button
														type="primary"
														icon={<ReloadOutlined />}
														loading={tableLoading}
														onClick={() => refreshList(true)}
														style={{ marginRight: 10 }}
													/>
													{/* <ExcelDownload {...{ ...exportData }} iconOnly /> */}
													<Button
														// icon={<PlusOutlined />}
														type="primary"
														onClick={handleAddCustomer}>
														Create Customer
													</Button>
												</Col>
												{selectedRowKeys?.length === 1 ? (
													<Col>
														<Popconfirm
															title={`Are you sure want to delete this Customer?`}
															okText="Delete"
															cancelText="No"
															onConfirm={() => {
																let url = `${SERVER_IP}api/customers/${selectedRowKeys?.[0]}`;
																dispatch(deleteApi('DELETE_CUSTOMER', url));
															}}>
															<div style={{ textDecoration: 'underline', color: 'red', cursor: 'pointer' }}>Delete</div>
														</Popconfirm>
													</Col>
												) : null}
											</Row>
										</Col>
									</Row>
								</Col>
							</Row>
						)}
						scroll={{ x: 1300 }}
						pagination={{
							current: currentPage,
							pageSize: pageSize,
							position: ['none', 'none'],
						}}
						footer={() => (
							<Row justify="space-between">
								<Col span={12}>
									{!!paginationDetails?.totalRecords && `Showing ${getStartingValue()} - ${getEndingValue()} of ${paginationDetails?.totalRecords} Data`}
								</Col>
								<Col span={12}>
									<div style={{ textAlign: 'right' }}>
										<Pagination
											pageSizeOptions={initialPageSizeOptions}
											defaultPageSize={initialPageSize}
											showSizeChanger={true}
											total={paginationDetails?.totalRecords}
											onChange={handlePaginationChange}
											responsive
										/>
									</div>
								</Col>
							</Row>
						)}
						onChange={onTableChange}
					/>
				</Col>
			</Row>
			<AddCustomer {...{ customerAddModal, setCustomerAddModal, refreshList, editCustomer, handleClose }} />
			<PayNowModal
				{...{
					payNowAddModal,
					setPayNowAddModal,
					refreshList,
					editCustomer,
					handleClose,
				}}
			/>
		</>
	);
};

export default CustomerListPresentational;
