
function setCustomers(data){
    return{
        type : "SET_CUSTOMERS",
        payload : data
    }
}

export function setCustomersPagination(data){
    return{
        type : "SET_CUSTOMERS_PAGINATION",
        payload : data
    }
}

function setCustomersGenderWise(data){
    return{
        type : "SET_CUSTOMERS_GENDER_WISE",
        payload : data
    }
}

function setSelectedCustomer(data){
    return{
        type : "SET_SELECTED_CUSTOMER",
        payload : data
    }
}

export function setCustomerDetails(data){
    return{
        type : "SET_SELECTED_CUSTOMER_DETAILS",
        payload : data
    }
}


export {  
    setCustomers,
    setSelectedCustomer,
    setCustomersGenderWise
}