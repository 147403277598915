import Confetti from './confetti.json';
import HeartLoading from './heart-loading.json';
import Doctor from './doctor.json';
import Voice from './voice.json';
import NoData from './no-data.json';
import Loading from './loading1.json';
import Analytics from './analytics.json';
import UnderConstruction from './under-construction.json';

const LottieFile = {
	Confetti,
	HeartLoading,
	Doctor,
	Voice,
	UnderConstruction,
	Analytics,
	Loading,
	NoData
};

export default LottieFile;
