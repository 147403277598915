import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
	Button,
	// Tag
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { SERVER_IP } from 'assets/Config';
import HighlightComponent from 'components/HighlightComponent';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { formQueryStringFromObject, generatePagination, getWhatsAppUrl } from 'helpers';
import { API_STATUS } from 'constants/app-constants';
import VisitorListPresentational from './visitor-list-presentational';
import { getDateFormat } from 'services/Utils';
import { putApi } from 'redux/sagas/putApiSaga';
import {
	//  FaCalendarDay,
	FaRegHandshake,
} from 'react-icons/fa';

const initialPageSize = 10;
const initialPageSizeOptions = [10, 15, 20];

// const interestedLevel = {
// 	average: 'Average Interest',
// 	very: 'Very Interest',
// 	notSure: 'Not Sure',
// 	no: 'No Interest',
// };

const VisitorListFunctional = React.memo(({ converted }) => {
	const visitorRedux = useSelector((state) => state.visitorRedux);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const branchId = globalRedux?.selectedBranch?.id;
	const dispatch = useDispatch();

	const [visitorAddModal, setVisitorAddModal] = useState(false);
	const [followUpModal, setFollowUpModal] = useState(false);
	const [customerAddModal, setCustomerAddModal] = useState(false);
	const [searchKey, setSearchKey] = useState('');
	const [tableData, setTableData] = useState(visitorRedux.visitors);
	const [editVisitor, setEditVisitor] = useState(null);
	const [convertCustomer, setConvertCustomer] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);

	const getVisitors = useCallback(() => {
		const string = formQueryStringFromObject({
			branchId: globalRedux?.selectedBranch?.id,
			type: 'all',
		});
		let url = `${SERVER_IP}api/visitors?${string}`;
		dispatch(getApi('GET_VISITORS', url));
	}, [dispatch, globalRedux?.selectedBranch?.id]);

	const getServices = useCallback(() => {
		const string = formQueryStringFromObject({
			branchId,
		});
		let serviceUrl = `${SERVER_IP}api/services?${string}`;
		dispatch(getApi('GET_SERVICES', serviceUrl));
	}, [dispatch, branchId]);

	useEffect(() => {
		getVisitors();
		getServices();
	}, [getVisitors, getServices]);

	useEffect(() => {
		generatePagination(tableData);
	}, [tableData]);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_VISITOR === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_VISITOR'));
			setSelectedRowKeys([]);
			doIt = true;
		}
		if (globalRedux.apiStatus.EDIT_VISITOR === 'SUCCESS') {
			dispatch(resetApiStatus('EDIT_VISITOR'));
			doIt = true;
		}
		if (doIt) {
			getVisitors();
		}
	}, [globalRedux.apiStatus, dispatch, getVisitors]);

	useEffect(() => {
		setTableData(visitorRedux.visitors);
	}, [visitorRedux.visitors]);

	const filteredData = useMemo(() => {
		let data = tableData;
		if (searchKey !== '') {
			data = data.filter((visitor) => (visitor?.visitorName || '')?.toLowerCase().includes(searchKey.toLowerCase()));
		}
		if (converted) {
			data = data.filter((visitor) => visitor.isConvertedToCustomer);
		}
		return data;
	}, [tableData, searchKey, converted]);

	const handleDrawer = (rowData) => {
		setEditVisitor(rowData);
		setVisitorAddModal(true);
	};

	// const handleFollowUpDrawer = (rowData) => {
	// 	setEditVisitor(rowData);
	// 	setFollowUpModal(true);
	// };

	const handleCustomerDrawer = (rowData) => {
		setConvertCustomer({
			customerName: rowData.visitorName,
			mobile: rowData.phone,
			visitorId: rowData.id,
		});
		setCustomerAddModal(true);
	};

	const handleAddVisitor = () => {
		setEditVisitor(null);
		setVisitorAddModal(true);
	};

	const column = [
		{
			title: '#',
			dataIndex: 'visitorNumber',
			key: 'visitorNumber',
			// fixed: 'left',
			width: '5%',
			render: (value, record, index) => <div>{index + 1}</div>,
		},
		{
			title: 'Visitor Name',
			dataIndex: 'visitorName',
			key: 'visitorName',
			width: '25%',
			sorter: (a, b) => a?.visitorName?.localeCompare(b?.visitorName),
			// fixed: 'left',
			render: (value) => (
				<div style={{ fontWeight: 'bold' }}>
					<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />
				</div>
			),
		},
		// {
		// 	title: 'Interested In',
		// 	dataIndex: 'service',
		// 	key: 'service',
		// 	width: '15%',
		// 	sorter: (a, b) => a?.service?.serviceName?.localeCompare(b?.service?.serviceName),
		// 	// fixed: 'left',
		// 	render: (value) => (
		// 		<div style={{ fontWeight: 'bold' }}>
		// 			<HighlightComponent
		// 				highlightClassName="highlightClass"
		// 				searchWords={[searchKey]}
		// 				autoEscape={true}
		// 				textToHighlight={value?.serviceName || ' - '}
		// 			/>
		// 		</div>
		// 	),
		// },
		// {
		// 	title: 'Interested Level',
		// 	dataIndex: 'interestedLevel',
		// 	key: 'interestedLevel',
		// 	width: '17%',
		// 	sorter: (a, b) => a?.interestedLevel?.localeCompare(b?.interestedLevel),
		// 	// fixed: 'left',
		// 	render: (value) => (
		// 		<div style={{ fontWeight: 'bold' }}>
		// 			<HighlightComponent
		// 				highlightClassName="highlightClass"
		// 				searchWords={[searchKey]}
		// 				autoEscape={true}
		// 				textToHighlight={interestedLevel[value] || '-'}
		// 			/>
		// 		</div>
		// 	),
		// },
		{
			title: 'Phone',
			dataIndex: 'phone',
			align: 'left',
			width: '20%',
			render: (value, record) => (
				<div
					className="link"
					onClick={() => {
						window.open(
							getWhatsAppUrl(
								value,
								`
*Greetings!*
*Hi ${record?.visitorName}!*

Just a friendly reminder about your recent visit to our gym. 

We hope to see you again soon.

_Thank you!_

*${globalRedux?.selectedBranch?.branchName} Team*
`
							)
						);
					}}
					style={{ fontWeight: 'bold' }}>
					<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />
				</div>
				// eslint-disable-next-line
				// <a
				// 	style={{ fontWeight: 'bold' }}
				// 	href={getWhatsAppUrl(
				// 		value,
				// 		`Hi ${record?.visitorName}! Greetings! Just a friendly reminder about your recent visit to our gym. We hope to see you again soon. Thank you!`
				// 	)}
				// 	rel="nofollow noopener"
				// 	target="_blank">
				// 	<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />
				// </a>
			),
		},
		{
			title: 'Visited At',
			dataIndex: 'visitedDate',
			align: 'left',
			width: '20%',
			render: (value) => getDateFormat(value),
		},
		// {
		// 	title: 'Last Follow up Date',
		// 	dataIndex: 'calledOn',
		// 	align: 'left',
		// 	width: '15%',
		// 	render: (value, row) => {
		// 		return row.isCalled ? <Tag color="green"> {getDateFormat(value)} </Tag> : <Tag color="orange">NEVER</Tag>;
		// 	},
		// },
		{
			title: 'Remarks',
			dataIndex: 'remarks',
			align: 'left',
			width: '20%',
		},
		// {
		// 	title: 'Convert',
		// 	align: 'center',
		// 	dataIndex: 'id',
		// 	render: (value, row, index) => {
		// 		return (
		// 			<Row justify="center">
		// 				<Col>
		// 					{row.isConvertedToCustomer ? (
		// 						<Tag color="green">Converted</Tag>
		// 					) : (
		// 						<Button type="primary" style={{ background: '#3a9f10' }} size="small" onClick={() => handleCustomerDrawer(row)}>
		// 							Make Member
		// 						</Button>
		// 					)}
		// 				</Col>
		// 			</Row>
		// 		);
		// 	},
		// },
		{
			title: 'Action',
			dataIndex: 'visitorName',
			width: '10%',
			render: (value, row, index) => {
				return (
					<div
						style={{
							display: 'flex',
							gap: 5,
							justifyContent: 'center',
						}}>
						{!row.isConvertedToCustomer && (
							<>
								<Button
									title="Convert to a customer"
									type="primary"
									style={{ background: '#3a9f10' }}
									icon={<FaRegHandshake />}
									onClick={() => handleCustomerDrawer(row)}
									disabled={row.isConvertedToCustomer}
								/>
							</>
						)}
						{/* <>
							<Button
								style={{}}
								type="primary"
								icon={<FaCalendarDay />}
								onClick={() => handleFollowUpDrawer(row)}
								disabled={row.isConvertedToCustomer}
							/>
						</> */}
						<>
							<Button type="primary" icon={<EditOutlined />} onClick={() => handleDrawer(row)} disabled={row.isConvertedToCustomer} />
						</>
					</div>
				);
			},
		},
	];

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_VISITORS === API_STATUS.PENDING, [globalRedux.apiStatus]);

	const onSelectChange = (selectedRowKeys) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		// fixed: true,
	};

	const handleClose = useCallback(() => {
		if (customerAddModal) {
			const url = `${SERVER_IP}api/visitors/${convertCustomer?.visitorId}`;
			const data = {
				visitorName: convertCustomer?.customerName,
				phone: convertCustomer?.mobile,
				branchId: globalRedux?.selectedBranch?.id,
				isConvertedToCustomer: true,
				isCalled: true,
				calledOn: new Date(),
				remarks: 'Visitor converted to a customer',
			};
			dispatch(putApi(data, 'EDIT_VISITOR', url));
		}
		setVisitorAddModal(false);
		setCustomerAddModal(false);
		setConvertCustomer(null);
		setEditVisitor(null);
	}, [setVisitorAddModal, setEditVisitor, setConvertCustomer, setCustomerAddModal, convertCustomer, customerAddModal, dispatch, globalRedux]);

	return (
		<VisitorListPresentational
			{...{
				filteredData,
				column,
				tableLoading,
				rowSelection,
				selectedRowKeys,
				handleAddVisitor,
				currentPage,
				pageSize,
				initialPageSizeOptions,
				initialPageSize,
				handleTableChange,
				setSearchKey,
				getStartingValue,
				getEndingValue,
				visitorAddModal,
				setVisitorAddModal,
				customerAddModal,
				setCustomerAddModal,
				refreshList: getVisitors,
				editVisitor,
				handleClose,
				convertCustomer,
				converted,
				followUpModal,
				setFollowUpModal,
			}}
		/>
	);
});

export default VisitorListFunctional;
