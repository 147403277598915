let initialState = {
    customers : [],
    selectedCustomer : null,
    selectedCustomerDetails : null,
    paginationDetails: {

    }
};

const customerReducer = (state = initialState, action) => {

  switch (action.type) {

    case "LOGOUT" : {
      return initialState
    }
  
    case "SET_CUSTOMERS" : 
      return {
        ...state,
        customers : action.payload
      }   
  
  
    case "SET_CUSTOMERS_PAGINATION" : 
      return {
        ...state,
        paginationDetails : action.payload
      }   
  
    case "SET_CUSTOMERS_GENDER_WISE" : 
      return {
        ...state,
        customersGenderWise : action.payload
      }   

    case "SET_SELECTED_CUSTOMER" : 
      return {
        ...state,
        selectedCustomer : action.payload
      }      

    case "SET_SELECTED_CUSTOMER_DETAILS" : 
      return {
        ...state,
        selectedCustomerDetails : action.payload
      }      

    
    default : return state
  }

}

export {customerReducer}
