import React from 'react';
// import { AiOutlineSearch } from 'react-icons/ai';
import { Popconfirm, Pagination, Row, Col } from 'antd';
import { useDispatch } from 'react-redux';
import { SERVER_IP } from 'assets/Config';
import TableComponent from 'components/table-component';
import { deleteApi } from 'redux/sagas/deleteApiSaga';
import DatePicker from 'components/date-picker';
import PdfDownload from 'components/pdf-download';
import ExcelDownload from 'components/excel-download';

const PaymentReportListPresentational = ({
	filteredData,
	column,
	tableLoading,
	rowSelection,
	selectedRowKeys,
	currentPage,
	pageSize,
	initialPageSizeOptions,
	initialPageSize,
	handleTableChange,
	// setSearchKey,
	getStartingValue,
	getEndingValue,
	handleFilterChange,
	exportData,
}) => {
	const { RangePicker } = DatePicker;
	const dispatch = useDispatch();
	return (
		<Row>
			<Col span={24} style={{}}>
				<TableComponent
					loading={tableLoading}
					className="custom-table"
					style={{ width: '100%' }}
					columns={column}
					bordered
					rowKey={(record) => record._id}
					dataSource={filteredData}
					rowSelection={rowSelection}
					title={() => (
						<Row style={{ justifyContent: 'space-between' }}>
							<Col span={8}>
								<Row gutter={[10, 10]}>
									<Col xl={24}>
										<Row gutter={[10, 10]} align="middle">
											<Col>
												{/* <Input
													placeholder="Search"
													suffix={<AiOutlineSearch />}
													style={{ height: '30px' }}
													onChange={({ target: { value } }) => setSearchKey(value)}
												/> */}
												<Col>
													<span>Select Month: </span>
													{/* <DatePicker
														onChange={(date, dateString) => console.log('date, dateString', date, dateString)}
														picker="month"
													/> */}
													<RangePicker onChange={(date) => handleFilterChange('date', date)} />
												</Col>
											</Col>
											{selectedRowKeys?.length === 1 ? (
												<Col>
													<Popconfirm
														title={`Are you sure want to delete this Coach?`}
														okText="Delete"
														cancelText="No"
														onConfirm={() => {
															let url = `${SERVER_IP}api/coaches/${selectedRowKeys?.[0]}`;
															dispatch(deleteApi('DELETE_COACH', url));
														}}>
														<div style={{ textDecoration: 'underline', color: 'red', cursor: 'pointer' }}>Delete</div>
													</Popconfirm>
												</Col>
											) : null}
											<Col xl={24} md={24} style={{ paddingTop: 10 }}>
												<Row gutter={[10, 10]}>
													<Col>
														<PdfDownload {...{ ...exportData }} />
													</Col>
													<Col>
														<ExcelDownload {...{ ...exportData }} />
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
							<Col>
								{/* <Button
									icon={<PlusOutlined />}
									type="primary"
									style={{ height: '30px', width: '100%' }}
									onClick={handleAddCoach}
								>
									Create Coach
								</Button> */}
							</Col>
						</Row>
					)}
					pagination={{
						current: currentPage,
						pageSize: pageSize,
						position: ['none', 'none'],
					}}
					footer={() => (
						<Row justify="space-between">
							<Col span={12}>
								{!!filteredData?.length && `Showing ${getStartingValue()} - ${getEndingValue()} of ${filteredData?.length} Data`}
							</Col>
							<Col span={12}>
								<div style={{ textAlign: 'right' }}>
									<Pagination
										pageSizeOptions={initialPageSizeOptions}
										defaultPageSize={initialPageSize}
										showSizeChanger={true}
										total={filteredData?.length}
										onChange={handleTableChange}
										responsive
									/>
								</div>
							</Col>
						</Row>
					)}
				/>
			</Col>
		</Row>
	);
};

export default PaymentReportListPresentational;
