import React, { useEffect } from 'react';
import { Row, Col, Input, Button, Form, Drawer, DatePicker, Select } from 'antd';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { putApi } from 'redux/sagas/putApiSaga';
import { API_STATUS, DATE_FORMAT } from 'constants/app-constants';
import { SERVER_IP } from 'assets/Config';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';

const FollowUpEntry = ({ followUpModal, setFollowUpModal, width = '40%', editVisitor, refreshList, handleClose }) => {
	const [form] = Form.useForm();
	const globalRedux = useSelector((state) => state.globalRedux);
	const branchId = globalRedux?.selectedBranch?.id;
	const services = useSelector((state) => state?.serviceRedux?.services || []);
	const dispatch = useDispatch();

	useEffect(() => {
		if (editVisitor) {
			form.setFieldsValue({
				visitorName: editVisitor?.visitorName || '',
				visitedDate: moment(editVisitor?.visitedDate) || '',
				phone: editVisitor?.phone || '',
				remarks: editVisitor?.remarks || '',
			});
		} else {
			form?.resetFields();
			form.setFieldsValue({
				visitedDate: moment(),
			});
		}
	}, [editVisitor, form]);

	// useEffect(() => {
	// 	form.setFieldValue('visitorImages', visitorImages)
	// }, [visitorImages, form])

	const handleSubmit = (values) => {
		let data = {
			visitorName: values?.visitorName || '',
			visitedDate: values?.visitedDate || '',
			phone: values?.phone || '',
			remarks: values?.remarks || '',
			branchId,
		};

		if (!editVisitor) {
			dispatch(postApi(data, 'ADD_VISITOR'));
		} else {
			let url = `${SERVER_IP}api/visitors/${editVisitor.id}`;
			dispatch(putApi(data, 'EDIT_VISITOR', url));
		}
	};

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_VISITOR === 'SUCCESS' || globalRedux.apiStatus.EDIT_VISITOR === 'SUCCESS') {
			dispatch(resetApiStatus(editVisitor ? 'EDIT_VISITOR' : 'ADD_VISITOR'));
			refreshList?.();
			handleClose?.();
			form?.resetFields();
		}
	}, [globalRedux.apiStatus, editVisitor, setFollowUpModal, dispatch, refreshList, handleClose, form]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const onClose = () => {
		setFollowUpModal(false);
	};

	const loading = globalRedux.apiStatus.ADD_VISITOR === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_VISITOR === API_STATUS.PENDING;

	return (
		<Drawer
			placement="right"
			title="Make Follow up Entry"
			width={width || '40%'}
			open={followUpModal}
			closable
			onClose={onClose}
			destroyOnClose={true}>
			<Row>
				<Col span={24}>
					<Row style={{ marginTop: 20 }}>
						<Form
							name="add-visitor"
							className="required_in_right"
							style={{ width: '100%' }}
							colon={true}
							labelAlign="left"
							form={form}
							onFinish={handleSubmit}
							initialValues={{}}
							{...layer1FormCol}>
							<Form.Item
								label="Visitor Name"
								name="visitorName"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<Input placeholder="Visitor name" disabled={editVisitor} />
							</Form.Item>
							<Form.Item
								label="Next Follow Up Date"
								name="nextFollowUpDate"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<DatePicker style={{ width: '100%' }} placeholder="Next Follow Up Date" format={DATE_FORMAT.DD_MM_YYYY} />
							</Form.Item>
							<Form.Item
								label="Remarks"
								name="remarks"
								rules={[
									{
										required: false,
										message: 'This Field is required!',
									},
								]}>
								<Input.TextArea />
							</Form.Item>
							<Form.Item
								label="Interested In"
								name="interestedIn"
								rules={[
									{
										required: false,
										message: 'This Field is required!',
									},
								]}>
								<Select placeholder="Select Service">
									{services?.map((service, index) => (
										<Select.Option key={index} value={service.id}>
											{service?.serviceName}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								label="Interested Level"
								name="interestedLevel"
								rules={[
									{
										required: false,
										message: 'This Field is required!',
									},
								]}>
								<Select placeholder="Select Interested Level">
									<Select.Option value="veryInterested">Very Interested</Select.Option>
									<Select.Option value="averageInterested">Average Interested</Select.Option>
									<Select.Option value="notSure">Not Sure</Select.Option>
									<Select.Option value="notInterested">Not Interested</Select.Option>
								</Select>
							</Form.Item>
							<Form.Item
								wrapperCol={{
									offset: 0,
									span: 24,
								}}>
								<Row className="space-between" style={{ paddingTop: 20, width: '100%', margin: 0 }}>
									<Button danger style={{ width: '49%' }} onClick={onClose}>
										Cancel
									</Button>
									<Button loading={loading} style={{ width: '49%' }} type="primary" htmlType="submit">
										Submit
									</Button>
								</Row>
							</Form.Item>
						</Form>
					</Row>
				</Col>
			</Row>
		</Drawer>
	);
};

export default FollowUpEntry;
