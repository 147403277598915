import React from 'react';
import { List, Card, Row, Col, Skeleton, Image } from 'antd';
import { useSelector } from 'react-redux';
import { API_STATUS } from 'constants/app-constants';
import { SERVER_IP } from 'assets/Config';

const { Meta } = Card;

const Images = () => {
	const ticketRedux = useSelector((state) => state.ticketRedux);
	const globalRedux = useSelector((state) => state.globalRedux);

	const { images } = ticketRedux?.ticketDetails || {};

	const loading = globalRedux.apiStatus.GET_TICKETS_DETAILS === API_STATUS.PENDING;

	// useEffect(() => {
	// 	fetch('https://dummyjson.com/products')
	// 		.then((res) => res.json())
	// 		.then((res) => {
	// 			setDataSource(res?.products);
	// 		});
	// }, []);

	return (
		<Row className="images_container">
			<Col span={24}>
				{loading ? (
					<Col>
						<Skeleton active />
					</Col>
				) : (
					<List
						loading={loading}
						dataSource={images}
						grid={{
							xs: 1,
							sm: 2,
							md: 3,
							lg: 4,
							xl: 5,
							xxl: 6,
						}}
						renderItem={(item) => (
							<Card
								key={item?.id}
								// hoverable
								style={{
									margin: 10,
								}}
								// cover={
								// 	<img
								// 		style={{
								// 			height: 120,
								// 		}}
								// 		alt="example"
								// 		src={`${SERVER_IP}/${item?.path}`}
								// 	/>
								// }
							>
								<Image
									style={{
										height: 120,
										width: '100%',
									}}
									src={`${SERVER_IP}/${item?.path}`}
								/>
								<Meta
									title={item?.filename}
									// description="www.instagram.com"
								/>
							</Card>
						)}
					/>
				)}
			</Col>
		</Row>
	);
};

export default Images;
