import React, { useState, useMemo, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import { useSelector } from 'react-redux';
import { MailOutlined } from '@ant-design/icons';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { MENUS, SUPER_ADMIN_EMAILS, SUPER_ADMIN_MENUS } from 'constants/app-constants';
import './sidebar.scss';
import { isTablet } from 'react-device-detect';

const { Sider } = Layout;
const { SubMenu } = Menu;

export const LogoComponent = ({ style = {}, collapsed = false, textColor = '#fff' }) => (
	<Link to="/" className="logo_class" style={{ ...style, color: textColor }}>
		{collapsed ? (
			<span>
				F<span style={{ color: '#4d7cfe' }}>T</span>
			</span>
		) : (
			<span>
				FITTE<span style={{ color: '#4d7cfe', paddingLeft: 1 }}>.in</span>
			</span>
		)}
	</Link>
);

function Sidebar() {
	const loginRedux = useSelector((state) => state.loginRedux);
	const [collapsed, setCollapsed] = useState(false);
	const [openKeys, setOpenKeys] = useState([]);
	const navigate = useNavigate();
	const { pathname } = useLocation();
	// const globalRedux = useSelector((state) => state.globalRedux);

	/**
	 * @description This function is used to set the open keys of the menu
	 */
	const onOpenChange = (keys) => {
		if (keys) {
			const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
			setOpenKeys([latestOpenKey]);
		} else {
			setOpenKeys([]);
		}
	};

	useEffect(() => {
		if (isTablet) {
			setCollapsed(true);
		}
	}, []);

	/**
	 * @description: This function is to get active menu from route
	 */
	const { selectedKey } = useMemo(() => {
		let selectedKey = '1';
		(SUPER_ADMIN_EMAILS.includes(loginRedux.email) ? SUPER_ADMIN_MENUS : MENUS).find((menu) => {
			const hasSubMenu = !!menu?.submenus;
			if (menu?.route?.split('/')?.[1] === pathname?.split('/')?.[1]) {
				selectedKey = menu?.key;
				return selectedKey;
			}
			if (hasSubMenu) {
				const subMenu = menu.submenus.find((submenu) => submenu.route === pathname);
				selectedKey = subMenu?.key;
				return selectedKey;
			}
			return null;
		});
		return { selectedKey };
	}, [pathname, loginRedux.email]);

	return (
		<Sider breakpoint="md" collapsedWidth={80} collapsible collapsed={collapsed} onCollapse={() => setCollapsed(!collapsed)}>
			<LogoComponent style={{ color: '#fff' }} collapsed={collapsed} />
			<Menu theme="light" mode="inline" selectedKeys={[selectedKey]} openKeys={openKeys} onOpenChange={onOpenChange}>
				{(SUPER_ADMIN_EMAILS.includes(loginRedux.email) ? SUPER_ADMIN_MENUS : MENUS).map((menu, index) => {
					const hasSubMenu = !!menu?.submenus;
					const Icon = menu?.icon || <MailOutlined />;
					if (hasSubMenu) {
						return (
							<SubMenu
								{...{
									key: menu?.subName,
									icon: Icon,
									title: menu?.name,
									className: 'sub_menu_style',
								}}>
								{menu?.submenus.map((submenu, index) => {
									return (
										<Menu.Item onClick={() => submenu?.route && navigate(submenu?.route)} key={submenu.key}>
											{submenu?.name}
										</Menu.Item>
									);
								})}
							</SubMenu>
						);
					} else {
						return (
							<Menu.Item
								key={menu?.key}
								icon={Icon}
								onClick={() => {
									menu?.route && navigate(menu?.route);
									onOpenChange();
								}}>
								{menu?.name}
							</Menu.Item>
						);
					}
				})}
			</Menu>
		</Sider>
	);
}

export default Sidebar;
