import React, { useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
import { Row, Col, Input, Button, Form, Drawer, Radio, Select, InputNumber } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { API_STATUS, DATE_FORMAT, GYM_PACKAGES, PAYMENT_METHOD } from 'constants/app-constants';
import { SERVER_IP } from 'assets/Config';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { getApi } from 'redux/sagas/getApiDataSaga';
import DatePicker from 'components/date-picker';
import { putApi } from 'redux/sagas/putApiSaga';
import API_ENDPOINTS from 'redux/endpoints';
import { ADD_GYM_PAYMENT, GET_EDIT_GYM_PAYMENT, GET_GYM_SB } from 'redux/reducers/constants';

const EditPayment = ({ paymentAddModal, width = '40%', editPaymentId, setPaymentAddModal, refreshList, handleClose, paymentFor }) => {
	const [form] = Form.useForm();
	const globalRedux = useSelector((state) => state.globalRedux);
	const editPayment = useSelector((state) => state.gymPaymentRedux?.editPayment);
	const gymSb = useSelector((state) => state.userRedux?.gymSB);
	const paymentMethodValue = Form.useWatch('paymentMethod', form);
	const packageValue = Form.useWatch('package', form);
	const paidOnValue = Form.useWatch('paidOn', form);
	const amountValue = Form.useWatch('amount', form);

	const dispatch = useDispatch();

	const calculateBalance = useCallback(() => {
		const amountFormValue = form.getFieldValue('amount');

		const actualAmount = form.getFieldValue('total');

		const balance = actualAmount - amountFormValue;
		form.setFieldValue('balance', balance);
	}, [form]);

	useEffect(() => {
		if (amountValue) {
			calculateBalance();
		}
	}, [amountValue, calculateBalance]);

	useEffect(() => {
		dispatch(getApi(GET_GYM_SB, API_ENDPOINTS.GET_GYM_SB));
	}, [dispatch])

	useEffect(() => {
		if (packageValue) {
			const amountFormValue = form.getFieldValue('amount');
			const selectedPackage = GYM_PACKAGES?.find((pckg) => pckg?.value === packageValue);
			const amount = amountFormValue ? amountFormValue : selectedPackage?.price;
			form.setFieldsValue({
				amount,
				...(paidOnValue && { nextBillDate: moment(paidOnValue).add(selectedPackage?.days, 'days') }),
			});
			calculateBalance();
		}
	}, [packageValue, form, paidOnValue, calculateBalance]);


	useEffect(() => {
		form?.resetFields();
		form?.setFieldsValue({
			paymentFor,
		});
	}, [form, paymentFor]);

	const handleSubmit = (values) => {
		let data = {
			...editPayment,
			...values,
			id: editPaymentId,
			amount: values?.amount || 0,
			nextBillDate: values?.nextBillDate || moment(),
			remarks: values?.remarks || '',
			paidOn: values?.paidOn || '',
			paymentMethod: values?.paymentMethod || '',
			...(paymentMethodValue === PAYMENT_METHOD[1]?.value && { upiTransactionId: values?.upiTransactionId || '' }),
			paymentFor: 'package',
			balance: values?.balance || 0,
			groupId: values?.groupId || null,
		};
		dispatch(putApi(data, ADD_GYM_PAYMENT , `${API_ENDPOINTS.ADD_GYM_PAYMENT}/${editPaymentId}`));

		// if (!editPaymentId) {
		// 	dispatch(postApi(data, 'ADD_PAYMENT'));
		// } else {
		// 	let url = `${SERVER_IP}api/customers/${editPaymentId.id}`;
		// 	dispatch(putApi(data, 'EDIT_GYM_PAYMENT', url));
		// }
	};

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_GYM_PAYMENT === 'SUCCESS' || globalRedux.apiStatus.EDIT_GYM_PAYMENT === 'SUCCESS') {
			dispatch(resetApiStatus(ADD_GYM_PAYMENT));
			// dispatch(resetApiStatus(editPaymentId ? 'EDIT_GYM_PAYMENT' : 'ADD_PAYMENT'));
			refreshList?.(true);
			handleClose?.();
			form?.resetFields();
		}
	}, [globalRedux.apiStatus, editPaymentId, setPaymentAddModal, dispatch, refreshList, handleClose, form, paymentFor]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	useEffect(() => {
		if (editPaymentId && paymentAddModal) {
			form.resetFields();
			let url = `${SERVER_IP}api/gymPayments/${editPaymentId}`;
			dispatch(getApi(GET_EDIT_GYM_PAYMENT, url));

		}
	}, [editPaymentId, form, paymentAddModal, dispatch]);


	const loading = globalRedux.apiStatus.ADD_PAYMENT === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_GYM_PAYMENT === API_STATUS.PENDING;
	const paymentLoading = useMemo(() => globalRedux?.apiStatus[GET_EDIT_GYM_PAYMENT] === API_STATUS.PENDING, [globalRedux?.apiStatus]);
	// const customerLoading = useMemo(() => globalRedux?.apiStatus.GET_CUSTOMER === API_STATUS.PENDING, [globalRedux?.apiStatus?.GET_CUSTOMER]);


	useEffect(() => {
		if(!!!paymentLoading && editPayment?.id){
			form.setFieldsValue({
				...editPayment,
				total: editPayment?.amount + editPayment?.balance,
				gymName: editPayment?.user?.gymName,
				paidOn: moment(editPayment?.paidOn),
				nextBillDate: moment(editPayment?.nextBillDate),
			});
		}
	}, [editPayment, form, paymentLoading])

	return (
		<Drawer
			placement="right"
			title={`${editPaymentId ? 'Edit' : 'New'} Payment`}
			width={width || '40%'}
			open={paymentAddModal}
			closable
			onClose={() => setPaymentAddModal(false)}
			destroyOnClose={true}>
			<Row>
				<Col span={24}>
					<Row style={{ marginTop: 20 }}>
						<Form
							name="pay-now"
							className="required_in_right"
							style={{ width: '100%' }}
							colon={true}
							labelAlign="left"
							form={form}
							onFinish={handleSubmit}
							{...layer1FormCol}>
							<Form.Item
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}
								label="Gym Name"
								name="gymId">
								<Select placeholder="Gym Name" disabled>
									{gymSb?.map((gym, index) => (
										<Select.Option key={index} value={gym.id}>
											{gym?.gymName}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}
								label="Plan"
								name="package">
								<Select placeholder="Plan">
									{GYM_PACKAGES?.map((plan, index) => (
										<Select.Option key={index} value={plan.value}>
											{plan?.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								label="Total Amount"
								name="total"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<InputNumber placeholder="Total Amount" disabled style={{ width: '100%' }} />
							</Form.Item>
							<Form.Item
								label="Amount"
								name="amount"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<InputNumber placeholder="Amount" style={{ width: '100%' }} />
							</Form.Item>
							<Form.Item
								label="Balance"
								name="balance"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<InputNumber disabled placeholder="Balance" style={{ width: '100%' }} />
							</Form.Item>
							<Form.Item
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}
								label="Paid on"
								name="paidOn">
									<DatePicker disabled style={{ width: '100%' }} format={DATE_FORMAT.DD_MM_YYYY} />
							</Form.Item>
							<Form.Item
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}
								label="Next Bill Date"
								name="nextBillDate">
									<DatePicker disabled style={{ width: '100%' }} format={DATE_FORMAT.DD_MM_YYYY} />
							</Form.Item>
							<Form.Item
								label="Payment Method"
								name="paymentMethod"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<Radio.Group style={{ width: '100%' }} options={PAYMENT_METHOD} optionType="button" buttonStyle="solid" />
							</Form.Item>
							{paymentMethodValue === PAYMENT_METHOD[1]?.value && (
								<Form.Item
									label="UPI TransactionId"
									name="upiTransactionId"
									rules={[
										{
											required: true,
											message: 'This Field is required!',
										},
									]}>
									<InputNumber placeholder="UPI TransactionId" style={{ width: '100%' }} />
								</Form.Item>
							)}
							<Form.Item
								label="Remarks"
								name="remarks"
								rules={[
									{
										required: false,
										message: 'This Field is required!',
									},
								]}>
								<Input.TextArea />
							</Form.Item>
							<Form.Item
								wrapperCol={{
									offset: 0,
									span: 24,
								}}>
								<Row className="space-between" style={{ paddingTop: 20, width: '100%', margin: 0 }}>
									<Button danger style={{ width: '49%' }} onClick={() => setPaymentAddModal(false)}>
										Cancel
									</Button>
									<Button loading={loading} style={{ width: '49%' }} type="primary" htmlType="submit">
										Pay
									</Button>
								</Row>
							</Form.Item>
						</Form>
					</Row>
				</Col>
			</Row>
		</Drawer>
	);
};

export default EditPayment;
