import React, { useEffect } from 'react';
import { Row, Col, Input, Button, Form, Drawer, InputNumber } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { putApi } from 'redux/sagas/putApiSaga';
import { API_STATUS } from 'constants/app-constants';
import { SERVER_IP } from 'assets/Config';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';

const AddPeriod = ({ periodAddModal, width = '40%', editPeriod, setPeriodAddModal, refreshList, handleClose }) => {
	const [form] = Form.useForm();
	const globalRedux = useSelector((state) => state.globalRedux);
	const branchId = globalRedux?.selectedBranch?.id;
	const dispatch = useDispatch();

	useEffect(() => {
		if (editPeriod) {
			form.setFieldsValue({
				name: editPeriod?.name || '',
				days: editPeriod?.days || '',
			});
		} else {
			form?.resetFields();
		}
	}, [editPeriod, form]);

	useEffect(() => {
		if (!periodAddModal) {
			form?.resetFields();
		}
	}, [periodAddModal, form]);

	const handleSubmit = (values) => {
		let data = {
			name: values?.name || '',
			days: values?.days || '',
			branchId,
		};

		if (!editPeriod) {
			dispatch(postApi(data, 'ADD_PERIOD'));
		} else {
			let url = `${SERVER_IP}api/period/${editPeriod.id}`;
			dispatch(putApi(data, 'EDIT_PERIOD', url));
		}
	};

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_PERIOD === 'SUCCESS' || globalRedux.apiStatus.EDIT_PERIOD === 'SUCCESS') {
			dispatch(resetApiStatus(editPeriod ? 'EDIT_PERIOD' : 'ADD_PERIOD'));
			refreshList?.();
			handleClose?.();
			form?.resetFields();
		}
	}, [globalRedux.apiStatus, editPeriod, setPeriodAddModal, dispatch, refreshList, handleClose, form]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const loading = globalRedux.apiStatus.ADD_PERIOD === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_PERIOD === API_STATUS.PENDING;

	return (
		<Drawer
			placement="right"
			title={`${editPeriod ? 'Edit' : 'New'} Period`}
			width={width || '40%'}
			visible={periodAddModal}
			closable
			onClose={() => setPeriodAddModal(false)}
			destroyOnClose={true}>
			<Row>
				<Col span={24}>
					<Row style={{ marginTop: 20 }}>
						<Form
							name="add-period"
							className="required_in_right"
							style={{ width: '100%' }}
							colon={true}
							labelAlign="left"
							form={form}
							onFinish={handleSubmit}
							initialValues={{}}
							{...layer1FormCol}>
							<Form.Item
								label="Name"
								name="name"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<Input placeholder="Name" />
							</Form.Item>
							<Form.Item
								label="Days"
								name="days"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<InputNumber
									placeholder="Days"
									style={{
										width: '100%',
									}}
								/>
							</Form.Item>
							<Form.Item
								wrapperCol={{
									offset: 0,
									span: 24,
								}}>
								<Row className="space-between" style={{ paddingTop: 20, width: '100%', margin: 0 }}>
									<Button danger style={{ width: '49%' }} onClick={() => setPeriodAddModal(false)}>
										Cancel
									</Button>
									<Button loading={loading} style={{ width: '49%' }} type="primary" htmlType="submit">
										{editPeriod ? 'Update' : 'Save'}
									</Button>
								</Row>
							</Form.Item>
						</Form>
					</Row>
				</Col>
			</Row>
		</Drawer>
	);
};

export default AddPeriod;
