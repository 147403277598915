import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { SERVER_IP } from 'assets/Config';
import HighlightComponent from 'components/HighlightComponent';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { generatePagination } from 'helpers';
import { API_STATUS } from 'constants/app-constants';
import UserListPresentational from './user-list-presentational';

const initialPageSize = 10;
const initialPageSizeOptions = [10, 15, 20];

const UserListFunctional = React.memo(() => {
	const globalRedux = useSelector((state) => state.globalRedux);
	const users = useSelector((state) => state?.globalRedux?.users);
	console.log('🚀 ~ file: user-list-functional.js:19 ~ UserListFunctional ~ users', users);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [userAddModal, setUserAddModal] = useState(false);
	const [searchKey, setSearchKey] = useState('');
	const [tableData, setTableData] = useState(users);
	const [editUser, setEditUser] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const dispatch = useDispatch();

	const getUsers = useCallback(() => {
		let url = `${SERVER_IP}user?orgId=${globalRedux?.selectedBranch?.id}`;
		dispatch(getApi('GET_USERS', url));
	}, [dispatch, globalRedux?.selectedBranch?.id]);

	useEffect(() => {
		getUsers();
	}, [getUsers]);

	useEffect(() => {
		generatePagination(tableData);
	}, [tableData]);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_CUSTOMER === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_CUSTOMER'));
			setSelectedRowKeys([]);
			doIt = true;
		}
		if (doIt) {
			getUsers();
		}
	}, [globalRedux.apiStatus, dispatch, getUsers]);

	useEffect(() => {
		setTableData(users);
	}, [users]);

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((user) => {
			return (
				(user?.userNumber || '')?.toString()?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(user?.displayName || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(user?.mobile || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(user?.email || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(user?.gstTreatment || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(user?.address || '')?.toString().toLowerCase().includes(searchKey.toLowerCase()) ||
				(user?.gstin || '')?.toString().toLowerCase().includes(searchKey.toLowerCase()) ||
				(user?.gstTreatment || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(user?.panCard || '')?.toLowerCase().includes(searchKey.toLowerCase) ||
				// (user?.remarks || '')?.toLowerCase().includes(searchKey.toLowerCase())
				(user?.outstandingBalance || '')?.toString().toLowerCase().includes(searchKey.toLowerCase())
			);
		});
	}, [tableData, searchKey]);

	const handleDrawer = (rowData) => {
		setEditUser(rowData);
		setUserAddModal(true);
	};

	const handleAddUser = () => {
		setEditUser(null);
		setUserAddModal(true);
	};

	const column = [
		// {
		// 	title: '#',
		// 	dataIndex: 'userNumber',
		// 	key: 'userNumber',
		// 	sorter: (a, b) => a?.userNumber - b?.userNumber,
		// 	// fixed: 'left',
		// 	render: (value) => (
		// 		<HighlightComponent
		// 			highlightClassName="highlightClass"
		// 			searchWords={[searchKey]}
		// 			autoEscape={true}
		// 			textToHighlight={value?.toString()}
		// 		/>
		// 	),
		// },
		{
			title: 'First Name',
			dataIndex: 'firstName',
			key: 'firstName',
			sorter: (a, b) => a?.firstName?.localeCompare(b?.firstName),
			// fixed: 'left',
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />
			),
		},
		{
			title: 'Last Name',
			dataIndex: 'lastName',
			key: 'lastName',
			sorter: (a, b) => a?.lastName?.localeCompare(b?.lastName),
			// fixed: 'left',
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />
			),
		},
		{
			title: 'Mobile',
			dataIndex: 'mobile',
			sorter: (a, b) => a?.mobile - b?.mobile,
			align: 'left',
			render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />,
		},
		{
			title: 'Email',
			dataIndex: 'email',
			sorter: (a, b) => a?.email?.localeCompare(b?.email),
			align: 'left',
			width: '15%',
			render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />,
		},
		{
			title: 'Access Level',
			dataIndex: 'accessLevel',
			sorter: (a, b) => a?.accessLevel?.localeCompare(b?.accessLevel),
			align: 'left',
			width: '15%',
			render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />,
		},
		{
			title: 'Action',
			align: 'center',
			dataIndex: 'displayName',
			render: (value, row, index) => {
				return (
					<Row justify="center">
						<Col className="edit_icon" onClick={() => handleDrawer(row)}>
							<EditOutlined />
						</Col>
					</Row>
				);
			},
		},
	];

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_CUSTOMERS === API_STATUS.PENDING, [globalRedux.apiStatus]);

	const onSelectChange = (selectedRowKeys) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		// fixed: true,
	};

	const handleClose = useCallback(() => {
		setUserAddModal(false);
		setEditUser(null);
	}, [setUserAddModal, setEditUser]);

	return (
		<UserListPresentational
			{...{
				filteredData,
				column,
				tableLoading,
				rowSelection,
				selectedRowKeys,
				handleAddUser,
				currentPage,
				pageSize,
				initialPageSizeOptions,
				initialPageSize,
				handleTableChange,
				setSearchKey,
				getStartingValue,
				getEndingValue,
				userAddModal,
				setUserAddModal,
				refreshList: getUsers,
				editUser,
				handleClose,
			}}
		/>
	);
});

export default UserListFunctional;
