import { SET_OUTSTANDING } from '../constants';

function setOutstanding(data) {
	return {
		type: SET_OUTSTANDING,
		payload: data,
	};
}

export { setOutstanding };
