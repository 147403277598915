
function setSuppliers(data){
    return{
        type : "SET_SUPPLIERS",
        payload : data
    }
}

export {  
    setSuppliers,
}