import React, { useEffect } from 'react';
import { Form, Input, Button, Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { postApi } from 'redux/sagas/postApiDataSaga';
// import loginImg from 'assets/images/login1.jpg';
import { LogoComponent } from 'components/side-bar';
import LottieComponent from 'components/lottie-component';
import LottieFile from 'assets/lottie-files';
import './login.scss';

const Login = (props) => {
	const [form] = Form.useForm();
	const loginRedux = useSelector((state) => state.loginRedux);
	const globalRedux = useSelector((state) => state.globalRedux);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const FormProps = {
		labelCol: {
			span: 8,
		},
		wrapperCol: {
			span: 24,
		},
	};

	useEffect(() => {
		if (!!loginRedux.token && loginRedux.isLogged && loginRedux.email && loginRedux.email !== 'sathishcse1975@gmail.com') {
			navigate('/branches');
		}
		if (!!loginRedux.token && loginRedux.isLogged && loginRedux.email === 'sathishcse1975@gmail.com') {
			navigate('/');
		}
	}, [loginRedux.token, loginRedux.isLogged, loginRedux.email, navigate]);

	const login = (data) => {
		dispatch(postApi(data, 'LOGIN'));
	};

	return (
		<Row className="login_container" style={{ height: '100vh', backgroundColor: '#fff' }}>
			<Col xl={12} md={12} className="login_image">
				<div style={{ fontWeight: 'bold', fontSize: 30, color: '#000', paddingBottom: 30 }}>
					Welcome to FITTE<span style={{ color: '#4d7cfe' }}>.in</span>
				</div>
				<div style={{ fontWeight: 'bold', fontSize: 30, color: '#000', padding: '0 20px', textAlign: 'center' }}>
					#1 All in one application for gym management.
				</div>
				<div style={{ fontWeight: 'bold', fontSize: 20, color: '#000', padding: '0 20px', textAlign: 'center' }}>
					Sync all your Customers, Coaches, Memberships and Products in One App.
				</div>
				<LottieComponent file={LottieFile.Analytics} height="80%" />
			</Col>
			<Col xl={12} md={12}>
				<Row justify="center" align="middle" style={{ height: '100%' }}>
					<Col xl={12} md={12}>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}>
							<img
								src={require('../../assets/images/logo.png')}
								style={{
									width: 80,
								}}
								alt="logo"
							/>
						</div>
						<LogoComponent style={{ fontSize: 30 }} textColor="#000" />
						<Col span={24}>
							<div style={{ fontWeight: 'bold', fontSize: 30, color: '#000' }}>
								Welcome <span style={{ color: '#4d7cfe' }}>back!</span>
							</div>
							<div style={{ color: 'lightgray' }}>Welcome back! Please enter your details.</div>
						</Col>
						<Col xl={24} style={{ paddingTop: 20 }}>
							<Form requiredMark={false} colon={true} layout="vertical" labelAlign="left" form={form} onFinish={login} {...FormProps}>
								<Form.Item
									label="Email / Mobile"
									name="email"
									rules={[
										{
											required: true,
											message: 'Please enter your email/mobile',
										},
									]}>
									<Input placeholder="Enter your email or mobile number" />
								</Form.Item>

								<Form.Item
									label="Password"
									name="password"
									rules={[
										{
											required: true,
											message: 'Please enter your password',
										},
									]}>
									<Input.Password visibilityToggle placeholder="Enter your password" />
								</Form.Item>

								{/* <Link className="nav-link" to="/forgot-password" style={{ fontSize: '12px' }}>
												Forgot Password?
											</Link> */}

								<Form.Item>
									<Button
										style={{ width: '100%', fontWeight: 'bold' }}
										type="primary"
										htmlType="submit"
										disabled={globalRedux.apiStatus.LOGIN === 'PENDING'}
										loading={globalRedux.apiStatus.LOGIN === 'PENDING'}>
										LOGIN
									</Button>
								</Form.Item>
							</Form>
							<Row>
								<Col>
									<Row>
										{/* <Link className="nav-link" to="/forgot-password" style={{ fontSize: '12px' }}>
														Forgot Password?
													</Link> */}
										<br />
									</Row>
								</Col>
								{/* <Col>
									<Row>
										<Link className="nav-link" to="/register" style={{ fontSize: '12px', textAlign: 'right' }}>
											New User?
										</Link>
									</Row>
								</Col> */}
							</Row>
						</Col>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default Login;
