import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Popconfirm, Button, Pagination, Row, Col } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { generatePagination } from 'helpers';
import TableComponent from 'components/table-component';
import AddDrawer from 'components/drawer-component';
import { getApi } from '../../redux/sagas/getApiDataSaga';
import { SERVER_IP } from '../../assets/Config';
import { getDateFormat } from '../../services/Utils';
import { deleteApi } from '../../redux/sagas/deleteApiSaga';
import { resetApiStatus } from '../../redux/reducers/globals/globalActions';
import HighlightComponent from '../../components/HighlightComponent';

const initialPageSize = 10;
const initialPageSizeOptions = [10, 15, 20];

const Vehicles = React.memo(() => {
	const globalRedux = useSelector((state) => state?.globalRedux);
	const dispatch = useDispatch();

	const [vehicleAddModal, setVehicleAddModal] = useState(false);
	const [searchKey] = useState('');
	const [tableData, setTableData] = useState(globalRedux?.vehicles || []);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const [editVehicle, setEditAsset] = useState(null);

	const getVehicle = useCallback(() => {
		let url = `${SERVER_IP}vehicle?orgId=${globalRedux?.selectedBranch?.id}`;
		dispatch(getApi('GET_VEHICLES', url));
	}, [dispatch, globalRedux?.selectedBranch?.id]);

	useEffect(() => {
		// getVehicle();
		generatePagination(tableData);
	}, [getVehicle, tableData]);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_VEHICLE === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_VEHICLE'));
			doIt = true;
		}
		if (doIt) {
			getVehicle();
		}
	}, [globalRedux.apiStatus, getVehicle, dispatch]);

	useEffect(() => {
		setTableData(globalRedux?.vehicles || []);
	}, [globalRedux?.vehicles]);

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((asset) => {
			return (
				(asset?.assetName || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(asset?.openingBalance || '')?.toLowerCase().includes(searchKey.toLowerCase())
			);
		});
	}, [tableData, searchKey]);

	const handleDrawer = (rowData) => {
		setEditAsset(rowData);
		setVehicleAddModal(true);
	};

	const handleStaff = () => {
		setEditAsset(null);
		setVehicleAddModal(true);
	};

	const column = [
		{
			title: 'Vehicle name',
			dataIndex: 'vehicleName',
			key: 'vehicleName',
			// fixed: 'left',
			width: '15%',
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />
			),
		},
		{
			title: 'Reg. Number',
			dataIndex: 'regNumber',
			key: 'regNumber',
			// fixed: 'left',
			width: '10%',
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />
			),
		},
		{
			title: 'Vehicle Type',
			dataIndex: 'vehicleType',
			key: 'vehicleType',
			// fixed: 'left',
			width: '10%',
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />
			),
		},
		{
			title: 'Remarks',
			dataIndex: 'remarks',
			width: '10%',
			render: (value) => value,
		},
		{
			title: 'Created Date',
			dataIndex: 'created_at',
			align: 'left',
			width: '15%',
			render: (value) => getDateFormat(value),
		},
		{
			title: 'Created By',
			dataIndex: 'createdBy',
			align: 'left',
			width: '10%',
			render: (value) => value?.firstName,
		},
		{
			title: 'Action',
			align: 'center',
			width: '10%',
			dataIndex: 'vehicleName',
			render: (value, row) => {
				return (
					<Row gutter={10} justify="center">
						<Col onClick={() => handleDrawer(row)} className="edit_icon">
							<EditOutlined />
						</Col>
						<Col className="delete_icon">
							<Popconfirm
								title={`Are You Sure to Delete ${value}?`}
								okText="Delete"
								cancelText="No"
								placement="rightTop"
								onConfirm={() => {
									let url = `${SERVER_IP}vehicle/${row._id}?orgId=${globalRedux?.selectedBranch?.id}`;
									dispatch(deleteApi('DELETE_VEHICLE', url));
								}}>
								<CloseOutlined />
							</Popconfirm>
						</Col>
					</Row>
				);
			},
		},
	];

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	return (
		<>
			<Row style={{ padding: '20px 10px' }}>
				<Col xl={24}>
					<TableComponent
						className="custom-table"
						style={{ width: '100%' }}
						columns={column}
						bordered
						rowKey={(record) => record._id}
						dataSource={filteredData}
						title={() => (
							<Row justify="space-between">
								<Col md={3}>
									{/* <Input
										placeholder="Search"
										suffix={<AiOutlineSearch />}
										style={{ height: '30px', borderRadius: '8px' }}
										onChange={({ target: { value } }) => setSearchKey(value)}
									/> */}
								</Col>
								<Col md={7}></Col>
								<Col>
									<Button type="primary" style={{ height: '30px', width: '100%' }} onClick={() => handleStaff(true)}>
										Add Vehicle
									</Button>
								</Col>
							</Row>
						)}
						pagination={{
							current: currentPage,
							pageSize: pageSize,
							position: ['none', 'none'],
						}}
						footer={() => (
							<Row justify="space-between">
								<Col>
									<div>
										{!!filteredData?.length &&
											`Showing ${getStartingValue()} - ${getEndingValue()} of ${filteredData?.length} Data`}
									</div>
								</Col>

								<Col md={8}>
									<div style={{ textAlign: 'right' }}>
										<Pagination
											pageSizeOptions={initialPageSizeOptions}
											defaultPageSize={initialPageSize}
											showSizeChanger={true}
											total={filteredData?.length}
											onChange={handleTableChange}
											responsive
										/>
									</div>
								</Col>
							</Row>
						)}
						onChange={handleTableChange}
					/>
				</Col>
			</Row>
			<AddDrawer {...{ vehicleAddModal, setVehicleAddModal, getVehicle, editVehicle }} />
		</>
	);
});

export default Vehicles;
