import { SET_OUTSTANDING } from '../constants';

let initialState = {
	outstanding: [],
};

const outstandingReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_OUTSTANDING:
			return {
				...state,
				outstanding: action.payload,
			};
		default:
			return state;
	}
};

export { outstandingReducer };
