import React, { useCallback, useEffect } from 'react';
import { Button, Col, Input, Row, Select } from 'antd';
import { formQueryStringFromObject } from 'helpers';
import { SERVER_IP } from 'assets/Config';
import { useDispatch, useSelector } from 'react-redux';
import { getApi } from 'redux/sagas/getApiDataSaga';

const { Option } = Select;

const SendSingleSMS = () => {
	// const [value, setValue] = useState(1);
	const customers = useSelector((state) => state?.customerRedux?.customers || []);
	const globalRedux = useSelector((state) => state.globalRedux);
	const dispatch = useDispatch();
	const branchId = globalRedux?.selectedBranch?.id;

	const getCustomers = useCallback(() => {
		const string = formQueryStringFromObject({
			branchId,
		});
		let url = `${SERVER_IP}api/customers?${string}`;
		branchId && dispatch(getApi('GET_CUSTOMERS', url));
	}, [dispatch, branchId]);

	useEffect(() => {
		getCustomers();
	}, [getCustomers]);

	// const onChange = (e) => {
	// 	setValue(e.target.value);
	// };
	return (
		<Row style={{ padding: 20 }}>
			<Col span={24}>
				<h3>Send Single SMS: Available sms counts:1400</h3>
			</Col>
			<Col span={24}>
				<Row gutter={[10, 10]}>
					<Col span={24}>
						<p>Send Single SMS Page</p>
					</Col>
					<Col span={24}>
						<Select
							mode="multiple"
							style={{ width: '100%' }}
							placeholder="Select items"
							onChange={(value) => console.log(value)}
							optionFilterProp="children">
							{customers.map((customer) => (
								<Option key={customer.id} value={`${customer.customerName} - ${customer.mobile}`}>
									{customer.customerName} - {customer.mobile}
								</Option>
							))}
						</Select>
					</Col>
					<Col span={24}>
						<Input.TextArea placeholder="Enter your message here" rows={4} />
					</Col>
					<Col span={24}>
						<Button type="primary">Send SMS</Button>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default SendSingleSMS;
