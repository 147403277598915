import React, { Fragment } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ children }) => {
	const User = useSelector((state) => state.loginRedux);
	if (!User.isLogged) {
		return <Navigate to={'/login'} replace />;
	}
	return <Fragment>{children}</Fragment>;
};

export default ProtectedRoute;
