let initialState = {
    periods : [],
};

const periodReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PERIODS" : 
      return {
        ...state,
        periods : action.payload
      }   
    default : return state
  }
}

export { periodReducer }
