import React from 'react';
import { Row, Col } from 'antd';
import TableComponent from 'components/table-component';

const SMSPricing = () => {
	const columns = [
		{
			title: 'S.NO',
			dataIndex: 'sno',
			key: 'sno',
		},
		{
			title: 'Quantity',
			dataIndex: 'quantity',
			key: 'quantity',
		},
		{
			title: 'Validity',
			dataIndex: 'validity',
			key: 'validity',
		},
		{
			title: 'Price',
			dataIndex: 'price',
			key: 'price',
		},
		{
			title: 'Total (WITH TAX)',
			dataIndex: 'totalWithTax',
			key: 'totalWithTax',
		},
	];

	const data = [
		{
			key: '1',
			sno: '1',
			quantity: '10k',
			validity: '5 years',
			price: '₹ 2000',
			totalWithTax: '₹ 2360',
		},
		{
			key: '2',
			sno: '2',
			quantity: '25k',
			validity: '5 years',
			price: '₹ 5000',
			totalWithTax: '₹ 5900',
		},
		{
			key: '3',
			sno: '3',
			quantity: '50k',
			validity: '5 years',
			price: '₹ 10000',
			totalWithTax: '₹ 11800',
		},
		{
			key: '4',
			sno: '4',
			quantity: '1L',
			validity: '5 years',
			price: '₹ 20000',
			totalWithTax: '₹ 23600',
		},
	];

	return (
		<Row style={{ padding: 20 }}>
			<Col span={18}>
				<h3>SMS Pricing</h3>
				<TableComponent columns={columns} dataSource={data} pagination={false} />
			</Col>
		</Row>
	);
};

export default SMSPricing;
