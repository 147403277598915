import { CLOSE_GYM_PAYMENT_MODAL, OPEN_GYM_PAYMENT_MODAL, SET_EDIT_GYM_PAYMENT, SET_GYM_PAYMENTS, SET_GYM_PAYMENTS_PAGINATION } from '../constants';

function setGymPayments(data) {
	return {
		type: SET_GYM_PAYMENTS,
		payload: data,
	};
}

export function setGymPaymentsPagination(data){
    return{
        type : SET_GYM_PAYMENTS_PAGINATION,
        payload : data
    }
}

function setEditGymPayment(data){
	return {
		type: SET_EDIT_GYM_PAYMENT,
		payload: data,
	};
}

function openGymPaymentModal(data) {
	return {
		type: OPEN_GYM_PAYMENT_MODAL,
		payload: data,
	};
}

function closeGymPaymentModal(data) {
	return {
		type: CLOSE_GYM_PAYMENT_MODAL,
		payload: data,
	};
}

export { setGymPayments, openGymPaymentModal, closeGymPaymentModal, setEditGymPayment };
