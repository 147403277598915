import React, { forwardRef } from 'react';
import moment from 'moment';
import { DATE_FORMAT } from 'constants/app-constants';
import { useSelector } from 'react-redux';

const VoucherToPrint = forwardRef((props, ref) => {
	const { firstName = '', lastName = '' } = useSelector((state) => state?.loginRedux);
	const { voucherNumber, voucherDate, amount, voucherheadId: { voucherheadName } = {} } = props?.data || {};
	return (
		<div class="voucher-box" ref={ref}>
			<table cellPadding="0" cellSpacing="0">
				<tr class="top">
					<td colSpan="3">
						<table className="bordered-bottom">
							<tr>
								<td class="title">
									<div>Voucher</div>
									<div>R K V Matriculation Higher Secondary School, Jedarpalayam</div>
								</td>
							</tr>
						</table>
					</td>
				</tr>
				<tr class="information">
					<td colSpan="3">
						<table className="bordered-bottom">
							<tr>
								<td>
									<div className="row">
										<div className="label">Voucher No#:</div>
										<div className="value">{voucherNumber}</div>
									</div>
									<div className="row">
										<div className="label">Voucher Date:</div>
										<div className="value">{moment(voucherDate).format(DATE_FORMAT.DD_MM_YYYY) || ''}</div>
									</div>
								</td>
								<td></td>
								<td></td>
								<td>
									<div className="row">
										<div
											className="label"
											style={{
												width: '52%',
											}}>
											Printed On:
										</div>
										<div
											className="value"
											style={{
												width: '48%',
											}}>
											{moment().format(`${DATE_FORMAT.DD_MM_YYYY}, h:mm A`) || ''}
										</div>
									</div>
									<div className="row">
										<div
											className="label"
											style={{
												width: '52%',
											}}>
											Voucher By:
										</div>
										<div
											className="value"
											style={{
												width: '48%',
											}}>
											{`${firstName} ${lastName}`}
										</div>
									</div>
								</td>
							</tr>
						</table>
					</td>
				</tr>
				<tr class="heading">
					<td width="40%">Particulars</td>
					<td width="40%">Amount</td>
				</tr>
				<tr class="item">
					<td>{voucherheadName}</td>
					<td>{amount}</td>
				</tr>
				<tr class="total">
					<td></td>
					<td>Total: {amount}</td>
				</tr>
			</table>

			<div class="voucher-footer">
				<div class="voucher-footer-left">Authorized Signature</div>
				<div class="voucher-footer-right">Receiver's Signature</div>
			</div>
		</div>
	);
});

export default VoucherToPrint;
