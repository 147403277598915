import React, { useEffect, useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Col, Divider, Input, Row, Select } from 'antd';
import { arrayMoveImmutable } from 'array-move';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { useDispatch, useSelector } from 'react-redux';
import { SERVER_IP } from 'assets/Config';
import { getApi } from 'redux/sagas/getApiDataSaga';
import AddInvoicePresentational from './add-invoice-presentational';
import { DeleteOutlined, MenuOutlined, PlusOutlined } from '@ant-design/icons';

const itemDefaultRecord = {
	itemId: '',
	quantity: 1,
	rate: 0,
	discount: 0,
	discountType: 'PERCENT',
	tax: '',
	description: '',
	id: uuidv4(),
	index: 0,
};

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

const AddInvoiceFunctional = () => {
	const [tableData, setTableData] = useState([itemDefaultRecord]);
	const [customerAddModal, setCustomerAddModal] = useState(false);
	const [itemAddModal, setItemAddModal] = useState(false);
	const items = useSelector((state) => state.itemRedux?.items || []);
	const customers = useSelector((state) => state.customerRedux?.customers || []);
	const globalRedux = useSelector((state) => state.globalRedux);
	const dispatch = useDispatch();

	const SortableItem = SortableElement((props) => <tr {...props} />);
	const SortableBody = SortableContainer((props) => <tbody {...props} />);

	const getItems = useCallback(() => {
		let url = `${SERVER_IP}item?orgId=${globalRedux?.selectedBranch?.id}`;
		dispatch(getApi('GET_ITEMS', url));
	}, [dispatch, globalRedux?.selectedBranch?.id]);

	const getCustomers = useCallback(() => {
		let url = `${SERVER_IP}customer?orgId=${globalRedux?.selectedBranch?.id}`;
		dispatch(getApi('GET_CUSTOMERS', url));
	}, [dispatch, globalRedux?.selectedBranch?.id]);

	useEffect(() => {
		getCustomers();
	}, [getCustomers]);

	useEffect(() => {
		getItems();
	}, [getItems]);

	const handleInputChange = useCallback(
		(label, value, rowId) => {
			const data = tableData.map((data) => {
				if (data.id === rowId) {
					return {
						...data,
						[label]: value,
					};
				} else {
					return data;
				}
			});
			setTableData([...data]);
		},
		[tableData]
	);

	const handleAddTableData = useCallback(() => {
		let data = [...tableData];
		data.push({
			...itemDefaultRecord,
			id: uuidv4(),
			index: tableData?.length,
		});
		setTableData(data);
	}, [tableData]);

	const handleRemove = (id) => {
		const data = tableData.filter((data) => data.id !== id);
		setTableData([...data]);
	};

	useEffect(() => {
		const filledList = tableData?.map((data) => data.itemId).filter((data) => data);
		if (tableData?.length === filledList.length) {
			handleAddTableData();
		}
	}, [tableData, handleAddTableData]);

	const columns = [
		{
			title: 'Sort',
			dataIndex: 'sort',
			width: 30,
			className: 'drag-visible',
			render: () => <DragHandle />,
		},
		{
			title: 'Item Details',
			dataIndex: 'itemId',
			key: 'itemId',
			width: '40%',
			render: (value, record) => (
				<Row>
					<Col span={24}>
						<Select
							{...{
								...(value && { value }),
							}}
							optionFilterProp="children"
							style={{ width: '100%' }}
							placeholder="Select item"
							onChange={(value) => handleInputChange('itemId', value, record?.id)}
							dropdownRender={(menu) => (
								<div>
									{menu}
									<Divider />
									<div style={{ display: 'flex', flexWrap: 'nowrap' }}>
										<a
											href
											style={{ flex: 'none', color: '#188dfa', padding: '8px', display: 'block', cursor: 'pointer' }}
											onClick={() => setItemAddModal(true)}>
											<PlusOutlined /> Item
										</a>
									</div>
								</div>
							)}>
							{items?.map((item) => (
								<Select.Option value={item?._id}>{item?.itemName}</Select.Option>
							))}
						</Select>
					</Col>

					<Col span={24} style={{ paddingTop: 10 }}>
						<Input.TextArea
							value={record?.description}
							onChange={({ target: { value } }) => handleInputChange('description', value, record?.id)}
							disabled={!record?.itemId}
							style={{ width: '100%' }}
							placeholder="Add a description to your item"
						/>
					</Col>
				</Row>
			),
		},
		{
			title: 'Quantity',
			dataIndex: 'quantity',
			key: 'quantity',
			width: '12%',
			align: 'right',
			render: (value, record) => (
				<Input
					value={value}
					onChange={({ target: { value } }) => handleInputChange('quantity', parseFloat(value), record?.id)}
					type="number"
					pattern="^-?[0-9]\d*\.?\d*$"
					className="textAlignRight"
					style={{ width: '100%' }}
					placeholder="Enter quantity"
				/>
			),
		},
		{
			title: 'Rate',
			dataIndex: 'rate',
			align: 'left',
			width: '12%',
			render: (value, record) => (
				<Input
					value={value}
					onChange={({ target: { value } }) => handleInputChange('rate', parseFloat(value), record?.id)}
					type="number"
					pattern="^-?[0-9]\d*\.?\d*$"
					className="textAlignRight"
					style={{ width: '100%' }}
					placeholder="Enter rate"
				/>
			),
		},
		{
			title: 'Discount',
			dataIndex: 'discount',
			align: 'left',
			width: '12%',
			render: (value, record) => (
				<Row style={{ flexWrap: 'nowrap' }}>
					<Col>
						<Input
							value={value}
							onChange={({ target: { value } }) => handleInputChange('discount', parseFloat(value), record?.id)}
							type="number"
							pattern="^-?[0-9]\d*\.?\d*$"
							className="textAlignRight"
							style={{ width: 60 }}
							placeholder="Enter discount"
						/>
					</Col>
					<Col>
						<Select
							defaultValue={'PERCENT'}
							style={{ width: 55 }}
							onChange={(value) => handleInputChange('discountType', value, record?.id)}>
							<Select.Option value="PERCENT">%</Select.Option>
							<Select.Option value="AMOUNT">₹</Select.Option>
						</Select>
					</Col>
				</Row>
			),
		},
		{
			title: 'Tax',
			dataIndex: 'tax',
			align: 'left',
			width: '12%',
			render: (value, record) => (
				<Select
					{...{
						...(value && { value }),
					}}
					placeholder="Select tax"
					style={{ width: 100 }}
					onChange={(value) => handleInputChange('tax', value, record?.id)}>
					<Select.Option value="0">GST 0%</Select.Option>
					<Select.Option value="5">GST 5%</Select.Option>
					<Select.Option value="12">GST 12%</Select.Option>
					<Select.Option value="18">GST 18%</Select.Option>
					<Select.Option value="28">GST 28%</Select.Option>
				</Select>
			),
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			align: 'right',
			width: '12%',
			render: (value, record) => {
				let answer = 0;
				const total = parseFloat(record?.quantity || 0) * parseFloat(record?.rate || 0);
				if (record?.discountType === 'PERCENT') {
					answer = total - total * ((record?.discount || 0) / 100);
				}
				if (record?.discountType === 'AMOUNT') {
					answer = total - (record?.discount || 0);
				}
				return <span style={{ fontWeight: 'bold' }}>{parseFloat(answer).toFixed(2)}</span>;
			},
		},
		{
			title: 'Action',
			dataIndex: 'item',
			key: 'item',
			align: 'center',
			width: '3%',
			render: (value, record) =>
				tableData.length > 1 && record?.itemId ? <DeleteOutlined style={{ color: 'red' }} onClick={() => handleRemove(record.id)} /> : null,
		},
	];

	const onSortEnd = ({ oldIndex, newIndex }) => {
		if (oldIndex !== newIndex) {
			const newData = arrayMoveImmutable(tableData.slice(), oldIndex, newIndex).filter((el) => !!el);
			console.log('Sorted items: ', newData);
			setTableData(newData);
		}
	};

	const DraggableContainer = (props) => (
		<SortableBody useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
	);

	const DraggableBodyRow = ({ className, style, ...restProps }) => {
		// function findIndex base on Table rowKey props and should always be a right array index
		const index = tableData.findIndex((x) => {
			return x.index === restProps['data-row-key'];
		});
		return <SortableItem index={index} {...restProps} />;
	};
	console.log('🚀 ~ file: add-invoice-functional.js ~ line 279 ~ DraggableBodyRow ~ tableData', tableData);
	return (
		<AddInvoicePresentational
			{...{
				columns,
				dataSource: tableData,
				customers,
				customerAddModal,
				setCustomerAddModal,
				getCustomers,
				itemAddModal,
				setItemAddModal,
				getItems,
				DraggableContainer,
				DraggableBodyRow,
			}}
		/>
	);
};

export default AddInvoiceFunctional;
