import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import AddTicketPresentational from './add-ticket-presentational';
import { SERVER_IP } from 'assets/Config';
import { ACTIONS } from 'constants/app-constants';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { sendGetRequest } from 'redux/sagas/utils';
import { debounce } from 'lodash';

const AddTicketFunctional = ({ toggleVisible, refreshList, visible }) => {
	const [form] = Form.useForm();
	const [searchString, setSearchString] = useState('');
	const [inputString, setInputString] = useState('');
	const [selectedCustomer, setSelectedCustomer] = useState(null);
	const [searchList, setSearchList] = useState({
		data: [],
		loading: false,
	});
	// const customerData = useSelector((state) => state.customerRedux?.customers);
	// const itemData = useSelector((state) => state.itemRedux?.items);
	const globalRedux = useSelector((state) => state.globalRedux);
	const organizationId = globalRedux?.selectedBranch?.id;
	const dispatch = useDispatch();

	const handleSearch = useCallback(
		async (searchString) => {
			setSearchString(searchString);
			if (searchString) {
				setSearchList({
					loading: true,
				});
				const { data } = await sendGetRequest(
					null,
					`${SERVER_IP}customer/search?orgId=${globalRedux?.selectedBranch?.id}&searchText=${searchString}`
				);
				setSearchList({ data, loading: false });
			}
		},
		[globalRedux?.selectedBranch?.id]
	);

	useEffect(() => {
		if (!searchString || !visible) {
			setSearchList({
				data: [],
				loading: false,
			});
		}
	}, [searchString, visible]);

	const debounceFn = debounce(handleSearch, 1000);

	const getItems = useCallback(() => {
		let url = `${SERVER_IP}item?orgId=${organizationId}`;
		dispatch(getApi(ACTIONS.GET_ITEMS, url));
	}, [dispatch, organizationId]);

	const getCustomers = useCallback(() => {
		let url = `${SERVER_IP}customer?orgId=${organizationId}`;
		dispatch(getApi(ACTIONS.GET_CUSTOMERS, url));
	}, [dispatch, organizationId]);

	useEffect(() => {
		getItems();
		getCustomers();
		handleSearch();
	}, [getCustomers, getItems, handleSearch]);

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_TICKET === 'SUCCESS') {
			form.resetFields();
			getItems();
			refreshList();
			toggleVisible(false);
			dispatch(resetApiStatus('ADD_TICKET'));
		}
	}, [globalRedux.apiStatus, getItems, refreshList, dispatch, toggleVisible, form]);

	const handleRowClick = (data) => {
		form.setFieldsValue({
			customerName: data?.displayName,
			mobile: data?.mobile,
			address: `${data?.billingDetails?.[0]?.addressLine1 || ''} ${data?.billingDetails?.[0]?.addressLine2 || ''}`,
		});
		setSelectedCustomer(data);
		setSearchString('');
		setInputString('');
	};

	const handleSubmit = (values) => {
		const request = {
			orgId: globalRedux?.selectedBranch?.id,
			customerId: selectedCustomer?.id,
			...values,
			// customerId: '62642c8fcd1f4500e4b6b219',
		};
		delete request?.customerName;
		dispatch(postApi(request, 'ADD_TICKET'));
	};

	return (
		<AddTicketPresentational
			{...{
				visible,
				toggleVisible,
				form,
				handleSubmit,
				debounceFn,
				searchList,
				searchString,
				handleRowClick,
				inputString,
				setInputString,
			}}
		/>
	);
};

export default AddTicketFunctional;
