import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { Row, Col, Button, Form, Drawer, Select, Divider, Input } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { useHotkeys } from 'react-hotkeys-hook';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { API_STATUS } from 'constants/app-constants';
import { SERVER_IP } from 'assets/Config';
import { formQueryStringFromObject } from 'helpers';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { putApi } from 'redux/sagas/putApiSaga';
import TimePicker from 'components/time-picker';
import FoodModal from 'components/food-modal';

const layer1FormCol = {
	labelCol: { span: 5 },
	wrapperCol: { span: 8 },
};

const defaultDietItem = () => ({
	id: uuidv4(),
	time: null,
	food: '',
	qty: '',
});

const createDefaultDietPlan = (weekCount = 1) => {
	const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
	const weeks = Array.from({ length: weekCount }, (_, i) => `Week${i + 1}`);
	return weeks.reduce((acc, week) => {
		acc[week] = days.reduce((dayAcc, day) => {
			dayAcc[day] = Array.from({ length: 4 }, () => defaultDietItem()); // Create 4 default diet items
			return dayAcc;
		}, {});
		return acc;
	}, {});
};

const AddDietPlan = ({ dietPlanAddModal, width = '70%', editDietPlan, setDietPlanAddModal, refreshList, handleClose }) => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const globalRedux = useSelector((state) => state.globalRedux);
	const branchId = globalRedux?.selectedBranch?.id;
	const customers = useSelector((state) => state?.customerRedux?.customers || []);
	const buttonRef = useRef();

	const [dietPlans, setDietPlans] = useState(createDefaultDietPlan(1));

	const [, setHistory] = useState([dietPlans]);

	useHotkeys('ctrl + n', () => buttonRef?.current?.click());
	useHotkeys('ctrl + z', () => undoLastChange());

	const undoLastChange = () => {
		setHistory((prevHistory) => {
			if (prevHistory.length > 1) {
				const newHistory = prevHistory.slice(0, -1);
				setDietPlans(newHistory[newHistory.length - 1]);
				return newHistory;
			}
			return prevHistory;
		});
	};

	const customerLoading = useMemo(() => globalRedux?.apiStatus.GET_CUSTOMER === API_STATUS.PENDING, [globalRedux.apiStatus]);
	const apiString = useMemo(() => formQueryStringFromObject({ branchId }), [branchId]);

	const fetchCustomers = useCallback(() => {
		if (branchId) {
			dispatch(getApi('GET_CUSTOMERS', `${SERVER_IP}api/customers?${apiString}`));
		}
	}, [dispatch, branchId, apiString]);

	const fetchCoaches = useCallback(() => {
		dispatch(getApi('GET_COACHES', `${SERVER_IP}api/coaches?${apiString}`));
	}, [dispatch, apiString]);

	useEffect(() => {
		fetchCoaches();
		fetchCustomers();
	}, [fetchCoaches, fetchCustomers]);

	const addDietItem = (week, day) => {
		setDietPlans((prevPlans) => {
			const newDietItem = defaultDietItem();
			const newDiets = {
				...prevPlans,
				[week]: {
					...prevPlans[week],
					[day]: [...prevPlans[week][day], newDietItem],
				},
			};
			setHistory((prevHistory) => [...prevHistory, newDiets]);
			return newDiets;
		});
	};

	const updateDietItem = (week, day, id, field, value) => {
		setDietPlans((prevPlans) => {
			const newDiets = prevPlans[week][day].map((item) => (item.id === id ? { ...item, [field]: value } : item));
			const updatedPlans = { ...prevPlans, [week]: { ...prevPlans[week], [day]: newDiets } };
			setHistory((prevHistory) => [...prevHistory, updatedPlans]);
			return updatedPlans;
		});
	};

	const removeDietItem = (week, day, id) => {
		setDietPlans((prevPlans) => {
			const newDiets = prevPlans[week][day].filter((item) => item.id !== id);
			const updatedPlans = { ...prevPlans, [week]: { ...prevPlans[week], [day]: newDiets } };
			setHistory((prevHistory) => [...prevHistory, updatedPlans]);
			return updatedPlans;
		});
	};

	const addWeek = () => {
		setDietPlans((prevPlans) => {
			const newWeekNumber = Object.keys(prevPlans).length + 1;
			const newWeek = `Week${newWeekNumber}`;
			const newDiets = {
				...prevPlans,
				[newWeek]: {
					Monday: [defaultDietItem()],
					Tuesday: [defaultDietItem()],
					Wednesday: [defaultDietItem()],
					Thursday: [defaultDietItem()],
					Friday: [defaultDietItem()],
					Saturday: [defaultDietItem()],
					Sunday: [defaultDietItem()],
				},
			};
			setHistory((prevHistory) => [...prevHistory, newDiets]);
			return newDiets;
		});
	};

	const removeWeek = (week) => {
		setDietPlans((prevPlans) => {
			const { [week]: _, ...remainingWeeks } = prevPlans; // Destructure to omit the week
			setHistory((prevHistory) => [...prevHistory, remainingWeeks]);
			return remainingWeeks;
		});
	};

	const handleSubmit = (values) => {
		const data = { ...values, branchId, dietPlans, paidFor: 'personal_training' };
		const apiMethod = editDietPlan ? putApi : postApi;
		const apiEndpoint = editDietPlan ? `api/dietPlan/${editDietPlan.id}` : 'ADD_DIET_PLAN';

		dispatch(apiMethod(data, editDietPlan ? 'EDIT_DIET_PLAN' : 'ADD_PERSONAL_TRAINING', `${SERVER_IP}${apiEndpoint}`));
	};

	const onClose = () => {
		handleClose();
		setDietPlanAddModal(false);
		form.resetFields();
		setDietPlans(createDefaultDietPlan(1));
		setHistory([createDefaultDietPlan(1)]);
	};

	return (
		<Drawer
			placement="right"
			title={`${editDietPlan ? 'Edit' : 'New'} Diet Plan`}
			width={width}
			open={dietPlanAddModal}
			closable
			onClose={onClose}
			destroyOnClose>
			<Row>
				<Col span={24}>
					<Form
						scrollToFirstError
						name="add-dietPlan"
						className="required_in_right"
						style={{ width: '100%' }}
						colon
						labelAlign="left"
						form={form}
						onFinish={handleSubmit}
						initialValues={{}}
						{...layer1FormCol}>
						<Form.Item label="Customer Name" name="customerId" rules={[{ required: true, message: 'This Field is required!' }]}>
							<Select placeholder="Select Customer" loading={customerLoading}>
								{customers.map((customer) => (
									<Select.Option
										key={customer.id}
										value={customer.id}>{`${customer.customerName} - ${customer.mobile}`}</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item label="Category" name="category" rules={[{ required: true, message: 'This Field is required!' }]}>
							<Select placeholder="Select Category">
								{[
									{ id: 1, categoryName: 'Fat Loss' },
									{ id: 2, categoryName: 'Weight Gain' },
								].map((category) => (
									<Select.Option key={category.id} value={category.id}>
										{category.categoryName}
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						<div style={{ margin: '20px 0' }}>
							<Divider />
						</div>
						<h4>Diet Schedule</h4>
						{Object.keys(dietPlans).map((week) => (
							<React.Fragment key={week}>
								<Row
									justify="space-between"
									align="middle"
									gutter={8}
									style={{
										border: '1px solid #f0f0f0',
										padding: '8px',
										marginBottom: '10px',
										backgroundColor: '#f0f0f0',
									}}>
									<Col>
										<h4
											style={{
												margin: 0,
											}}>
											{week}
										</h4>
									</Col>
									<Col>
										<Button danger icon={<DeleteOutlined />} onClick={() => removeWeek(week)} />
									</Col>
								</Row>
								{Object.keys(dietPlans[week]).map((day) => (
									<Row key={day} style={{ margin: '10px 0' }} gutter={16}>
										<Col span={5}>
											<h5>{day}</h5>
										</Col>
										<Col span={19}>
											{dietPlans[week][day].map((item) => (
												<Row key={item.id} style={{ marginBottom: '8px' }} gutter={8}>
													<Col span={5}>
														<TimePicker
															style={{ width: '100%' }}
															use12Hours
															format="h A"
															placeholder="Select Time"
															onChange={(value) => updateDietItem(week, day, item.id, 'time', value)}
															value={item.time ? moment(item.time) : null}
														/>
													</Col>
													<Col span={12}>
														<FoodModal
															data={[
																{ id: 1, foodName: 'Egg' },
																{ id: 2, foodName: 'Papaya' },
																{ id: 3, foodName: 'Milk' },
																{ id: 4, foodName: 'Chicken' },
															]}
															value={item.food}
															onChange={(value) => updateDietItem(week, day, item.id, 'food', value)}
														/>
													</Col>
													<Col span={5}>
														<Input
															placeholder="Quantity"
															value={item.qty}
															onChange={(e) => updateDietItem(week, day, item.id, 'qty', e.target.value)}
														/>
													</Col>
													<Col span={2}>
														<Button danger icon={<DeleteOutlined />} onClick={() => removeDietItem(week, day, item.id)} />
													</Col>
												</Row>
											))}
											<Button style={{ width: "100%" }} type="dashed" onClick={() => addDietItem(week, day)} icon={<PlusOutlined />}>
												Add Item
											</Button>
										</Col>
									</Row>
								))}
							</React.Fragment>
						))}
						<Button type="dashed" style={{ marginTop: 16 }} onClick={addWeek} icon={<PlusOutlined />}>
							Add Week
						</Button>

						<Row justify="end" gutter={16}>
							<Col>
								<Button type="default" onClick={onClose}>
									Cancel
								</Button>
							</Col>
							<Col>
								<Button type="primary" htmlType="submit">
									{editDietPlan ? 'Update' : 'Save'}
								</Button>
							</Col>
						</Row>
					</Form>
				</Col>
			</Row>
		</Drawer>
	);
};

export default AddDietPlan;
