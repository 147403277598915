import React, { useEffect } from 'react';
import { Col, Row, Upload } from 'antd';
import {
	FacebookOutlined,
	InstagramOutlined,
	MailOutlined,
	MobileOutlined,
	YoutubeOutlined,
} from '@ant-design/icons';
import { SERVER_IP } from 'assets/Config';
import FileUpload from 'components/file-upload/file-upload';
import { useDispatch, useSelector } from 'react-redux';
import { putApi } from 'redux/sagas/putApiSaga';

// const beforeUpload = (file) => {
// 	const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
// 	if (!isJpgOrPng) {
// 		message.error('You can only upload JPG/PNG file!');
// 	}
// 	const isLt2M = file.size / 1024 / 1024 < 2;
// 	if (!isLt2M) {
// 		message.error('Image must smaller than 2MB!');
// 	}
// 	return isJpgOrPng && isLt2M;
// };

const ProfilePresentational = ({ userDetails, fileList, onChange, onPreview }) => {
	// const [loading, setLoading] = React.useState(false);
	// const [, setBannerLoading] = React.useState(false);
	// const [imageUrl, setImageUrl] = React.useState();
	// const [bannerImageUrl, setBannerImageUrl] = React.useState();
	const IMAGE_ACTION_URL = SERVER_IP + 'api/media/upload';
	const loginRedux = useSelector((state) => state.loginRedux);
	const [ profilePicList, setProfilePicList ] = React.useState([]); 
	const [ bannerList, setBannerList ] = React.useState([]); 
	const dispatch = useDispatch();

	// const handleChange = (info, type = 'profile') => {
	// 	if (info.file.status === 'uploading') {
	// 		type === 'profile' ? setLoading(true) : setBannerLoading(true);
	// 		return;
	// 	}
	// 	if (info.file.status === 'done') {
	// 		// Get this url from response in real world.
	// 		getBase64(info.file.originFileObj, (url) => {
	// 			if (type === 'profile') {
	// 				setLoading(false);
	// 				setImageUrl(url);
	// 				// dispatch(postApi(data, 'PROFILE_IMAGE_UPDATE'));
	// 			} else {
	// 				setBannerLoading(false);
	// 				setBannerImageUrl(url);
	// 			}
	// 		});
	// 	}
	// };
	// const uploadButton = (
	// 	<div>
	// 		{loading ? <LoadingOutlined /> : <UploadOutlined />}
	// 		<div style={{ marginTop: 8 }}>Upload</div>
	// 	</div>
	// );

	useEffect(() => {
		const updateProfilePic = async() => {
			if(loginRedux.profilePic !== profilePicList[0].url)
			{
				const data = {
					profilePic: profilePicList[0].thumbUrl
				};
				let url = `${SERVER_IP}api/users/${loginRedux.id}`;
				dispatch(putApi(data, 'EDIT_CUSTOMER', url));
			}
		}
		profilePicList.length > 0 && updateProfilePic();
	}, [profilePicList, dispatch, loginRedux]);


	useEffect(() => {
		const updatebannerPic = async() => {
			const data = {
				bannerImage: bannerList[0].thumbUrl
			};
			let url = `${SERVER_IP}api/users/${loginRedux.id}`;
			dispatch(putApi(data, 'EDIT_CUSTOMER', url));
		}
		bannerList.length > 0 && updatebannerPic();
	}, [bannerList, dispatch, loginRedux]);


	useEffect(() => {
		if (userDetails?.profilePic) {
			setProfilePicList([{
				uid: userDetails?.profilePic,
				name: userDetails?.firstName + " " + userDetails?.lastName,
				url: SERVER_IP + userDetails?.profilePic,
				thumbUrl: userDetails?.thumbUrl
			}]);
		}
		if (userDetails?.bannerImage) {
			setBannerList([{
				uid: userDetails?.bannerImage,
				name: userDetails?.firstName + " " + userDetails?.lastName,
				url: SERVER_IP + userDetails?.bannerImage,
				thumbUrl: userDetails?.thumbUrl
			}]);
		}
	}, [userDetails]);

	return (
		<Row className="profileContainer">
			<Col span={24}>
				<Row>
					<Col span={24} className="banner">
						<div className="banner-upload">
							<FileUpload className="avatar-uploader" listType="picture-card" setFileList={setBannerList} action={ '/' } fileList={bannerList} max={1} afterUpload={() => {}} />
							{/* <ImgCrop showGrid rotationSlider aspectSlider showReset>
								<Upload
									listType="picture-card"
									className="avatar-uploader"
									name="picture-card"
									showUploadList={false}
									action={IMAGE_ACTION_URL}
									onChange={(e) => handleChange(e, 'banner')}>
									{bannerImageUrl ? <img src={bannerImageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
								</Upload>
							</ImgCrop> */}
						</div>
						<Col className="profileIcon">
							<FileUpload className="avatar-uploader" listType="picture-circle" 
								afterUpload={() => {}}
								action={'/'} 
								setFileList={setProfilePicList}
								fileList={profilePicList}
								max={1}
							/>
						</Col>
					</Col>
					<Col span={24} className="content">
						<Col className="details">
							<Col className="gymName">{userDetails?.gymName}</Col>
							<Col className="ownerName">
								{userDetails?.firstName} {userDetails?.lastName}
							</Col>
							<Col className="description">{userDetails?.description}</Col>
							<Col className="contact">
								<Col className="email">
									<MailOutlined /> {userDetails?.email}
								</Col>
								<Col className="mobile">
									<MobileOutlined /> {userDetails?.mobile}
								</Col>
							</Col>
							<Col className="socialLinks">
								<Row gutter={[10, 10]}>
									<Col className="link">
										<Row align="middle">
											<Col style={{ paddingRight: 5 }}>
												<FacebookOutlined />
											</Col>
											<Col>{userDetails?.socialLinks?.facebook}</Col>
										</Row>
									</Col>
									<Col className="link">
										<Row align="middle">
											<Col style={{ paddingRight: 5 }}>
												<InstagramOutlined />
											</Col>
											<Col>{userDetails?.socialLinks?.instagram}</Col>
										</Row>
									</Col>
									<Col className="link">
										<Row align="middle">
											<Col style={{ paddingRight: 5 }}>
												<YoutubeOutlined />
											</Col>
											<Col>{userDetails?.socialLinks?.youtube}</Col>
										</Row>
									</Col>
								</Row>
							</Col>
						</Col>
						<Col style={{ padding: 20 }}>
							{/* <ImgCrop showGrid rotationSlider aspectSlider showReset> */}
							<Upload
								action={IMAGE_ACTION_URL}
								listType="picture-card"
								fileList={fileList}
								onChange={onChange}
								onPreview={onPreview}>
								{fileList.length < 3 && '+ Upload'}
							</Upload>
							{/* </ImgCrop> */}
						</Col>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default ProfilePresentational;
