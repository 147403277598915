import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Row, Col, Button, Form, Drawer, Input, Radio, Select, InputNumber } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { API_STATUS, DATE_FORMAT, PAYMENT_METHOD } from 'constants/app-constants';
import { SERVER_IP } from 'assets/Config';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { formQueryStringFromObject } from 'helpers';
import DatePicker from 'components/date-picker';

const PayNowModal = ({ payNowAddModal, width = '40%', editCustomer, setPayNowAddModal, refreshList, handleClose }) => {
	const [form] = Form.useForm();
	const globalRedux = useSelector((state) => state.globalRedux);
	const memberships = useSelector((state) => state.membershipRedux?.memberships || []);
	const services = useSelector((state) => state?.serviceRedux?.services || []);
	const coaches = useSelector((state) => state.coachRedux?.coaches);
	const paymentMethodValue = Form.useWatch('paymentMethod', form);
	const serviceIdValue = Form.useWatch('serviceId', form);
	const membershipIdValue = Form.useWatch('membershipId', form);
	const paidOnValue = Form.useWatch('paidOn', form);
	const amountValue = Form.useWatch('amount', form);
	const [originalAmount, setOriginalAmount] = useState(0);

	const branchId = globalRedux?.selectedBranch?.id;
	const dispatch = useDispatch();

	const string = useMemo(() => {
		const string = formQueryStringFromObject({
			branchId,
		});
		return string;
	}, [branchId]);

	useEffect(() => {
		let balance = originalAmount - amountValue;
		form.setFieldsValue({
			balance,
		});
	}, [amountValue, form, originalAmount]);

	const getMemberships = useCallback(
		(serviceId) => {
			const query = formQueryStringFromObject({
				serviceId,
			});
			let membershipUrl = `${SERVER_IP}api/memberships?${string}&${query}`;
			dispatch(getApi('GET_MEMBERSHIPS', membershipUrl));
		},
		[dispatch, string]
	);

	const getServices = useCallback(() => {
		let serviceUrl = `${SERVER_IP}api/services?${string}`;
		dispatch(getApi('GET_SERVICES', serviceUrl));
	}, [dispatch, string]);

	useEffect(() => {
		if (payNowAddModal) {
			getServices();
		} else form?.resetFields();
	}, [getServices, payNowAddModal, form]);

	useEffect(() => {
		if (serviceIdValue) {
			form.setFieldsValue({
				membershipId: '',
				amount: 0,
				balance: 0,
			});
			getMemberships(serviceIdValue);
		}
	}, [serviceIdValue, getMemberships, form]);

	useEffect(() => {
		if (membershipIdValue) {
			const amountFormValue = form.getFieldValue('amount');
			const membership = memberships?.find((membership) => membership?.id === membershipIdValue);
			const amount = amountFormValue ? amountFormValue : membership?.offerPrice || membership?.originalPrice;
			setOriginalAmount(amount);
			form.setFieldsValue({
				amount,
			});
		}
	}, [membershipIdValue, form, memberships]);

	useEffect(() => {
		if (membershipIdValue) {
			const membership = memberships?.find((membership) => membership?.id === membershipIdValue);
			form.setFieldsValue({
				...(paidOnValue && { nextBillDate: moment(paidOnValue).add(membership?.days, 'days') }),
			});
		}
	}, [membershipIdValue, form, memberships, paidOnValue]);

	useEffect(() => {
		if (editCustomer) {
			form.setFieldsValue({
				// type: editCustomer?.type || 'Contact',
				customerName: editCustomer?.customerName || '',
				age: editCustomer?.age || '',
				email: editCustomer?.email || '',
				mobile: editCustomer?.mobile || '',
				address: editCustomer?.address || '',
				gender: editCustomer?.gender || '',
				joiningDate: moment(editCustomer?.joiningDate) || '',
				referredBy: editCustomer?.referredBy || '',
				onBoardedBy: editCustomer?.onBoardedBy || '',
				paidOn: moment(),
			});
		} else {
			form?.resetFields();
		}
	}, [editCustomer, form]);

	const handleSubmit = (values) => {
		let data = {
			customerId: editCustomer.id,
			membershipId: values?.membershipId || '',
			serviceId: values?.serviceId || '',
			amount: values?.amount || '',
			balance: values?.balance || 0,
			nextBillDate: values?.nextBillDate || '',
			paidOn: values?.paidOn || '',
			paymentMethod: values?.paymentMethod || '',
			remarks: values?.remarks || '',
			receivedBy: values?.receivedBy || '',
			paymentFor: 'membership',
			...(paymentMethodValue === PAYMENT_METHOD[1]?.value && { upiTransactionId: values?.upiTransactionId || '' }),
			branchId,
		};
		console.log(data, 'ADD_PAYMENT');
		dispatch(postApi(data, 'ADD_PAYMENT'));
	};

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_PAYMENT === 'SUCCESS' || globalRedux.apiStatus.EDIT_CUSTOMER === 'SUCCESS') {
			dispatch(resetApiStatus('ADD_PAYMENT'));
			// dispatch(resetApiStatus(editCustomer ? 'EDIT_CUSTOMER' : 'ADD_PAYMENT'));
			refreshList?.(true);
			handleClose?.();
			form?.resetFields();
		}
	}, [globalRedux.apiStatus, editCustomer, setPayNowAddModal, dispatch, refreshList, handleClose, form]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const loading = globalRedux.apiStatus.ADD_PAYMENT === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_CUSTOMER === API_STATUS.PENDING;
	const membershipLoading = useMemo(() => globalRedux?.apiStatus.GET_MEMBERSHIPS === API_STATUS.PENDING, [globalRedux?.apiStatus?.GET_MEMBERSHIPS]);
	const serviceLoading = useMemo(() => globalRedux?.apiStatus.GET_SERVICES === API_STATUS.PENDING, [globalRedux?.apiStatus?.GET_SERVICES]);

	return (
		<Drawer
			placement="right"
			title={`Pay Bill`}
			width={width || '40%'}
			open={payNowAddModal}
			closable
			onClose={() => setPayNowAddModal(false)}
			destroyOnClose>
			<Row>
				<Col span={24}>
					<Row style={{ marginTop: 20 }}>
						<Form
							name="pay-now"
							className="required_in_right"
							style={{ width: '100%' }}
							colon={true}
							labelAlign="left"
							form={form}
							onFinish={handleSubmit}
							{...layer1FormCol}>
							<Form.Item
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}
								label="Service type"
								name="serviceId">
								<Select loading={serviceLoading} placeholder="Service type">
									{services?.map((service, index) => (
										<Select.Option key={index} value={service.id}>
											{service?.serviceName}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}
								label="Membership"
								name="membershipId">
								<Select disabled={!Boolean(serviceIdValue)} loading={membershipLoading} placeholder="Membership">
									{memberships?.map((membership, index) => (
										<Select.Option key={index} value={membership.id}>
											{membership?.membershipName} - {membership?.offerPrice || membership?.originalPrice}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								label="Amount"
								name="amount"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<InputNumber placeholder="Amount" style={{ width: '100%' }} />
							</Form.Item>
							<Form.Item
								label="Balance"
								name="balance"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<InputNumber disabled placeholder="Balance" style={{ width: '100%' }} />
							</Form.Item>
							<Form.Item
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}
								label="Paid on"
								name="paidOn">
								<DatePicker disabled={!Boolean(serviceIdValue)} style={{ width: '100%' }} format={DATE_FORMAT.DD_MM_YYYY} />
							</Form.Item>
							<Form.Item
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}
								label="Next Bill Date"
								name="nextBillDate">
								<DatePicker disabled={!Boolean(serviceIdValue)} style={{ width: '100%' }} format={DATE_FORMAT.DD_MM_YYYY} />
							</Form.Item>
							<Form.Item
								label="Payment Method"
								name="paymentMethod"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<Radio.Group style={{ width: '100%' }} options={PAYMENT_METHOD} optionType="button" buttonStyle="solid" />
							</Form.Item>
							{paymentMethodValue === PAYMENT_METHOD[1]?.value && (
								<Form.Item
									label="UPI TransactionId"
									name="upiTransactionId"
									rules={[
										{
											required: true,
											message: 'This Field is required!',
										},
									]}>
									<InputNumber placeholder="UPI TransactionId" style={{ width: '100%' }} />
								</Form.Item>
							)}
							<Form.Item
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}
								label="Received By"
								name="receivedBy">
								<Select placeholder="Received By">
									{coaches?.map((coach) => (
										<Select.Option value={coach.id}>{coach?.coachName}</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								label="Remarks"
								name="remarks"
								rules={[
									{
										required: false,
										message: 'This Field is required!',
									},
								]}>
								<Input.TextArea />
							</Form.Item>
							<Form.Item
								wrapperCol={{
									offset: 0,
									span: 24,
								}}>
								<Row className="space-between" style={{ paddingTop: 20, width: '100%', margin: 0 }}>
									<Button danger style={{ width: '49%' }} onClick={() => setPayNowAddModal(false)}>
										Cancel
									</Button>
									<Button loading={loading} style={{ width: '49%' }} type="primary" htmlType="submit">
										Pay
									</Button>
								</Row>
							</Form.Item>
						</Form>
					</Row>
				</Col>
			</Row>
		</Drawer>
	);
};

export default PayNowModal;
