import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { SERVER_IP } from 'assets/Config';
import HighlightComponent from 'components/HighlightComponent';
import { ACTIONS, API_STATUS, DATE_FORMAT } from 'constants/app-constants';
import InvoiceListPresentational from './invoice-list-presentational';
import InvoiceToPrint from './invoice-to-print';

const initialPageSize = 10;
const initialPageSizeOptions = [10, 15, 20];

const DATE_SOURCE = [
	{
		key: '1',
		date: '10-11-2022',
		invoice: 'INV-001',
		orderNumber: 'ORD-001',
		customerName: 'John Doe',
		status: 'Overdue by 1 day',
		dueDate: '10-11-2022',
		amount: '100.00',
		balanceDue: '10.00',
	},
	{
		key: '2',
		date: '10-11-2022',
		invoice: 'INV-002',
		orderNumber: 'ORD-001',
		customerName: 'Sathish Saminathan',
		status: 'Paid',
		dueDate: '10-11-2022',
		amount: '100.00',
		balanceDue: '10.00',
	},
];

const InvoiceListFunctional = React.memo(() => {
	const receipts = useSelector((state) => state?.receiptsRedux?.receipts);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [searchKey, setSearchKey] = useState('');
	const [tableData, setTableData] = useState(receipts);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [selectedInvoice, setSelectedInvoice] = useState(null);
	const [selectedRecordToPrint, setSelectedRecordToPrint] = useState(null);
	const componentRef = React.useRef();
	const dispatch = useDispatch();

	const getReceipts = () => {
		dispatch(getApi(ACTIONS.GET_RECEIPTS, `${SERVER_IP}receipt?orgId=${globalRedux?.selectedBranch?.id}`));
	};

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((record) => {
			return (
				(record?.ticketType || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.priority || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.customerName || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.mobile || '')?.toLowerCase().includes(searchKey.toLowerCase())
			);
		});
	}, [tableData, searchKey]);

	const column = [
		{
			title: 'Date',
			dataIndex: 'date',
			key: 'date',
			width: '10%',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.toString()}
				/>
			),
		},
		{
			title: 'Invoice#',
			dataIndex: 'invoice',
			key: 'invoice',
			width: '10%',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={moment(value).format(DATE_FORMAT.DD_MM_YYYY)}
				/>
			),
		},
		{
			title: 'Order Number',
			dataIndex: 'orderNumber',
			key: 'orderNumber',
			width: '10%',
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value || ''} />
			),
		},
		{
			title: 'Customer Name',
			dataIndex: 'customerName',
			key: 'customerName',
			width: '10%',
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value || ''} />
			),
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			width: '10%',
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value || ''} />
			),
		},
		{
			title: 'Due Date',
			dataIndex: 'dueDate',
			key: 'dueDate',
			width: '10%',
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value || ''} />
			),
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
			width: '10%',
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value || ''} />
			),
		},
		{
			title: 'Balance Due',
			dataIndex: 'balanceDue',
			key: 'balanceDue',
			width: '10%',
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value || ''} />
			),
		},
		// {
		// 	title: 'Action',
		// 	align: 'center',
		// 	dataIndex: 'displayName',
		// 	width: '10%',
		// 	render: (value, row) => {
		// 		return (
		// 			<Row gutter={10} justify="center">
		// 				<Col onClick={() => handleSelectRow(row)} className="edit_icon">
		// 					<EditOutlined />
		// 				</Col>
		// 				<Col className="delete_icon">
		// 					<Col style={{ padding: 0 }}>
		// 						<PrinterOutlined />
		// 					</Col>
		// 				</Col>
		// 			</Row>
		// 		);
		// 	},
		// },
	];

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage === 0 ? 1 : currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const onSelectChange = (selectedRowKeys) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	};

	useEffect(() => {
		setTableData(DATE_SOURCE || receipts);
	}, [receipts]);

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_TICKETS === API_STATUS.PENDING, [globalRedux.apiStatus]);

	const handleAfterPrint = () => {
		setSelectedRecordToPrint(null);
	};

	const reactToPrintContent = React.useCallback(() => {
		return componentRef.current;
	}, []);

	const handlePrint = useReactToPrint({
		content: reactToPrintContent,
		documentTitle: 'Voucher',
		removeAfterPrint: true,
		onAfterPrint: handleAfterPrint,
		// onBeforeGetContent: handleOnBeforeGetContent,
	});

	useEffect(() => {
		selectedRecordToPrint && handlePrint();
	}, [selectedRecordToPrint, handlePrint]);

	return (
		<>
			<div style={{ display: 'none' }}>
				<InvoiceToPrint ref={componentRef} data={selectedRecordToPrint} />
			</div>
			<InvoiceListPresentational
				{...{
					column,
					filteredData,
					handleTableChange,
					getStartingValue,
					getEndingValue,
					pageSize,
					initialPageSizeOptions,
					initialPageSize,
					currentPage,
					setSearchKey,
					refreshList: getReceipts,
					tableLoading,
					rowSelection,
					selectedInvoice,
					setSelectedInvoice,
					setSelectedRecordToPrint,
				}}
			/>
		</>
	);
});

export default InvoiceListFunctional;
