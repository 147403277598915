import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { Row, Col, Button, Form, Drawer, Select, Divider, Input } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { useHotkeys } from 'react-hotkeys-hook';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { SERVER_IP } from 'assets/Config';
import { formQueryStringFromObject } from 'helpers';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { putApi } from 'redux/sagas/putApiSaga';
import TimePicker from 'components/time-picker';
import FoodModal from 'components/food-modal';

const layer1FormCol = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 },
};

const defaultDietItem = () => ({
    id: uuidv4(),
    time: null,
    food: '',
});

const createDefaultDietPlan = () => {
    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const weeks = ['Week1', 'Week2', 'Week3', 'Week4'];
    return weeks.reduce((acc, week) => {
        acc[week] = days.reduce((dayAcc, day) => {
            dayAcc[day] = Array(1).fill(null).map(() => defaultDietItem());
            return dayAcc;
        }, {});
        return acc;
    }, {});
};

const AddDietPlanTemplate = ({ dietPlanTemplateAddModal, width = '50%', editDietPlanTemplate, setDietPlanTemplateAddModal, refreshList, handleClose }) => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const globalRedux = useSelector((state) => state.globalRedux);
	const branchId = globalRedux?.selectedBranch?.id;
	const buttonRef = useRef();

	const [dietPlanTemplateTemplates, setDietPlanTemplateTemplates] = useState(createDefaultDietPlan());

	const [, setHistory] = useState([dietPlanTemplateTemplates]);

	useHotkeys('ctrl + n', () => buttonRef?.current?.click());
	useHotkeys('ctrl + z', () => undoLastChange());

	const undoLastChange = () => {
		setHistory((prevHistory) => {
			if (prevHistory.length > 1) {
				const newHistory = prevHistory.slice(0, -1);
				setDietPlanTemplateTemplates(newHistory[newHistory.length - 1]);
				return newHistory;
			}
			return prevHistory;
		});
	};

	const apiString = useMemo(() => formQueryStringFromObject({ branchId }), [branchId]);

	const fetchCustomers = useCallback(() => {
		if (branchId) {
			dispatch(getApi('GET_CUSTOMERS', `${SERVER_IP}api/customers?${apiString}`));
		}
	}, [dispatch, branchId, apiString]);

	const fetchCoaches = useCallback(() => {
		dispatch(getApi('GET_COACHES', `${SERVER_IP}api/coaches?${apiString}`));
	}, [dispatch, apiString]);

	useEffect(() => {
		fetchCoaches();
		fetchCustomers();
	}, [fetchCoaches, fetchCustomers]);

	const addDietItem = (week, day) => {
		setDietPlanTemplateTemplates((prevPlans) => {
			const newDietItem = { id: uuidv4(), time: null, food: '', qty: '' };
			const newDiets = {
				...prevPlans,
				[week]: {
					...prevPlans[week],
					[day]: [...prevPlans[week][day], newDietItem],
				},
			};
			setHistory((prevHistory) => [...prevHistory, newDiets]);
			return newDiets;
		});
	};

	const updateDietItem = (week, day, id, field, value) => {
		setDietPlanTemplateTemplates((prevPlans) => {
			const newDiets = prevPlans[week][day].map((item) => (item.id === id ? { ...item, [field]: value } : item));
			const updatedPlans = { ...prevPlans, [week]: { ...prevPlans[week], [day]: newDiets } };
			setHistory((prevHistory) => [...prevHistory, updatedPlans]);
			return updatedPlans;
		});
	};

	const removeDietItem = (week, day, id) => {
		setDietPlanTemplateTemplates((prevPlans) => {
			const newDiets = prevPlans[week][day].filter((item) => item.id !== id);
			const updatedPlans = { ...prevPlans, [week]: { ...prevPlans[week], [day]: newDiets } };
			setHistory((prevHistory) => [...prevHistory, updatedPlans]);
			return updatedPlans;
		});
	};

	const handleSubmit = (values) => {
		const data = { ...values, branchId, dietPlanTemplateTemplates, paidFor: 'personal_training' };
		const apiMethod = editDietPlanTemplate ? putApi : postApi;
		const apiEndpoint = editDietPlanTemplate ? `api/dietPlanTemplate/${editDietPlanTemplate.id}` : 'ADD_DIET_PLAN_TEMPLATE';

		dispatch(apiMethod(data, editDietPlanTemplate ? 'EDIT_DIET_PLAN_TEMPLATE' : 'ADD_DIET_PLAN_TEMPLATE', `${SERVER_IP}${apiEndpoint}`));
	};

	const onClose = () => {
		handleClose();
		setDietPlanTemplateAddModal(false);
		form.resetFields();
		setDietPlanTemplateTemplates(createDefaultDietPlan());
		setHistory([createDefaultDietPlan()]);
	};

	return (
		<Drawer
			placement="right"
			title={`${editDietPlanTemplate ? 'Edit' : 'New'} Diet Plan Template`}
			width={width}
			open={dietPlanTemplateAddModal}
			closable
			onClose={onClose}
			destroyOnClose>
			<Row>
				<Col span={24}>
					<Form
						name="add-dietPlanTemplate"
						className="required_in_right"
						style={{ width: '100%' }}
						colon
						labelAlign="left"
						form={form}
						onFinish={handleSubmit}
						initialValues={{}}
						{...layer1FormCol}>

						<Form.Item label="Category" name="category" rules={[{ required: true, message: 'This Field is required!' }]}>
							<Select placeholder="Select Category">
								{[
									{ id: 1, categoryName: 'Fat Loss' },
									{ id: 2, categoryName: 'Weight Gain' },
								].map((category) => (
									<Select.Option key={category.id} value={category.id}>
										{category.categoryName}
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						<div style={{ margin: '20px 0' }}>
							<Divider />
						</div>
						<h4>Diet Schedule</h4>
						{Object.keys(dietPlanTemplateTemplates).map((week) => (
							<React.Fragment key={week}>
								<h4>{week}</h4>
								{Object.keys(dietPlanTemplateTemplates[week]).map((day) => (
									<div style={{ paddingBottom: 10 }} key={day}>
										<h5>{day}</h5>
										{dietPlanTemplateTemplates[week][day].map((diet) => (
											<Row key={diet.id} gutter={16} align="middle" style={{ marginBottom: 8 }}>
												<Col span={5}>
													<TimePicker
														style={{ width: '100%' }}
														use12Hours
														format="h A"
														placeholder="Select Time"
														onChange={(time) => updateDietItem(week, day, diet.id, 'time', time)}
														value={diet.time ? moment(diet.time) : null}
													/>
												</Col>
												<Col span={12}>
													<FoodModal
														data={[
															{ id: 1, foodName: 'Egg' },
															{ id: 2, foodName: 'Papaya' },
															{ id: 3, foodName: 'Milk' },
															{ id: 4, foodName: 'Chicken' },
														]}
														value={diet.food}
														onChange={(value) => updateDietItem(week, day, diet.id, 'food', value)}
													/>
												</Col>
												<Col span={5}>
													<Input
														placeholder="Quantity"
														value={diet.qty}
														onChange={(e) => updateDietItem(week, day, diet.id, 'qty', e.target.value)}
													/>
												</Col>
												<Col span={2}>
													<Button danger icon={<DeleteOutlined />} onClick={() => removeDietItem(week, day, diet.id)} />
												</Col>
											</Row>
										))}
										<Button style={{
											width: '100%'
										}} type="dashed" onClick={() => addDietItem(week, day)} icon={<PlusOutlined />}>
											Add Diet Item
										</Button>
									</div>
								))}
								<Divider />
							</React.Fragment>
						))}

						<Row justify="end" gutter={16}>
							<Col>
								<Button type="default" onClick={onClose}>Cancel</Button>
							</Col>
							<Col>
								<Button type="primary" htmlType="submit">{editDietPlanTemplate ? 'Update' : 'Save'}</Button>
							</Col>
						</Row>
					</Form>
				</Col>
			</Row>
		</Drawer>
	);
};

export default AddDietPlanTemplate;
