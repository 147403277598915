import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { Modal, Row, Col, Divider, Select, Input, Form, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import TableComponent from 'components/table-component';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { API_STATUS } from 'constants/app-constants';
import { SERVER_IP } from 'assets/Config';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { AiOutlineSearch } from 'react-icons/ai';
import { putApi } from 'redux/sagas/putApiSaga';
import { deleteApi } from 'redux/sagas/deleteApiSaga';
import './food-model.scss';

const layer1FormCol = {
	labelCol: {
		span: 24,
	},
	wrapperCol: {
		span: 24,
	},
};

const FoodModal = ({ value = null, onChange, data = [] }) => {
	const [foodModal, setFoodModal] = useState(false);
	const [searchKey, setSearchKey] = useState('');
	const [addFormVisible, setAddFormVisible] = useState(false);
	const [editData, setEditData] = useState(null);
	const [tableData, setTableData] = useState([]);
	const selectRef = useRef(null);
	const globalRedux = useSelector((state) => state.globalRedux);
	const foods = useSelector((state) => state?.foodRedux?.foods) || data;
	// const foods = useSelector((state) => state?.foodRedux?.foods);
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const column = [
		{
			title: 'FOOD NAME',
			dataIndex: 'foodName',
			key: 'foodName',
			width: '20%',
			align: 'left',
		},
		{
			title: 'Delete',
			dataIndex: 'foodName',
			key: 'foodName',
			width: '10%',
			align: 'center',
			render: (value, record) => (
				<Row className="show-on-hover" justify="center">
					<Col span={6} onClick={() => handleEdit(record)}>
						<EditOutlined />
					</Col>
					<Col span={6} className="delete" onClick={() => handleRemove(record?.id)}>
						<DeleteOutlined />
					</Col>
				</Row>
			),
		},
	];

	const handleInputKeyDown = () => {
		if (selectRef.current) {
			selectRef.current.blur();
			setFoodModal(true);
		}
	};

	const handleRemove = (id) => {
		let url = `${SERVER_IP}api/food/${id}?branchId=${globalRedux?.selectedBranch?.id}`;
		dispatch(deleteApi('DELETE_FOOD', url));
	};

	const handleSubmit = (values) => {
		let data = {
			...values,
			branchId: globalRedux?.selectedBranch?.id,
		};
		if (editData) {
			let url = `${SERVER_IP}api/food/${editData.id}?branchId=${globalRedux?.selectedBranch?.id}`;
			dispatch(putApi(data, 'EDIT_FOOD', url));
		} else {
			dispatch(postApi(data, 'ADD_FOOD'));
		}
	};

	const handleEdit = (values) => {
		let data = {
			...values,
			branchId: globalRedux?.selectedBranch?.id,
			foodName: values?.foodName || '',
		};
		setEditData(data);
	};

	const getFoods = useCallback(() => {
		let url = `${SERVER_IP}api/food/?branchId=${globalRedux?.selectedBranch?.id}`;
		dispatch(getApi('GET_FOODS', url));
	}, [dispatch, globalRedux?.selectedBranch?.id]);

	// useEffect(() => {
	// 	getFoods();
	// }, [getFoods]);

	useEffect(() => {
		if ((foods || [])?.length > 0) {
			setTableData(foods);
		}
	}, [foods]);

	useEffect(() => {
		if (editData) {
			form.setFieldsValue({
				foodName: editData?.foodName || '',
			});
			setAddFormVisible(true);
		}
	}, [editData, form]);

	useEffect(() => {
		if (!addFormVisible) {
			form.resetFields();
			setEditData(null);
		}
	}, [addFormVisible, form]);

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_FOOD === 'SUCCESS' || globalRedux.apiStatus.EDIT_FOOD === 'SUCCESS') {
			dispatch(resetApiStatus(editData ? 'EDIT_FOOD' : 'ADD_FOOD'));
			setAddFormVisible(false);
			getFoods();
		}
	}, [globalRedux.apiStatus, editData, setFoodModal, dispatch, getFoods]);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_FOOD === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_FOOD'));
			doIt = true;
		}
		if (doIt) {
			getFoods();
		}
	}, [globalRedux.apiStatus, dispatch, getFoods]);

	const loading = globalRedux.apiStatus.GET_FOODS === API_STATUS.PENDING;
	const buttonLoading = globalRedux.apiStatus.ADD_FOOD === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_FOOD === API_STATUS.PENDING;

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((food) => {
			return (
				(food?.foodName || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(food?.outstandingBalance || '')?.toString().toLowerCase().includes(searchKey.toLowerCase())
			);
		});
	}, [tableData, searchKey]);

	return (
		<>
			<Select
				onChange={onChange}
				ref={selectRef}
				placeholder="Select Food"
				style={{ width: '100%' }}
				showSearch
				optionFilterProp="children"
				filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
				{...{
					...(value && { value }),
				}}
				dropdownRender={(menu) => (
					<div>
						<div
							style={{ display: 'flex', flexWrap: 'nowrap', cursor: 'pointer', background: '#6ebaff' }}
							onClick={() => {
								handleInputKeyDown();
							}}>
							<a href style={{ flex: 'none', color: '#fff', padding: '8px', display: 'block' }}>
								Manage Food
							</a>
						</div>
						<Divider />
						{menu}
					</div>
				)}>
				{(foods || []).map((food) => (
					<Select.Option key={food?.id} value={food?.id}>
						{food?.foodName}
					</Select.Option>
				))}
			</Select>

			<Modal
				title={
					<Row>
						<Col span={24}>
							<Row>
								<Col span={24}>Foods</Col>
							</Row>
						</Col>
					</Row>
				}
				footer={null}
				onCancel={() => setFoodModal(false)}
				cancelButtonProps={{ onPress: () => setFoodModal(false) }}
				okText="Save"
				open={foodModal}
				style={{ top: 0 }}
				width={'40%'}>
				<Row className="add-food">
					<Col span={24}>
						{addFormVisible && (
							<Row className="add-food-form">
								<Col span={24}>
									<Form
										name="add-staff"
										style={{}}
										requiredMark={false}
										colon={false}
										labelAlign="left"
										form={form}
										onFinish={handleSubmit}
										{...layer1FormCol}>
										<Row gutter={[10, 0]}>
											<Col span={15}>
												<Form.Item
													label="Food Name"
													name="foodName"
													initialValue={editData?.foodName}
													rules={[
														{
															required: true,
															message: 'This Field is Required!',
														},
													]}>
													<Input autoFocus placeholder="Food name" />
												</Form.Item>
											</Col>
										</Row>
										<Row gutter={[10, 0]}>
											<Col span={12}>
												<Form.Item
													style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}
													wrapperCol={{
														span: 24,
													}}>
													<Row>
														<Button loading={buttonLoading} type="primary" style={{ marginRight: 5 }} htmlType="submit">
															{editData ? 'Update' : 'Save'}
														</Button>
														<Button onClick={() => setAddFormVisible(false)} style={{}} danger>
															Cancel
														</Button>
													</Row>
												</Form.Item>
											</Col>
										</Row>
									</Form>
								</Col>
							</Row>
						)}
						<TableComponent
							className="add-table"
							style={{ width: '100%' }}
							rowKey={(record) => record.id}
							dataSource={filteredData}
							columns={column}
							pagination={false}
							loading={loading}
							title={() =>
								addFormVisible ? null : (
									<Row gutter={[10, 0]} style={{ justifyContent: 'space-between' }}>
										<Col span={15}>
											<Input
												placeholder="Search Food"
												suffix={<AiOutlineSearch />}
												onChange={({ target: { value } }) => setSearchKey(value)}
											/>
										</Col>
										<Col span={9} style={{ textAlign: 'right' }}>
											<Button type="primary" onClick={() => setAddFormVisible(true)}>
												Create New
											</Button>
										</Col>
									</Row>
								)
							}
						/>
					</Col>
				</Row>
			</Modal>
		</>
	);
};

export default FoodModal;
