import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { EditOutlined, PrinterOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { getDateFormat } from 'services/Utils';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { SERVER_IP } from 'assets/Config';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import HighlightComponent from 'components/HighlightComponent';
import { ACTIONS, API_STATUS, DATE_FORMAT } from 'constants/app-constants';
import { generatePagination } from 'helpers';
import VoucherListPresentational from './voucher-list-presenatational';
import VoucherToPrint from './voucher-to-print';

const initialPageSize = 10;
const initialPageSizeOptions = [10, 15, 20];

const VoucherListFunctional = React.memo(() => {
	const componentRef = React.useRef();
	const voucherRedux = useSelector((state) => state.voucherRedux);
	const users = useSelector((state) => state?.userRedux?.users);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [visible, toggleVisible] = useState(false);
	const [selectedRecordToPrint, setSelectedRecordToPrint] = useState(null);
	const [searchKey, setSearchKey] = useState('');
	const [tableData, setTableData] = useState(voucherRedux?.vouchers || []);
	const [currentPage, setCurrentPage] = useState(1);
	const [state, setState] = useState({
		selectedRow: null,
		visible: false,
	});
	const [pageSize, setPageSize] = useState(initialPageSize);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const dispatch = useDispatch();

	const getVouchers = useCallback(() => {
		dispatch(getApi(ACTIONS.GET_VOUCHERS, `${SERVER_IP}voucher?orgId=${globalRedux?.selectedBranch?.id}`));
	}, [dispatch, globalRedux?.selectedBranch?.id]);

	useEffect(() => {
		getVouchers();
		generatePagination(tableData);
	}, [getVouchers, tableData]);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_VOUCHER === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_VOUCHER'));
			doIt = true;
		}
		if (doIt) {
			getVouchers();
		}
	}, [globalRedux.apiStatus, dispatch, getVouchers]);

	useEffect(() => {
		setTableData(voucherRedux?.vouchers);
		!state?.visible && state?.selectedRow && setState((state) => ({ ...state, selectedRow: null }));
	}, [voucherRedux?.vouchers, state?.visible, state?.selectedRow]);

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((record) => {
			return (
				(record?.ticketType || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.priority || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.customerName || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.mobile || '')?.toLowerCase().includes(searchKey.toLowerCase())
			);
		});
	}, [tableData, searchKey]);

	const handleEdit = (rowData) => {
		setState({
			...state,
			selectedRow: rowData,
			visible: true,
		});
	};

	const column = [
		{
			title: 'Voucher #',
			dataIndex: 'voucherNumber',
			key: 'voucherNumber',
			// fixed: 'left',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.toString()}
				/>
			),
		},
		{
			title: 'Voucher Date',
			dataIndex: 'voucherDate',
			key: 'voucherDate',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value ? moment(value).format(DATE_FORMAT.MM_DD_YYYY) : ''}
				/>
			),
		},
		{
			title: 'Voucher Name',
			dataIndex: 'voucherheadId',
			key: 'voucherheadId',
			// fixed: 'left',
			width: 150,
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.voucherheadName}
				/>
			),
		},
		{
			title: 'Transaction Type',
			dataIndex: 'transactionType',
			key: 'transactionType',
			// fixed: 'left',
			width: 150,
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />
			),
		},
		{
			title: 'Acc Book',
			dataIndex: 'accbookId',
			key: 'accbookId',
			// fixed: 'left',
			width: 150,
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.accbookName}
				/>
			),
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
			align: 'right',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.toString()}
				/>
			),
		},
		{
			title: 'Particulars',
			dataIndex: 'particulars',
			key: 'particulars',
			render: (value, row) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />
			),
		},
		{
			title: 'Created Date',
			dataIndex: 'created_at',
			align: 'left',
			render: (value) => getDateFormat(value),
		},
		{
			title: 'Created By',
			dataIndex: 'createdBy',
			align: 'left',
			render: (value) => value?.firstName,
		},
		{
			title: 'Action',
			align: 'center',
			dataIndex: 'voucherNumber',
			width: '10%',
			render: (value, row, index) => {
				return (
					<Row gutter={10} justify="center">
						<Col onClick={() => handleEdit(row)} className="edit_icon">
							<EditOutlined />
						</Col>
						<Col className="delete_icon">
							<Col onClick={() => setSelectedRecordToPrint(row)}>
								<PrinterOutlined />
							</Col>
						</Col>
					</Row>
				);
			},
		},
	];

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_TICKETS === API_STATUS.PENDING, [globalRedux.apiStatus]);

	const onSelectChange = (selectedRowKeys) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	};

	const handleAfterPrint = () => {
		setSelectedRecordToPrint(null);
	};

	const reactToPrintContent = React.useCallback(() => {
		return componentRef.current;
	}, []);

	const handlePrint = useReactToPrint({
		content: reactToPrintContent,
		documentTitle: 'Voucher',
		removeAfterPrint: true,
		onAfterPrint: handleAfterPrint,
		// onBeforeGetContent: handleOnBeforeGetContent,
	});

	useEffect(() => {
		selectedRecordToPrint && handlePrint();
	}, [selectedRecordToPrint, handlePrint]);

	return (
		<>
			<div style={{ display: 'none' }}>
				<VoucherToPrint ref={componentRef} data={selectedRecordToPrint} />
			</div>
			<VoucherListPresentational
				{...{
					column,
					filteredData,
					visible,
					toggleVisible,
					handleTableChange,
					getStartingValue,
					getEndingValue,
					pageSize,
					initialPageSizeOptions,
					initialPageSize,
					currentPage,
					setSearchKey,
					refreshList: getVouchers,
					tableLoading,
					rowSelection,
					users,
					state,
					setState,
				}}
			/>
		</>
	);
});

export default VoucherListFunctional;
