import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReloadOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { SERVER_IP } from 'assets/Config';
// import ExcelDownload from 'components/excel-download';
import TableComponent from 'components/table-component';
import { convertToIndianRupees, formQueryStringFromObject } from 'helpers';
import { sendGetRequest } from 'redux/sagas/utils';
import { capitalizeFirstLetter } from 'services/Utils';

const columns = [
	{
		title: 'Customer Name',
		dataIndex: 'customer',
		key: 'customer',
		render: (value) => <span>{value?.customerName || '-'}</span>,
	},
	// {
	// 	title: 'Payment For',
	// 	dataIndex: 'paymentFor',
	// 	key: 'paymentFor',
	// 	render: (value) => <span>{capitalizeFirstLetter(value) || '-'}</span>,
	// },
	{
		title: 'Service',
		dataIndex: 'service',
		key: 'service',
		render: (value) => <span>{value?.serviceName || ' - '}</span>,
	},
	{
		title: 'Membership',
		dataIndex: 'membership',
		key: 'membership',
		render: (value) => (
			<span
				style={{
					color: '#4d7cfe',
					fontWeight: 'bold',
				}}>
				{value?.membershipName || ' - '}
			</span>
		),
	},
	{
		title: 'Product',
		dataIndex: 'product',
		key: 'product',
		render: (value) => <span>{value?.productName || ' - '}</span>,
	},
	{
		title: 'Payment',
		dataIndex: 'paymentMethod',
		key: 'paymentMethod',
		render: (value) => <span>{capitalizeFirstLetter(value) || ' - '}</span>,
	},
	{
		title: 'Amount',
		dataIndex: 'amount',
		key: 'amount',
		align: 'right',
		sorter: (a, b) => a.amount - b.amount,
		render: (text) => <span>{convertToIndianRupees(text)}</span>,
	},
];

const RecentPayments = () => {
	const [dataSource, setDataSource] = useState({
		data: [],
		loading: true,
	});
	const globalRedux = useSelector((state) => state.globalRedux);

	// const exportData = useMemo(() => {
	// 	const data = {
	// 		headers: columns?.map((col) => col?.title) || [],
	// 		data: [
	// 			...((dataSource?.data || []).map((stock, index) => ({
	// 				[columns[0]?.title]: stock?.date,
	// 				[columns[1]?.title]: parseFloat(stock?.totalAmount).toFixed(2),
	// 			})) || []),
	// 		],
	// 	};
	// 	return data;
	// }, [dataSource?.data, columns]);
	const getString = useCallback(() => formQueryStringFromObject({
		branchId: globalRedux?.selectedBranch?.id,
		limit: 10,
		offset: 0,
		sortBy: "updated_at",
		sortByOrder: "DESC",
	}), [globalRedux?.selectedBranch?.id]);

	const getPayments = useCallback(async () => {
		try {
			setDataSource((dataSource) => ({
				...dataSource,
				loading: true,
			}));
			const string = getString();
			const {
				data: { data },
			} = await sendGetRequest(null, `${SERVER_IP}api/payments?${string}`);
			setDataSource({
				data: data,
				loading: false,
			});
		} catch (error) {
			setDataSource({
				data: [],
				loading: false,
			});
		}
	}, [getString]);

	useEffect(() => {
		getPayments();
	}, [getPayments]);

	return (
		<Col span={24}>
			<Row className="dashboard_table">
				<Col
					style={{
						fontWeight: 'bold',
						fontSize: '1rem',
						padding: 10,
						backgroundColor: '#fff',
						borderRadius: '5px 5px 0 0',
					}}
					span={24}>
					<Row
						gutter={[10, 10]}
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}>
						<Col>Recent Payments</Col>
						<Col>
							{/* <ExcelDownload {...{ ...exportData }} iconOnly /> */}
							<Button
								type="primary"
								icon={<ReloadOutlined />}
								loading={dataSource.loading}
								onClick={getPayments}
								style={{ marginLeft: 10 }}
							/>
						</Col>
					</Row>
				</Col>
				<Col span={24}>
					<TableComponent
						className="custom-table"
						dataSource={dataSource?.data || []}
						columns={columns}
						pagination={false}
						loading={dataSource.loading}
						style={{
							width: '100%',
						}}
						scroll={{ y: 344 }}
					/>
				</Col>
			</Row>
		</Col>
	);
};

export default RecentPayments;
