import React, { useState } from 'react';
import { Button, Col, Input, Radio, Row, Space } from 'antd';

const OPTIONS = [
	{
		label: 'Active Member (77)',
		value: 'activeMember',
	},
	{
		label: 'Inactive Member (77)',
		value: 'inactiveMember',
	},
	{
		label: 'Pending Payment (77)',
		value: 'pendingPayment',
	},
	{
		label: 'Paid Member (77)',
		value: 'paidMember',
	},
	{
		label: 'Expired Member (77)',
		value: 'expiredMember',
	},
];

const SendBulkSMS = () => {
	const [value, setValue] = useState(1);

	const onChange = (e) => {
		setValue(e.target.value);
	};
	return (
		<Row style={{ padding: 20 }}>
			<Col span={24}>
				<h3>Send Bulk SMS: Available sms counts:1400</h3>
			</Col>
			<Col span={24}>
				<Row gutter={[10, 10]}>
					<Col span={24}>
						<p>Send Bulk SMS Page</p>
					</Col>
					<Col span={24}>
						<Radio.Group onChange={onChange} value={value}>
							<Space direction="vertical">
								{OPTIONS.map((option) => (
									<Radio key={option.value} value={option.value}>
										{option.label}
									</Radio>
								))}
							</Space>
						</Radio.Group>
					</Col>
					<Col span={24}>
						<Input.TextArea placeholder="Enter your message here" rows={4} />
					</Col>
					<Col span={24}>
						<Button type="primary">Send SMS</Button>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default SendBulkSMS;
