import React, { useEffect } from 'react';
import moment from 'moment';
import { Row, Col, Input, Button, Form, Drawer, Radio, DatePicker } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { putApi } from 'redux/sagas/putApiSaga';
import { API_STATUS, DATE_FORMAT, GENDER } from 'constants/app-constants';
import { SERVER_IP } from 'assets/Config';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';

const AddCoach = ({ coachAddModal, width = '40%', editCoach, setCoachAddModal, refreshList, handleClose }) => {
	const [form] = Form.useForm();
	const globalRedux = useSelector((state) => state.globalRedux);
	const dispatch = useDispatch();
	const branchId = globalRedux?.selectedBranch?.id;

	useEffect(() => {
		if (editCoach) {
			form.setFieldsValue({
				// type: editCoach?.type || 'Contact',
				coachName: editCoach?.coachName || '',
				age: editCoach?.age || '',
				email: editCoach?.email || '',
				password: editCoach?.password || '',
				mobile: editCoach?.mobile || '',
				address: editCoach?.address || '',
				gender: editCoach?.gender || '',
				aadhaarNo: editCoach?.aadhaarNo || '',
				joiningDate: moment(editCoach?.joiningDate) || '',
			});
		} else {
			form?.resetFields();
		}
	}, [editCoach, form]);

	const handleSubmit = (values) => {
		let data = {
			coachName: values?.coachName || '',
			age: values?.age || '',
			email: values?.email || '',
			mobile: values?.mobile || '',
			address: values?.address || '',
			gender: values?.gender || '',
			joiningDate: values?.joiningDate || '',
			aadhaarNo: values?.aadhaarNo || '',
			branchId,
			role: 'staff',
			...(!editCoach && { password: values?.password || '' }),
		};

		if (!editCoach) {
			dispatch(postApi(data, 'ADD_COACH'));
		} else {
			let url = `${SERVER_IP}api/coaches/${editCoach.id}`;
			dispatch(putApi(data, 'EDIT_COACH', url));
		}
	};

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_COACH === 'SUCCESS' || globalRedux.apiStatus.EDIT_COACH === 'SUCCESS') {
			dispatch(resetApiStatus(editCoach ? 'EDIT_COACH' : 'ADD_COACH'));
			refreshList?.();
			handleClose?.();
			form?.resetFields();
		}
	}, [globalRedux.apiStatus, editCoach, setCoachAddModal, dispatch, refreshList, handleClose, form]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const loading = globalRedux.apiStatus.ADD_COACH === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_COACH === API_STATUS.PENDING;

	return (
		<Drawer
			placement="right"
			title={`${editCoach ? 'Edit' : 'New'} Coach`}
			width={width || '40%'}
			visible={coachAddModal}
			closable
			onClose={() => setCoachAddModal(false)}
			destroyOnClose={true}>
			<Row>
				<Col span={24}>
					{/* <Row style={{ backgroundColor: '#fff', padding: '10px 0px' }}>
						<Col>
							<h6 style={{ marginBottom: '0px' }}>{editCoach ? 'Edit' : 'New'} Coach</h6>
						</Col>
					</Row>
					<Divider /> */}
					<Row style={{ marginTop: 20 }}>
						<Form
							name="add-coach"
							className="required_in_right"
							style={{ width: '100%' }}
							colon={true}
							labelAlign="left"
							form={form}
							onFinish={handleSubmit}
							initialValues={{}}
							{...layer1FormCol}>
							<Form.Item
								label="Coach Name"
								name="coachName"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<Input placeholder="Coach name" />
							</Form.Item>
							<Form.Item
								label="Age"
								name="age"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<Input placeholder="Age" />
							</Form.Item>
							<Form.Item
								label="Gender"
								name="gender"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<Radio.Group options={GENDER} optionType="button" buttonStyle="solid" />
							</Form.Item>
							<Form.Item
								label="Mobile"
								name="mobile"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<Input maxLength={10} placeholder="Mobile" />
							</Form.Item>
							<Form.Item
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}
								label="Email"
								name="email">
								<Input placeholder="Email" />
							</Form.Item>
							{!!!editCoach && (
								<Form.Item
									rules={[
										{
											required: true,
											message: 'This Field is required!',
										},
									]}
									label="Password"
									name="password">
									<Input.Password
										placeholder="Password"
										// iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
									/>
								</Form.Item>
							)}
							<Form.Item
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}
								label="Aadhaar"
								name="aadhaarNo">
								<Input placeholder="Aadhaar" />
							</Form.Item>
							<Form.Item
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}
								label="Joining Date"
								name="joiningDate">
								<DatePicker style={{ width: '100%' }} format={DATE_FORMAT.DD_MM_YYYY} />
							</Form.Item>
							<Form.Item
								label="Address"
								name="address"
								rules={[
									{
										required: false,
										message: 'This Field is required!',
									},
								]}>
								<Input.TextArea placeholder="Address" />
							</Form.Item>
							<Form.Item
								wrapperCol={{
									offset: 0,
									span: 24,
								}}>
								<Row className="space-between" style={{ paddingTop: 20, width: '100%', margin: 0 }}>
									<Button danger style={{ width: '49%' }} onClick={() => setCoachAddModal(false)}>
										Cancel
									</Button>
									<Button loading={loading} style={{ width: '49%' }} type="primary" htmlType="submit">
										{editCoach ? 'Update' : 'Save'}
									</Button>
								</Row>
							</Form.Item>
						</Form>
					</Row>
				</Col>
			</Row>
		</Drawer>
	);
};

export default AddCoach;
