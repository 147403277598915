import React from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import {
	Popconfirm,
	Input,
	// Button,
	Pagination,
	Row,
	Col,
	Button,
} from 'antd';
import { useDispatch } from 'react-redux';
import { SERVER_IP } from 'assets/Config';
import TableComponent from 'components/table-component';
import { deleteApi } from 'redux/sagas/deleteApiSaga';
import EditPayment from 'pages/payments/edit-payment';
import EditPersonalTrainingPayment from 'pages/personal-training/edit-personal-training-payment';
import { ReloadOutlined } from '@ant-design/icons';

const PaymentListPresentational = ({
	filteredData,
	column,
	tableLoading,
	rowSelection,
	selectedRowKeys,
	currentPage,
	pageSize,
	initialPageSizeOptions,
	initialPageSize,
	handleTableChange,
	setSearchKey,
	getStartingValue,
	getEndingValue,
	paymentAddModal,
	setPaymentAddModal,
	refreshList,
	editPaymentId,
	handleClose,
	paymentType,
	paymentFor,
	onTableChange,
	handlePaginationChange,
	paginationDetails
}) => {
	const dispatch = useDispatch();
	return (
		<>
			<Row>
				<Col span={paymentType ? 24 : 24} style={{}}>
					<TableComponent
						loading={tableLoading}
						className="custom-table"
						style={{ width: '100%' }}
						columns={column}
						bordered
						rowKey={(record) => record.id}
						dataSource={filteredData}
						rowSelection={rowSelection}
						onChange={onTableChange}
						title={() => (
							<Row style={{ justifyContent: 'space-between' }}>
								<Col span={24}>
									<Row gutter={[10, 10]}>
										<Col xl={24}>
											<Row gutter={[10, 10]} align="middle" justify={'space-between'}>
												<Col>
													<Row gutter={[10, 10]}>
														<Col>
															<Input
																placeholder="Search"
																suffix={<AiOutlineSearch />}
																style={{ height: '30px' }}
																onChange={({ target: { value } }) => setSearchKey(value)}
															/>
														</Col>
														{selectedRowKeys?.length === 1 ? (
															<Col>
																<Popconfirm
																	title={`Are you sure want to delete this Payment?`}
																	okText="Delete"
																	cancelText="No"
																	onConfirm={() => {
																		let url = `${SERVER_IP}api/payments/${selectedRowKeys?.[0]}`;
																		dispatch(deleteApi('DELETE_PAYMENT', url));
																	}}>
																	<div style={{ textDecoration: 'underline', color: 'red', cursor: 'pointer' }}>
																		Delete
																	</div>
																</Popconfirm>
															</Col>
														) : null}
													</Row>
												</Col>
												<Col>
													{/* <ExcelDownload {...{ ...exportData }} iconOnly /> */}
													<Button
														type="primary"
														icon={<ReloadOutlined />}
														loading={tableLoading}
														onClick={() => refreshList(true)}
														style={{ marginLeft: 10 }}
													/>
												</Col>
											</Row>
										</Col>
									</Row>
								</Col>
								{/* <Col>
									<Button
										// icon={<PlusOutlined />}
										type="primary"
										// style={{ height: '30px', width: '100%' }}
										onClick={handleEditPayment}>
										Create Payment
									</Button>
								</Col> */}
							</Row>
						)}
						pagination={{
							current: currentPage,
							pageSize: pageSize,
							position: ['none', 'none'],
						}}
						footer={() => (
							<Row justify="space-between">
								<Col span={12}>
									{!!paginationDetails?.totalRecords && `Showing ${getStartingValue()} - ${getEndingValue()} of ${paginationDetails?.totalRecords} Data`}
								</Col>
								<Col span={12}>
									<div style={{ textAlign: 'right' }}>
										<Pagination
											pageSizeOptions={initialPageSizeOptions}
											defaultPageSize={initialPageSize}
											showSizeChanger={true}
											total={paginationDetails?.totalRecords}
											onChange={handlePaginationChange}
											responsive
										/>
									</div>
								</Col>
							</Row>
						)}
					/>
				</Col>
			</Row>
			{paymentFor !== 'personalTraining' ? (
				<EditPayment {...{ paymentAddModal, setPaymentAddModal, refreshList, editPaymentId, handleClose, paymentFor }} />
			) : (
				<EditPersonalTrainingPayment {...{ paymentAddModal, setPaymentAddModal, refreshList, editPaymentId, handleClose, paymentFor }} />
			)}
		</>
	);
};

export default PaymentListPresentational;
