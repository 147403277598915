import React from 'react';
import { Col, Row } from 'antd';
import LottieFile from 'assets/lottie-files';
import LottieComponent from './lottie-component';
import { Link } from 'react-router-dom';

const NoData = () => {
	return (
		<Row
			justify="center"
			align="middle"
			style={{
				height: '100%',
				padding: 20,
			}}>
			<Col span={24}>
				<LottieComponent width={'30%'} file={LottieFile.NoData} />
			</Col>
			<Col span={24} className="error" style={{ fontSize: '1rem', textAlign: 'center' }}>
				<Col>Sorry, There is no service found.</Col>
				<Col>Please add atleast one service to add memberships..</Col>
				<Col><Link to="/services">Go to service page</Link></Col>
			</Col>
		</Row>
	);
};

export default NoData;
