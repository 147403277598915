let initialState = {
    customerReports : [],
};

const customerReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CUSTOMER_REPORTS" : 
      return {
        ...state,
        customerReports : action.payload
      }   
    default : return state
  }

}

export { customerReportReducer }
