import React from 'react';
import { Col, Row, Tabs } from 'antd';
import FoodsList from 'pages/foods/foods-list';
import DietPlanTemplateList from 'pages/diet-plan-template/diet-plan-template-list';
import DietPlanListFunctional from './components/diet-plan-list-functional';

const { TabPane } = Tabs;

const DietPlanList = () => {
	return (
		<Row className="personal-training-tab-container">
			<Col xl={24} className="container">
				<Tabs defaultActiveKey="pt">
					<TabPane tab={'Diet Plan'} key="DIET_PLAN">
						<DietPlanListFunctional />
					</TabPane>
					<TabPane tab={'Foods'} key="FOODS">
						<FoodsList />
					</TabPane>
					<TabPane tab={'Diet Plan Template'} key="DIET_PLAN_TEMPLATE">
						<DietPlanTemplateList />
					</TabPane>
				</Tabs>
			</Col>
		</Row>
	);
};

export default DietPlanList;
