// customer-list index

import React from 'react';
import CustomerListTab from './components/customer-list-tab';
import './style.scss';

const CustomerList = () => {
	return <CustomerListTab />;
};

export default CustomerList;
