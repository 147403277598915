import React, { useEffect } from 'react';
import { Row, Col, Input, Button, Divider, Form } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { putApi } from 'redux/sagas/putApiSaga';
import { API_STATUS, GST_TREATMENT } from 'constants/app-constants';
import { SERVER_IP } from 'assets/Config';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';

const AddCustomer = ({ handleClose, editCustomer, setCustomerAddModal }) => {
	const [gstTreatment, setGstTreatment] = React.useState(GST_TREATMENT[0]?.value);
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const globalRedux = useSelector((state) => state.globalRedux);
	// const states = useSelector((state) => state.globalRedux?.states);

	const isConsumer = React.useMemo(() => gstTreatment === GST_TREATMENT[0]?.value, [gstTreatment]);

	useEffect(() => {
		isConsumer &&
			form.setFieldsValue({
				gstin: '',
			});
	}, [gstTreatment, form, isConsumer, editCustomer]);

	useEffect(() => {
		editCustomer &&
			form?.setFieldsValue({
				gstin: editCustomer?.gstin,
			});
		editCustomer && setGstTreatment(editCustomer?.gstTreatment);
	}, [editCustomer, form]);

	const handleSubmit = (values) => {
		let data = {
			orgId: globalRedux?.selectedBranch?.id,
			customerName: values?.customerName || '',
			email: values?.email || '',
			mobile: values?.mobile || '',
			address: values?.address || '',
		};

		if (!editCustomer) {
			dispatch(postApi(data, 'ADD_CUSTOMER'));
		} else {
			let url = `${SERVER_IP}customer/${editCustomer._id}?orgId=${globalRedux?.selectedBranch?.id}`;
			dispatch(putApi(data, 'EDIT_CUSTOMER', url));
		}
	};

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_CUSTOMER === 'SUCCESS' || globalRedux.apiStatus.EDIT_CUSTOMER === 'SUCCESS') {
			dispatch(resetApiStatus(editCustomer ? 'EDIT_CUSTOMER' : 'ADD_CUSTOMER'));
			setCustomerAddModal(false);
			handleClose();
		}
	}, [globalRedux.apiStatus, editCustomer, handleClose, setCustomerAddModal, dispatch]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const loading = globalRedux.apiStatus.ADD_CUSTOMER === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_CUSTOMER === API_STATUS.PENDING;

	return (
		<Row>
			<Col span={24}>
				<Row style={{ backgroundColor: '#fff', padding: '10px 0px' }}>
					<Col>
						<h6 style={{ marginBottom: '0px' }}>{editCustomer ? 'Edit' : 'New'} Customers</h6>
					</Col>
				</Row>
				<Divider />
				<Row style={{ marginTop: 20 }}>
					<Form
						name="add-customer"
						className="required_in_right"
						style={{ width: '100%' }}
						colon={true}
						labelAlign="left"
						form={form}
						initialValues={{
							customerType: editCustomer?.customerType || 'Individual',
						}}
						onFinish={handleSubmit}
						{...layer1FormCol}>
						<Form.Item
							label="Customer Name"
							name="customerName"
							initialValue={editCustomer?.customerName}
							rules={[
								{
									required: true,
									message: 'This Field is required!',
								},
							]}>
							<Input />
						</Form.Item>
						<Form.Item
							label="Mobile"
							name="mobile"
							initialValue={editCustomer?.mobile}
							rules={[
								{
									required: true,
									message: 'This Field is required!',
								},
							]}>
							<Input />
						</Form.Item>
						<Form.Item label="Email" name="email" initialValue={editCustomer?.email}>
							<Input />
						</Form.Item>
						<Form.Item
							label="Address"
							name="address"
							rules={[
								{
									required: true,
									message: 'This Field is required!',
								},
							]}
							initialValue={editCustomer?.billingDetails?.[0]?.addressLine1}>
							<Input />
						</Form.Item>
						{/* <Form.Item label="Remarks" name="remarks" initialValue={editCustomer?.remarks}>
							<Input.TextArea />
						</Form.Item> */}
						<Form.Item
							wrapperCol={{
								offset: 0,
								span: 24,
							}}>
							<Row className="space-between" style={{ paddingTop: 20, width: '100%', margin: 0 }}>
								<Button danger style={{ width: '49%' }} onClick={() => setCustomerAddModal(false)}>
									Cancel
								</Button>
								<Button loading={loading} style={{ width: '49%' }} type="primary" htmlType="submit">
									{editCustomer ? 'Update' : 'Save'}
								</Button>
							</Row>
						</Form.Item>
					</Form>
				</Row>
			</Col>
		</Row>
	);
};

export default AddCustomer;
