import React, { useCallback, useEffect } from 'react';
// import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Descriptions, Modal, Row, Spin, Switch, Table, Tag } from 'antd';
import { SERVER_IP } from 'assets/Config';
import { API_STATUS, DATE_FORMAT } from 'constants/app-constants';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { capitalizeFirstLetter, getDateFormat, getINRMoneyFormat } from 'services/Utils';
import { putApi } from 'redux/sagas/putApiSaga';

const CustomerDetails = ({ id, onCloseCustomerDetails, viewDetails = false }) => {
	const customerRedux = useSelector((state) => state.customerRedux);
	const { selectedCustomerDetails } = customerRedux;
	const globalRedux = useSelector((state) => state.globalRedux);
	const dispatch = useDispatch();

	const columns = [
		{
			title: 'S.No',
			dataIndex: 'serviceId',
			key: 'serviceId',
			render: (text, row, index) => <p>{index + 1}</p>,
		},
		{
			title: 'Paid On',
			dataIndex: 'paidOn',
			render: (text) => getDateFormat(text, DATE_FORMAT.DD_MM_YYYY),
		},
		{
			title: 'Paid For',
			key: 'membership',
			render: (text, record) => (
				<>
					{record.membership && (
						<>
							<Tag color="geekblue">Membership</Tag>
							<Tag color="green">{text.service?.serviceName}</Tag>
							<Tag color="green">{text.membership?.membershipName}</Tag>
						</>
					)}
					{record.product && (
						<>
							<Tag color="geekblue">Product</Tag>
							<Tag color="green">{text.product?.productName}</Tag>
						</>
					)}
					{record.paymentFor === "Personal Training" && (
						<>
						<Tag color="geekblue">{record.paymentFor}</Tag>
						</>
					)}
				</>
			),
		},
		{
			title: 'Amount Paid',
			dataIndex: 'amount',
			align: 'right',
			render: (text) => getINRMoneyFormat(text),
		},
		{
			title: 'Balance',
			dataIndex: 'balance',
			align: 'right',
			render: (text) => Number(text) > 0 ? getINRMoneyFormat(text) : "N/A",
		},
		{
			title: 'Payment Method',
			dataIndex: 'paymentMethod',
			render: (text, record) => {
				return text === 'upi' ? (
					<>
						<Tag color="volcano">UPI</Tag>
						<Tag color="green">{record.upiTransactionId}</Tag>
					</>
				) : (
					<Tag color="green">Cash</Tag>
				);
			},
		},
		{
			title: 'Received By',
			dataIndex: 'coach',
			render: (text) => text.coachName,
		},
	];

	const getCustomerDetails = useCallback(() => {
		if (id) {
			let url = `${SERVER_IP}api/customers/${id}`;
			dispatch(getApi('SET_SELECTED_CUSTOMER_DETAILS', url));
		}
	}, [dispatch, id]);

	useEffect(() => {
		if (globalRedux.apiStatus.SET_SELECTED_CUSTOMER_DETAILS === 'SUCCESS') {
			dispatch(resetApiStatus('SET_SELECTED_CUSTOMER_DETAILS'));
		}
	}, [globalRedux.apiStatus.SET_SELECTED_CUSTOMER_DETAILS, dispatch]);

	useEffect(() => {
		getCustomerDetails();
	}, [getCustomerDetails]);

	const customerDetailsLoading = globalRedux.apiStatus.SET_SELECTED_CUSTOMER_DETAILS === API_STATUS.PENDING;


	const handleActivation = (isActive) => {
		let data = {
			isActive,
		};
		let url = `${SERVER_IP}api/customers/changeStatus/${selectedCustomerDetails?.id}`;
		dispatch(putApi?.(data, 'CHANGE_STATUS', url));
	};

	useEffect(() => {
		if (globalRedux.apiStatus.CHANGE_STATUS === 'SUCCESS') {
			getCustomerDetails?.();
		}
	}, [globalRedux.apiStatus?.CHANGE_STATUS, getCustomerDetails]);

	const changingStatus = globalRedux.apiStatus.CHANGE_STATUS === API_STATUS.PENDING;


	return (
		<Modal
			title={<p>Customer Details</p>}
			footer={
				<Button type="primary" onClick={onCloseCustomerDetails}>
					Close
				</Button>
			}
			width={"70%"}
			loading={customerDetailsLoading}
			open={viewDetails}
			centered
			onCancel={onCloseCustomerDetails}>
			<Row>
				{customerDetailsLoading || changingStatus ? (
					<Col
						span={24}
						style={{
							height: 400,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}>
						<Row justify={'center'}>
							<Col>
								<Spin size="large" />
							</Col>
							<Col span={24}>
								<p>Fetching Customer Details...</p>
							</Col>
						</Row>
					</Col>
				) : (
					<Col span={24} style={{ padding: 20 }}>
						<Row>
							<Col span={24}>
								<div style={{float: 'right'}} >
									<Switch
										style={{ width: '100%' }}
										checkedChildren="Active"
										unCheckedChildren="Inactive"
										checked={selectedCustomerDetails?.isActive}
										onChange={(value) => handleActivation(value)}
									/>
								</div>
							</Col>
						</Row>
						<div style={{ overflowY: 'scroll', height: 400 }}>
							<Descriptions title="Basic Info">
								<Descriptions.Item label="Name">{selectedCustomerDetails?.customerName}</Descriptions.Item>
								<Descriptions.Item label="Mobile">{selectedCustomerDetails?.mobile}</Descriptions.Item>
								<Descriptions.Item label="Email">{selectedCustomerDetails?.email}</Descriptions.Item>
								<Descriptions.Item label="Address">{selectedCustomerDetails?.address}</Descriptions.Item>
								<Descriptions.Item label="Gender">
									{selectedCustomerDetails?.gender && capitalizeFirstLetter(selectedCustomerDetails?.gender)}
								</Descriptions.Item>
								<Descriptions.Item label="Aadhaar">{selectedCustomerDetails?.aadhaarNo}</Descriptions.Item>
								<Descriptions.Item label="DOB">
									{getDateFormat(selectedCustomerDetails?.dob, DATE_FORMAT.DD_MM_YYYY)}
								</Descriptions.Item>
								<Descriptions.Item label="Age">{selectedCustomerDetails?.age}</Descriptions.Item>
								<Descriptions.Item label="Occupation">{selectedCustomerDetails?.occupation}</Descriptions.Item>
								{selectedCustomerDetails?.referral?.id !== selectedCustomerDetails?.id && (
									<Descriptions.Item label="Referred By">{selectedCustomerDetails?.referral?.customerName}</Descriptions.Item>
								)}
								<Descriptions.Item label="Remark">{selectedCustomerDetails?.remarks}</Descriptions.Item>
							</Descriptions>
							<Descriptions title="Onboarding Info">
								<Descriptions.Item label="Onboarded By">{selectedCustomerDetails?.coach?.coachName}</Descriptions.Item>
								<Descriptions.Item label="Onboarded On">
									{getDateFormat(selectedCustomerDetails?.joiningDate, DATE_FORMAT.DD_MM_YYYY)}
								</Descriptions.Item>
								<Descriptions.Item label="Next Billing Date">
									{getDateFormat(selectedCustomerDetails?.nextBillDate, DATE_FORMAT.DD_MM_YYYY)}
								</Descriptions.Item>
								<Descriptions.Item label="Height">{selectedCustomerDetails?.height} cm</Descriptions.Item>
								<Descriptions.Item label="Weight">{selectedCustomerDetails?.weight} kgs</Descriptions.Item>
								<Descriptions.Item label="Service">{selectedCustomerDetails?.service?.serviceName}</Descriptions.Item>
								<Descriptions.Item label="Membership">{selectedCustomerDetails?.membership?.membershipName}</Descriptions.Item>
							</Descriptions>
							{selectedCustomerDetails?.payments?.length > 0 && (
								<>
									<h5>Payments</h5>
									<Table columns={columns} dataSource={selectedCustomerDetails?.payments} />
								</>
							)}
						</div>
					</Col>
				)}
			</Row>
		</Modal>
	);
};

export default CustomerDetails;
