import React from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { Popconfirm, Input, Button, Pagination, Row, Col } from 'antd';
import { useDispatch } from 'react-redux';
import { SERVER_IP } from 'assets/Config';
import TableComponent from 'components/table-component';
import { deleteApi } from 'redux/sagas/deleteApiSaga';
import AddVisitor from 'pages/visitors/add-visitor';
import AddCustomer from 'pages/customers/add-customer';
import FollowUpEntry from 'pages/visitors/make-followup-entry';
import { ReloadOutlined } from '@ant-design/icons';

const VisitorListPresentational = ({
	filteredData,
	column,
	tableLoading,
	rowSelection,
	selectedRowKeys,
	handleAddVisitor,
	currentPage,
	pageSize,
	initialPageSizeOptions,
	initialPageSize,
	handleTableChange,
	setSearchKey,
	getStartingValue,
	getEndingValue,
	visitorAddModal,
	setVisitorAddModal,
	customerAddModal,
	setCustomerAddModal,
	refreshList,
	editVisitor,
	handleClose,
	convertCustomer,
	converted,
	followUpModal,
	setFollowUpModal,
}) => {
	const dispatch = useDispatch();
	return (
		<>
			<Row>
				<Col span={24} style={{}}>
					<TableComponent
						loading={tableLoading}
						className="custom-table"
						style={{ width: '100%' }}
						columns={column}
						bordered
						rowKey={(record) => record.id}
						dataSource={filteredData}
						rowSelection={rowSelection}
						title={() => (
							<Row style={{ justifyContent: 'space-between' }}>
								<Col span={24}>
									<Row gutter={[10, 10]}>
										<Col xl={24}>
											<Row gutter={[10, 10]} align="middle" justify={'space-between'}>
												<Col>
													<Row gutter={[10, 10]} align="middle">
														<Col>
															<Input
																placeholder="Search"
																suffix={<AiOutlineSearch />}
																style={{ height: '30px' }}
																onChange={({ target: { value } }) => setSearchKey(value)}
															/>
														</Col>
														{selectedRowKeys?.length === 1 ? (
															<Col>
																<Popconfirm
																	title={`Are you sure want to delete this Visitor?`}
																	okText="Delete"
																	cancelText="No"
																	onConfirm={() => {
																		let url = `${SERVER_IP}api/visitors/${selectedRowKeys?.[0]}`;
																		dispatch(deleteApi('DELETE_VISITOR', url));
																		refreshList();
																	}}>
																	<div style={{ textDecoration: 'underline', color: 'red', cursor: 'pointer' }}>
																		Delete
																	</div>
																</Popconfirm>
															</Col>
														) : null}
													</Row>
												</Col>
												<Col>
													{/* <ExcelDownload {...{ ...exportData }} iconOnly /> */}
													<Button
														type="primary"
														icon={<ReloadOutlined />}
														loading={tableLoading}
														onClick={() => refreshList()}
														style={{ marginRight: 10 }}
													/>
													<Button
														// icon={<PlusOutlined />}
														type="primary"
														// style={{ height: '30px', width: '100%' }}
														onClick={handleAddVisitor}>
														Create Visitor
													</Button>
												</Col>
											</Row>
										</Col>
									</Row>
								</Col>
							</Row>
						)}
						pagination={{
							current: currentPage,
							pageSize: pageSize,
							position: ['none', 'none'],
						}}
						footer={() => (
							<Row justify="space-between">
								<Col span={12}>
									{!!filteredData?.length && `Showing ${getStartingValue()} - ${getEndingValue()} of ${filteredData?.length} Data`}
								</Col>
								<Col span={12}>
									<div style={{ textAlign: 'right' }}>
										<Pagination
											pageSizeOptions={initialPageSizeOptions}
											defaultPageSize={initialPageSize}
											showSizeChanger={true}
											total={filteredData?.length}
											onChange={handleTableChange}
											responsive
										/>
									</div>
								</Col>
							</Row>
						)}
					/>
				</Col>
			</Row>
			<FollowUpEntry {...{ followUpModal, setFollowUpModal, refreshList, editVisitor, handleClose }} />
			<AddVisitor {...{ visitorAddModal, setVisitorAddModal, refreshList, editVisitor, handleClose }} />
			<AddCustomer {...{ customerAddModal, setCustomerAddModal, refreshList, handleClose }} editCustomer={convertCustomer} isVisitor={true} />
		</>
	);
};

export default VisitorListPresentational;
