let initialState = {
    gyms : [],
};

const gymReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_GYMS" : 
      return {
        ...state,
        gyms: action.payload
      }   
    default : return state
  }

}

export { gymReducer }
