import { SET_EDIT_PERSONAL_TRAINING_PAYMENT } from "../constants";

function setPersonalTraining(data) {
	return {
		type: 'SET_PERSONAL_TRAINING',
		payload: data,
	};
}

function setEditPersonalTrainingPayment(data) {
	return {
		type: SET_EDIT_PERSONAL_TRAINING_PAYMENT,
		payload: data,
	};
}

export { setPersonalTraining, setEditPersonalTrainingPayment };
