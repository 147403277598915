import { CLOSE_GYM_PAYMENT_MODAL, OPEN_GYM_PAYMENT_MODAL, SET_EDIT_GYM_PAYMENT, SET_GYM_PAYMENTS, SET_GYM_PAYMENTS_PAGINATION } from '../constants';

let initialState = {
	payments: [],
	paymentModalOpen: false,
	paymentFor: '',
	editPayment: {},
	paginationDetails: {}
};

const gymPaymentReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_GYM_PAYMENTS:
			return {
				...state,
				payments: action.payload,
			};
		case OPEN_GYM_PAYMENT_MODAL:
			return {
				...state,
				paymentModalOpen: true,
				paymentFor: action.payload.type,
			};
		case CLOSE_GYM_PAYMENT_MODAL:
			return {
				...state,
				paymentModalOpen: false,
				paymentFor: null,
			};
		case SET_EDIT_GYM_PAYMENT:
			return {
				...state,
				editPayment: action.payload,
			};
		case SET_GYM_PAYMENTS_PAGINATION: 
			return {
				...state,
				paginationDetails: action.payload,
			};
		default:
			return state;
	}
};

export { gymPaymentReducer };
