let initialState = {
    paymentReports : [],
};

const paymentReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PAYMENT_REPORTS" : 
      return {
        ...state,
        paymentReports : action.payload
      }   
    default : return state
  }

}

export { paymentReportReducer }
