
function setGyms(data){
    return{
        type : "SET_GYMS",
        payload : data
    }
}

export {  
    setGyms
}