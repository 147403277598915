import {  SET_EDIT_PERSONAL_TRAINING_PAYMENT } from "../constants";

let initialState = {
	personalTraining: [],
	editPTPayment: {}
};

const personalTrainingReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_PERSONAL_TRAINING':
			return {
				...state,
				personalTraining: action.payload,
			};
		case SET_EDIT_PERSONAL_TRAINING_PAYMENT: 
			return {
				...state,
				editPTPayment: action.payload,
			};
		default:
			return state;
	}
};

export { personalTrainingReducer };
