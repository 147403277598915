import { CLOSE_PAYMENT_MODAL, OPEN_PAYMENT_MODAL, SET_EDIT_PAYMENT, SET_PAYMENTS } from '../constants';

function setPayments(data) {
	return {
		type: SET_PAYMENTS,
		payload: data,
	};
}

export function setPaymentsPagination(data){
    return{
        type : "SET_PAYMENTS_PAGINATION",
        payload : data
    }
}

function setEditPayment(data){
	return {
		type: SET_EDIT_PAYMENT,
		payload: data,
	};
}

function openPaymentModal(data) {
	return {
		type: OPEN_PAYMENT_MODAL,
		payload: data,
	};
}

function closePaymentModal(data) {
	return {
		type: CLOSE_PAYMENT_MODAL,
		payload: data,
	};
}

export { setPayments, openPaymentModal, closePaymentModal, setEditPayment };
