import React from 'react';
import { Row, Col, Tabs } from 'antd';
import CustomerListFunctional from './customer-list-functional';

const { TabPane } = Tabs;

const CustomerListTab = () => {
	return (
		<Row className="customer-tab-container" style={{ height: '80%' }}>
			<Col xl={24} className="container">
				<Tabs defaultActiveKey="All" destroyInactiveTabPane>
					<TabPane tab={'Male'} key="male">
						<CustomerListFunctional />
					</TabPane>
					<TabPane tab={'Female'} key="female">
						<CustomerListFunctional isFemale />
					</TabPane>
				</Tabs>
			</Col>
		</Row>
	);
};

export default CustomerListTab;
