import React, { useCallback } from 'react';
import { Button, Form, Input, Select, Modal, Drawer, Row, Col } from 'antd';
import { ExclamationCircleOutlined, LoadingOutlined, SearchOutlined } from '@ant-design/icons';

const { confirm } = Modal;

const layer1FormCol = {
	labelCol: {
		span: 12,
	},
	wrapperCol: {
		span: 12,
	},
};

const AddTicketPresentational = ({
	visible = false,
	toggleVisible,
	form,
	handleSubmit,
	debounceFn,
	searchList,
	searchString,
	handleRowClick,
	inputString,
	setInputString,
}) => {
	const showConfirm = useCallback(() => {
		confirm({
			title: 'Do you want to close this window?',
			icon: <ExclamationCircleOutlined />,
			content: 'You will be lost all the details you have entered here.',
			onOk() {
				toggleVisible(false);
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	}, [toggleVisible]);

	return (
		<Drawer maskClosable={false} title="Add New Ticket" placement="right" width={'40%'} visible={visible} destroyOnClose onClose={showConfirm}>
			<Row className="mt-3 m-2 pt-2">
				<Col span={24}>
					<Row style={{ padding: 0, paddingBottom: 10 }}>
						{/* <Col xl={24}>
						<Input suffix={<SearchOutlined />} />
					</Col> */}
						<Col span={24}>
							<Input
								value={inputString}
								onChange={({ target: { value } }) => {
									setInputString(value);
									debounceFn(value);
								}}
								placeholder="search contact here"
								style={{ width: '100%' }}
								suffix={!searchList?.loading ? <SearchOutlined /> : <LoadingOutlined />}
							/>
							{searchString && !!searchList?.data?.length && (
								<Col span={24} className="search_result_style">
									<Row className="search_result_container">
										{searchList?.data?.map((data, index) => (
											<Col span={24} onClick={() => handleRowClick(data)} className="list-style" key={index}>
												<p>Customer ID: {data?.customerNumber || ''}</p>
												<p>
													<span style={{ fontWeight: 'bold' }}>{data?.displayName || ''}</span>
												</p>
												<p>{data?.mobile || ''}</p>
												{(data?.billingDetails?.[0]?.addressLine1 || data?.billingDetails?.[0]?.addressLine2) && (
													<p>{`${data?.billingDetails?.[0]?.addressLine1 || ''} ${
														data?.billingDetails?.[0]?.addressLine2 || ''
													}`}</p>
												)}
											</Col>
										))}
									</Row>
								</Col>
							)}
						</Col>
					</Row>
				</Col>
				<Col span={24}>
					<Form name="add-customer" colon={true} labelAlign="left" form={form} onFinish={handleSubmit} {...layer1FormCol}>
						{/* <Col style={{ paddingBottom: 20 }}>
							<Input.Search placeholder="search by customer name or mobile or customerId" loading={false} />
						</Col> */}
						<Form.Item
							label="Customer Name"
							name="customerName"
							rules={[
								{
									required: true,
									message: 'This Field is required!',
								},
							]}>
							<Input className="disabled_input_style" disabled placeholder="Customer Name" />
						</Form.Item>
						{/* <Form.Item label="Customer Ref ID" className="custom">
							<Form.Item name="customerRefId" rules={[{ required: true, message: 'required field' }]}>
								<Input placeholder="CustomerID" />
							</Form.Item>
						</Form.Item> */}
						<Form.Item
							label="Mobile Number"
							name="mobile"
							rules={[
								{
									required: true,
									message: 'This Field is required!',
								},
							]}>
							<Input placeholder="Mobile Number" />
						</Form.Item>
						<Form.Item
							label="Address"
							name="address"
							rules={[
								{
									required: true,
									message: 'Please Enter Overview!',
								},
							]}>
							<Input.TextArea placeholder="Address" />
						</Form.Item>
						<Form.Item
							label="Ticket Type"
							name="ticketType"
							rules={[
								{
									required: true,
									message: 'Please Select One!',
								},
							]}>
							<Select placeholder="Ticket Type">
								{['New Installation', 'Service', 'Shifting', 'Complaint'].map((option) => (
									<Select.Option value={option}>{option}</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item
							label="Ticket Priority"
							name="priority"
							rules={[
								{
									required: true,
									message: 'Please Select One!',
								},
							]}>
							<Select placeholder="Ticket Priority">
								{['Normal', 'High', 'Emergency'].map((option) => (
									<Select.Option value={option}>{option}</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item label="Description" name="description" rules={[{ required: true, message: 'required field' }]}>
							<Input.TextArea placeholder="Description" />
						</Form.Item>
						<Form.Item
							wrapperCol={{
								span: 24,
							}}>
							<Row gutter={10}>
								<Col xl={12}>
									<Button style={{ width: '100%' }} type="primary" htmlType="submit">
										Save
									</Button>
								</Col>
								<Col xl={12}>
									<Button style={{ width: '100%' }} type="default" onClick={() => toggleVisible(false)}>
										Cancel
									</Button>
								</Col>
							</Row>
						</Form.Item>
					</Form>
				</Col>
			</Row>
		</Drawer>
	);
};

export default AddTicketPresentational;
