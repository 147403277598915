import React from 'react';
import { Col, Row, Tabs } from 'antd';
import PersonalTrainingListFunctional from './components/personal-training-list-functional';
import PeriodList from 'pages/periods/periods-list';

const { TabPane } = Tabs;

const PersonalTrainingList = () => {
	return (
		<Row className="personal-training-tab-container">
			<Col xl={24} className="container">
				<Tabs defaultActiveKey="pt">
					<TabPane tab={'PT'} key="pt">
						<PersonalTrainingListFunctional />
					</TabPane>
					<TabPane tab={'Months'} key="months">
						<PeriodList />
					</TabPane>
				</Tabs>
			</Col>
		</Row>
	);
};

export default PersonalTrainingList;
