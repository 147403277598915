import React, { useState } from 'react';
import { Col, Input, Modal, Row } from 'antd';
import AnimatedNumber from 'components/animated-number';
import { EyeOutlined } from '@ant-design/icons';

const DashboardCard = ({ span, icon, value, name, description, onClick, withPassword }) => {
	const [isPasswordChecked, setIsPasswordChecked] = useState(false);
	const [password, setPassword] = useState('');
	const [modalVisible, setModalVisible] = useState(false);
	const correctPassword = 'Admin@1234'; // Replace with the actual password

	const handlePasswordSubmit = () => {
		if (password === correctPassword) {
			setIsPasswordChecked(true);
			setModalVisible(false);
		} else {
			alert('Incorrect Password', 'The password you entered is incorrect.');
		}
	};

	return (
		<Col xl={4} md={6}>
			<Row className="dashboard_card" onClick={onClick}>
				<Col span={24}>
					<Row align="middle">
						<Col span={19}>
							<div className="value">
								{withPassword ? (
									<>
										{isPasswordChecked ? (
											<AnimatedNumber value={value} />
										) : (
											<div
												style={{
													color: '#4d7cfe',
												}}>
												<EyeOutlined onClick={() => setModalVisible(true)} />
											</div>
										)}
									</>
								) : (
									<AnimatedNumber value={value} />
								)}
							</div>
							<div className="name">{name}</div>
						</Col>
						<Col span={5}>
							<div className="icon">{icon}</div>
						</Col>
					</Row>
				</Col>
				<Col span={24} className="desc">
					{description}
				</Col>
			</Row>
			<Modal width={'30%'} title="Enter Password" open={modalVisible} onOk={handlePasswordSubmit} onCancel={() => setModalVisible(false)}>
				<Input type="password" placeholder="Enter Password" onChange={(e) => setPassword(e.target.value)} />
			</Modal>
		</Col>
	);
};

export default DashboardCard;
