import React, { useCallback, useState } from 'react';
import { Layout } from 'antd';
import { FloatButton } from 'antd';
import { Outlet } from 'react-router-dom';
import { MdOutlinePayments, MdOutlineShoppingCart } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import AddPayment from 'pages/payments/add-payment';
import { CLOSE_PAYMENT_MODAL, OPEN_PAYMENT_MODAL } from 'redux/reducers/constants';
// import { getApi } from 'redux/sagas/getApiDataSaga';
// import { SERVER_IP } from 'assets/Config';
import Sidebar from './side-bar';
import PageHeader from './PageHeader';
import PageLoader from './page-loader';

const { Content } = Layout;

const PageLayout = () => {
	const dispatch = useDispatch();
	const [paymentAddModal, setPaymentAddModal] = useState(false);

	const handleClose = useCallback(() => {
		setPaymentAddModal(false);
		dispatch({ type: CLOSE_PAYMENT_MODAL });
	}, [setPaymentAddModal, dispatch]);

	const openPaymentModal = (type) => {
		dispatch({ type: OPEN_PAYMENT_MODAL, payload: { type } });
		setPaymentAddModal(true);
	};

	return (
		<Layout style={{ height: '100vh' }}>
			<PageLoader />
			<Sidebar />
			<Layout style={{ overflow: 'auto', height: '100vh' }}>
				<Content>
					<PageHeader />
					<Outlet />
				</Content>
			</Layout>
			<FloatButton.Group trigger="hover" type="primary" style={{ right: 24 }} icon={<MdOutlinePayments />}>
				<FloatButton icon={<MdOutlineShoppingCart />} onClick={() => openPaymentModal('product')} />
				{/* <FloatButton icon={<MdOutlinePeople />} onClick={() => openPaymentModal('membership')} /> */}
			</FloatButton.Group>
			<AddPayment {...{ paymentAddModal, setPaymentAddModal, handleClose }} />
		</Layout>
	);
};

export default PageLayout;
