// import { useEffect, useState } from 'react';
import { ConfigProvider } from 'antd';
import { ToastContainer } from 'react-toastify';
import { Toaster } from 'react-hot-toast';
import NetworkDetect from 'components/network-detect';
// import { getMessaging, onMessage } from 'firebase/messaging';
// import { showToast } from 'helpers';
// import { firebaseApp, getFirebaseToken } from './firebase';
import Routes from './routes/Routes';
import 'antd/dist/reset.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-toastify/dist/ReactToastify.css';
import './css/theme.scss';
import './css/custom1.scss';
import './style.scss';
import './css/antd.css';
// import './css/custom.scss';

// const messaging = getMessaging(firebaseApp);

function App() {
	// const [showNotificationBanner, setShowNotificationBanner] = useState(Notification.permission === 'default');
	// console.log('🚀 ~ file: App.js:19 ~ App ~ Notification.permission:', Notification.permission);

	// useEffect(() => {
	// 	if (Notification.permission === 'granted') {
	// 		handleGetFirebaseToken();
	// 	}
	// 	onMessage(messaging, (payload) => {
	// 		const {
	// 			notification: { title, body },
	// 		} = payload;
	// 		console.log("🚀 ~ file: App.js:31 ~ onMessage ~ title, body:", title, body)
	// 		showToast(title, body);
	// 		// toast(<ToastifyNotification title={title} body={body} />);
	// 	});
	// 	// onForegroundMessage()
	// 	// 	.then((payload) => {
	// 	// 		console.log('Received foreground message: ', payload);
	// 	// 		const {
	// 	// 			notification: { title, body },
	// 	// 		} = payload;
	// 	// 		showToast(title, body);
	// 	// 		// toast(<ToastifyNotification title={title} body={body} />);
	// 	// 	})
	// 	// 	.catch((err) => console.log('An error occured while retrieving foreground message. ', err));
	// });

	// const handleGetFirebaseToken = () => {
	// 	getFirebaseToken()
	// 		.then((firebaseToken) => {
	// 			console.log('Firebase token: ', firebaseToken);
	// 			if (firebaseToken) {
	// 				setShowNotificationBanner(false);
	// 			}
	// 		})
	// 		.catch((err) => console.error('An error occured while retrieving firebase token. ', err));
	// };
	return (
		<ConfigProvider
			theme={{
				hashed: false,
				token: {
					// fontFamily: 'Montserrat, sans-serif',
					fontFamily: 'Open Sans, sans-serif',
					colorPrimary: '#4d7cfe',
				},
			}}>
			{/* {showNotificationBanner && (
				<div className="notification-banner">
					<span>The app needs permission to</span>
					<a href="/#" className="notification-banner-link" onClick={handleGetFirebaseToken}>
						{" "}enable push notifications.
					</a>
				</div>
			)} */}
			<Routes />
			<NetworkDetect />
			<Toaster />
			<ToastContainer autoClose={1000} hideProgressBar style={{ width: '400px' }} />
		</ConfigProvider>
	);
}

export default App;
