import React, { useEffect } from 'react';
import { Row, Col, Input, Button, Form, Select, Drawer } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { putApi } from 'redux/sagas/putApiSaga';
import { API_STATUS, USER_TYPE } from 'constants/app-constants';
import { SERVER_IP } from 'assets/Config';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';

const AddUser = ({ userAddModal, width = '40%', editUser, setUserAddModal, refreshList, handleClose }) => {
	const [form] = Form.useForm();
	const globalRedux = useSelector((state) => state.globalRedux);
	const dispatch = useDispatch();

	useEffect(() => {
		if (editUser) {
			form.setFieldsValue({
				accessLevel: editUser?.accessLevel || USER_TYPE[1],
				firstName: editUser?.firstName,
				lastName: editUser?.lastName,
				email: editUser?.email,
				mobile: editUser?.mobile,
			});
		} else {
			form?.resetFields();
		}
	}, [editUser, form]);

	const handleSubmit = (values) => {
		let data = {
			orgId: globalRedux?.selectedBranch?.id,
			accessLevel: values?.accessLevel || USER_TYPE[1],
			firstName: values?.firstName || '',
			lastName: values?.lastName || '',
			email: values?.email || '',
			mobile: values?.mobile || '',
		};

		if (!editUser) {
			dispatch(postApi(data, 'ADD_USER'));
		} else {
			let url = `${SERVER_IP}user/${editUser.id}?orgId=${globalRedux?.selectedBranch?.id}`;
			dispatch(putApi(data, 'EDIT_CUSTOMER', url));
		}
	};

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_USER === 'SUCCESS' || globalRedux.apiStatus.EDIT_CUSTOMER === 'SUCCESS') {
			dispatch(resetApiStatus(editUser ? 'EDIT_CUSTOMER' : 'ADD_USER'));
			refreshList?.();
			handleClose?.();
			form?.resetFields();
		}
	}, [globalRedux.apiStatus, editUser, setUserAddModal, dispatch, refreshList, handleClose, form]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const loading = globalRedux.apiStatus.ADD_USER === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_CUSTOMER === API_STATUS.PENDING;

	return (
		<Drawer
			placement="right"
			title={`${editUser ? 'Edit' : 'New'} User`}
			width={width || '40%'}
			visible={userAddModal}
			closable
			onClose={() => setUserAddModal(false)}
			destroyOnClose={true}>
			<Row>
				<Col span={24}>
					<Row style={{ marginTop: 20 }}>
						<Form
							name="add-user"
							className="required_in_right"
							style={{ width: '100%' }}
							colon={true}
							labelAlign="left"
							form={form}
							onFinish={handleSubmit}
							initialValues={{
								accessLevel: USER_TYPE[1],
							}}
							{...layer1FormCol}>
							<Form.Item
								label="User Type"
								name="accessLevel"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<Select placeholder="select user type">
									{USER_TYPE.map((type) => (
										<Select.Option key={type} value={type}>
											{type}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								label="First Name"
								name="firstName"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<Input />
							</Form.Item>
							<Form.Item
								label="Last Name"
								name="lastName"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<Input />
							</Form.Item>
							<Form.Item
								label="Mobile"
								name="mobile"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<Input />
							</Form.Item>
							<Form.Item label="Email" name="email">
								<Input />
							</Form.Item>
							<Form.Item
								wrapperCol={{
									offset: 0,
									span: 24,
								}}>
								<Row className="space-between" style={{ paddingTop: 20, width: '100%', margin: 0 }}>
									<Button danger style={{ width: '49%' }} onClick={() => setUserAddModal(false)}>
										Cancel
									</Button>
									<Button loading={loading} style={{ width: '49%' }} type="primary" htmlType="submit">
										{editUser ? 'Update' : 'Save'}
									</Button>
								</Row>
							</Form.Item>
						</Form>
					</Row>
				</Col>
			</Row>
		</Drawer>
	);
};

export default AddUser;
