import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { SERVER_IP } from 'assets/Config';
import HighlightComponent from 'components/HighlightComponent';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { formQueryStringFromObject, generatePagination } from 'helpers';
import { API_STATUS, DATE_FORMAT } from 'constants/app-constants';
import PaymentReportListPresentational from './payment-report-list-presentational';

const initialPageSize = 10;
const initialPageSizeOptions = [10, 15, 20];

const PaymentReportListFunctional = React.memo(() => {
	const [filterOptions, setFilterOptions] = useState({
		fromDate: '',
		toDate: '',
	});
	const paymentReportRedux = useSelector((state) => state.paymentReportRedux);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [searchKey, setSearchKey] = useState('');
	const [tableData, setTableData] = useState(paymentReportRedux.paymentReports);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const dispatch = useDispatch();

	const getReports = useCallback(() => {
		const string = formQueryStringFromObject({
			branchId: globalRedux?.selectedBranch?.id,
			...filterOptions,
			type: 'payment',
		});
		let url = `${SERVER_IP}api/report?${string}`;
		dispatch(getApi('GET_PAYMENT_REPORTS', url));
	}, [dispatch, globalRedux?.selectedBranch?.id, filterOptions]);

	useEffect(() => {
		getReports();
	}, [getReports, filterOptions]);

	useEffect(() => {
		generatePagination(tableData);
	}, [tableData]);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_COACH === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_COACH'));
			setSelectedRowKeys([]);
			doIt = true;
		}
		if (doIt) {
			getReports();
		}
	}, [globalRedux.apiStatus.DELETE_COACH, dispatch, getReports]);

	useEffect(() => {
		setTableData(paymentReportRedux.paymentReports);
	}, [paymentReportRedux.paymentReports]);

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((report) => {
			return (
				(report?.reportName || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(report?.mobile || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(report?.email || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(report?.address || '')?.toString().toLowerCase().includes(searchKey.toLowerCase())
				// (report?.outstandingBalance || '')?.toString().toLowerCase().includes(searchKey.toLowerCase())
			);
		});
	}, [tableData, searchKey]);

	const handleFilterChange = (key, value) => {
		if (key === 'date') {
			setFilterOptions({
				fromDate: value ? moment(value?.[0]).toISOString() : '',
				toDate: value ? moment(value?.[1]).toISOString() : '',
			});
		}
	};

	const column = useMemo(
		() => [
			{
				title: '#',
				dataIndex: 'paymentNumber',
				key: 'paymentNumber',
				width: '2%',
				render: (value, record, index) => <div>{index + 1}</div>,
			},
			{
				title: 'Customer Name',
				dataIndex: 'customer',
				width: '15%',
				render: (value) => (
					<div style={{ fontWeight: 'bold' }}>
						<HighlightComponent
							highlightClassName="highlightClass"
							searchWords={[searchKey]}
							autoEscape={true}
							textToHighlight={value?.customerName || '-'}
						/>
					</div>
				),
			},
			{
				title: 'Amount',
				dataIndex: 'amount',
				align: 'left',
				width: '10%',
				render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={value?.toString()} />,
			},
			// {
			// 	title: 'Joined on',
			// 	dataIndex: 'joiningDate',
			// 	align: 'left',
			// 	width: '10%',
			// 	render: (value) => (
			// 		<HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={moment(value).format(DATE_FORMAT.DD_MM_YYYY)} />
			// 	),
			// },
			{
				title: 'Paid On',
				dataIndex: 'paidOn',
				align: 'left',
				width: '10%',
				render: (value) => (
					<HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={moment(value).format(DATE_FORMAT.DD_MM_YYYY)} />
				),
			},
			{
				title: 'Received by',
				dataIndex: 'coach_ma',
				align: 'left',
				width: '10%',
				render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={value?.coachName} />,
			},
			{
				title: 'Pay. Meth.',
				dataIndex: 'paymentMethod',
				// fixed: 'left',
				width: '10%',
				render: (value) => (
					<div style={{ fontWeight: 'bold' }}>
						<HighlightComponent
							highlightClassName="highlightClass"
							searchWords={[searchKey]}
							autoEscape={true}
							textToHighlight={value || '-'}
						/>
					</div>
				),
			},
			{
				title: 'UPI #',
				dataIndex: 'upiTransactionId',
				align: 'left',
				width: '10%',
				render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={(value || '-')?.toString()} />,
			},
			{
				title: 'Remarks',
				dataIndex: 'remarks',
				align: 'left',
				width: '15%',
				render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={(value || '-')?.toString()} />,
			},
			// {
			// 	title: 'Action',
			// 	align: 'center',
			// 	dataIndex: 'paymentName',
			// 	render: (value, row, index) => {
			// 		return (
			// 			<Row justify="center">
			// 				<Col className="edit_icon" onClick={() => handleDrawer(row)}>
			// 					<EditOutlined />
			// 				</Col>
			// 			</Row>
			// 		);
			// 	},
			// },
		],
		[searchKey]
	);

	const exportData = useMemo(() => {
		return {
			headers: column?.map((col) => col?.title) || [],
			data:
				filteredData.map((stock, index) => ({
					[column[0]?.title]: index + 1,
					[column[1]?.title]: stock?.customerId?.customerName,
					[column[2]?.title]: stock?.amount,
					[column[3]?.title]: moment(stock?.paidOn).format(DATE_FORMAT.DD_MM_YYYY),
					[column[4]?.title]: stock?.receivedBy?.coachName,
					[column[5]?.title]: stock?.paymentMethod,
					[column[6]?.title]: stock?.upiTransactionId,
					[column[7]?.title]: stock?.remarks,
				})) || [],
		};
	}, [filteredData, column]);

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_PAYMENT_REPORTS === API_STATUS.PENDING, [globalRedux.apiStatus]);

	// const onSelectChange = (selectedRowKeys) => {
	// 	setSelectedRowKeys(selectedRowKeys);
	// };

	// const rowSelection = {
	// 	selectedRowKeys,
	// 	onChange: onSelectChange,
	// 	// fixed: true,
	// };

	return (
		<PaymentReportListPresentational
			{...{
				filteredData,
				column,
				tableLoading,
				// rowSelection,
				selectedRowKeys,
				currentPage,
				pageSize,
				initialPageSizeOptions,
				initialPageSize,
				handleTableChange,
				setSearchKey,
				getStartingValue,
				getEndingValue,
				handleFilterChange,
				exportData,
			}}
		/>
	);
});

export default PaymentReportListFunctional;
