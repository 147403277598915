
function setCustomerReports(data){
    return{
        type : "SET_CUSTOMER_REPORTS",
        payload : data
    }
}

export {  
    setCustomerReports
}