import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Button, Form, Drawer, InputNumber } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { putApi } from 'redux/sagas/putApiSaga';
import { API_STATUS } from 'constants/app-constants';
import { SERVER_IP } from 'assets/Config';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import FileUpload from 'components/file-upload/file-upload';

const AddProduct = ({ productAddModal, width = '40%', editProduct, setProductAddModal, refreshList, handleClose }) => {
	const [form] = Form.useForm();
	const globalRedux = useSelector((state) => state.globalRedux);
	const branchId = globalRedux?.selectedBranch?.id;
	const [productImages, setProductImages] = useState([]);
	const dispatch = useDispatch();

	useEffect(() => {
		setProductImages([]);
		if (editProduct) {
			const productImage = JSON.parse(JSON.stringify(editProduct));
			if (productImage?.productImages?.length > 0) {
				for (let i = 0; i < productImage?.productImages?.length; i++) {
					productImage.productImages[i] =
						typeof productImage.productImages[i] === 'string' ? JSON.parse(productImage.productImages[i]) : productImage.productImages[i];
					productImage.productImages[i].url = String(productImage.productImages[i].url).includes(SERVER_IP)
						? productImage.productImages[i].url
						: SERVER_IP + productImage.productImages[i].url;
				}
			}
			form.setFieldsValue({
				productName: editProduct?.productName || '',
				countInStock: editProduct?.countInStock || '',
				originalPrice: editProduct?.originalPrice || '',
				offerPrice: editProduct?.offerPrice || '',
				productImages: productImage?.productImages || [],
			});
			if (productImage?.productImages?.length > 0) {
				setProductImages(productImage?.productImages);
			}
		} else {
			form?.resetFields();
		}
	}, [editProduct, form]);

	// useEffect(() => {
	// 	form.setFieldValue('productImages', productImages)
	// }, [productImages, form])

	const handleSubmit = (values) => {
		let images = [];
		if (productImages.length) {
			productImages.forEach((image) => {
				images.push({
					thumbUrl: image?.thumbUrl,
					uid: image?.uid,
					name: image?.name,
					url: image?.url ? image?.url?.replace(SERVER_IP, '') : null,
				});
			});
		}
		let data = {
			productName: values?.productName || '',
			countInStock: values?.countInStock || '',
			originalPrice: values?.originalPrice || '',
			offerPrice: values?.offerPrice || '',
			productImages: images || [],
			branchId,
		};

		if (!editProduct) {
			dispatch(postApi(data, 'ADD_PRODUCT'));
		} else {
			let url = `${SERVER_IP}api/products/${editProduct.id}`;
			dispatch(putApi(data, 'EDIT_PRODUCT', url));
		}
	};

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_PRODUCT === 'SUCCESS' || globalRedux.apiStatus.EDIT_PRODUCT === 'SUCCESS') {
			dispatch(resetApiStatus(editProduct ? 'EDIT_PRODUCT' : 'ADD_PRODUCT'));
			refreshList?.();
			handleClose?.();
			form?.resetFields();
		}
	}, [globalRedux.apiStatus, editProduct, setProductAddModal, dispatch, refreshList, handleClose, form]);

	const layer1FormCol = {
		labelCol: {
			span: 8,
		},
		wrapperCol: {
			span: 16,
		},
	};

	const onClose = () => {
		setProductImages([]);
		setProductAddModal(false);
	};

	const loading = globalRedux.apiStatus.ADD_PRODUCT === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_PRODUCT === API_STATUS.PENDING;

	return (
		<Drawer
			placement="right"
			title={`${editProduct ? 'Edit' : 'New'} Product`}
			width={width || '30%'}
			visible={productAddModal}
			closable
			onClose={onClose}
			destroyOnClose={true}>
			<Row>
				<Col span={24}>
					{/* <Row style={{ backgroundColor: '#fff', padding: '10px 0px' }}>
						<Col>
							<h6 style={{ marginBottom: '0px' }}>{editProduct ? 'Edit' : 'New'} Product</h6>
						</Col>
					</Row>
					<Divider /> */}
					<Row style={{ marginTop: 20 }}>
						<Form
							name="add-product"
							className="required_in_right"
							style={{ width: '100%' }}
							colon={true}
							labelAlign="left"
							form={form}
							onFinish={handleSubmit}
							initialValues={{}}
							{...layer1FormCol}>
							<Form.Item
								label="Product Name"
								name="productName"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<Input placeholder="Product name" />
							</Form.Item>
							<Form.Item
								label="Count In Stock"
								name="countInStock"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<InputNumber style={{ width: '100%' }} placeholder="In Stock" />
							</Form.Item>
							<Form.Item
								label="Original Price"
								name="originalPrice"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<InputNumber style={{ width: '100%' }} placeholder="Original Price" />
							</Form.Item>
							<Form.Item
								label="Offer Price"
								name="offerPrice"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<InputNumber style={{ width: '100%' }} placeholder="Offer Price" />
							</Form.Item>
							{/* {!editProduct && ( */}
							<FileUpload
								afterUpload={setProductImages}
								max={1}
								fileList={form.getFieldValue('productImages')}
								setFileList={setProductImages}
								action={'/'}
							/>
							{/* )} */}
							<Form.Item
								wrapperCol={{
									offset: 0,
									span: 24,
								}}>
								<Row className="space-between" style={{ paddingTop: 20, width: '100%', margin: 0 }}>
									<Button danger style={{ width: '49%' }} onClick={onClose}>
										Cancel
									</Button>
									<Button loading={loading} style={{ width: '49%' }} type="primary" htmlType="submit">
										{editProduct ? 'Update' : 'Save'}
									</Button>
								</Row>
							</Form.Item>
						</Form>
					</Row>
				</Col>
			</Row>
		</Drawer>
	);
};

export default AddProduct;
