import { CLOSE_PAYMENT_MODAL, OPEN_PAYMENT_MODAL, SET_EDIT_PAYMENT, SET_PAYMENTS } from '../constants';

let initialState = {
	payments: [],
	paymentModalOpen: false,
	paymentFor: '',
	editPayment: {},
	paginationDetails: {}
};

const paymentReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_PAYMENTS:
			return {
				...state,
				payments: action.payload,
			};
		case OPEN_PAYMENT_MODAL:
			return {
				...state,
				paymentModalOpen: true,
				paymentFor: action.payload.type,
			};
		case CLOSE_PAYMENT_MODAL:
			return {
				...state,
				paymentModalOpen: false,
				paymentFor: null,
			};
		case SET_EDIT_PAYMENT:
			return {
				...state,
				editPayment: action.payload,
			};
		case 'SET_PAYMENTS_PAGINATION': 
			return {
				...state,
				paginationDetails: action.payload,
			};
		default:
			return state;
	}
};

export { paymentReducer };
