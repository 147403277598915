import React, { useCallback, useEffect, useMemo, useState } from 'react';
// import moment from 'moment';
import { Row, Col, Button, Image, Tooltip } from 'antd';
import { isTablet } from 'react-device-detect';
import { EditOutlined, InfoCircleFilled, WalletOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { SERVER_IP } from 'assets/Config';
import HighlightComponent from 'components/HighlightComponent';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { convertToIndianRupees, formQueryStringFromObject, generatePaginationForServerSide, getWhatsAppUrl } from 'helpers';
import { API_STATUS, DATE_FORMAT } from 'constants/app-constants';
import { getDateFormat, getDifferenceDays } from 'services/Utils';
import MaleSVG from '../../../../assets/images/male.svg';
import FemaleSVG from '../../../../assets/images/female.svg';
import CustomerListPresentational from './customer-list-presentational';
import CustomerDetails from 'pages/customers/customer-details';

const initialPageSize = 10;
const initialPageSizeOptions = [10, 20, 40, 60];

// export const getRowColor = (nextBillDate) => {
// 	const currentDate = new Date();
// 	const nextBillDateObj = new Date(nextBillDate);
// 	const daysDifference = (nextBillDateObj - currentDate) / (1000 * 3600 * 24);

// 	if (daysDifference > 30) {
// 		return 'moreThanThirtyDays';
// 		// return '#fff'; // Green for dates more than 30 days away
// 	} else if (daysDifference > 0) {
// 		return 'moreThanThirtyDays';
// 		// return '#fadb14'; // Yellow for dates within 30 days
// 	} else {
// 		return 'expired';
// 		// return 'rgb(255 154 155)'; // Red for expired dates
// 	}
// };

export const getRowColor = (nextBillDate) => {
	const currentDate = new Date();
	const nextBillDateObj = new Date(nextBillDate);
	const daysDifference = (nextBillDateObj - currentDate) / (1000 * 3600 * 24);

	if (daysDifference > 30) {
		return 'moreThanThirtyDays';
		// return '#fff'; // Green for dates more than 30 days away
	} else if (daysDifference > 0 && daysDifference < 6) {
		return 'lessThanThirtyDays';
		// return '#fadb14'; // Yellow for dates within 10 days
	} else if (daysDifference > 0) {
		return 'moreThanThirtyDays';
		// return '#fff'; // Green for dates within 30 days but not less than 10
	} else {
		return 'expired';
		// return 'rgb(255 154 155)'; // Red for expired dates
	}
};

const CustomerListFunctional = React.memo(({ isFemale }) => {
	const customerRedux = useSelector((state) => state.customerRedux);
	const [selectedService, setSelectedService] = useState(null);
	const [selectedMembership, setSelectedMembership] = useState(null);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const branchId = globalRedux?.selectedBranch?.id;
	const dispatch = useDispatch();
	const [isExpiredCheck, setIsExpiredCheck] = useState(false);
	const [isInActiveCheck, setIsInActiveCheck] = useState(false);

	const [customerAddModal, setCustomerAddModal] = useState(false);
	const [payNowAddModal, setPayNowAddModal] = useState(false);
	const [searchKey, setSearchKey] = useState('');
	const [debouncedSearchKey, setDebouncedSearchKey] = useState(searchKey);
	const [tableData, setTableData] = useState(() => customerRedux?.customers);
	const [editCustomer, setEditCustomer] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const [paginationDetails, setPaginationDetails] = useState(customerRedux?.paginationDetails);
	const services = useSelector((state) => state?.serviceRedux?.services || []);
	const memberships = useSelector((state) => state?.membershipRedux?.memberships || []);
	const [viewDetails, setViewDetails] = useState(false);
	const [selectedCustomerId, setSelectedCustomerId] = useState(null);
	const [prevString, setPrevString] = useState(null);
	const [sortBy, setSortBy] = useState('updated_at');
	const [sortByOrder, setSortByOrder] = useState('DESC');

	const getString = useCallback(() => formQueryStringFromObject({
			branchId,
			gender: isFemale ? 'female' : 'male',
			...(isExpiredCheck && { isExpired: isExpiredCheck }),
			isActive: !isInActiveCheck,
			serviceId: selectedService,
			membershipId: selectedMembership,
			limit: pageSize,
			offset: (currentPage - 1) * pageSize,
			sortBy,
			sortByOrder,
			searchKey: debouncedSearchKey
	}), [branchId, currentPage, isExpiredCheck, isFemale, isInActiveCheck, pageSize, selectedMembership, selectedService, sortBy, sortByOrder, debouncedSearchKey]);


	const getCustomers = useCallback((forceReload = false) => {
		const string = getString();
		if (forceReload || string !== prevString) {
			let url = `${SERVER_IP}api/customers?${string}`;
			branchId && dispatch(getApi('GET_CUSTOMERS', url));
			setPrevString(string);
		}
	}, [getString, prevString, branchId, dispatch]);

	useEffect(() => {
		getCustomers();
	}, [getCustomers, debouncedSearchKey]);

	const getServices = useCallback(() => {
		const string = formQueryStringFromObject({
			branchId,
		});
		let serviceUrl = `${SERVER_IP}api/services?${string}`;
		dispatch(getApi('GET_SERVICES', serviceUrl));
	}, [dispatch, branchId]);

	const getMembership = useCallback(() => {
		const string = formQueryStringFromObject({
			branchId,
			serviceId: selectedService,
		});
		let membershipUrl = `${SERVER_IP}api/memberships?${string}`;
		dispatch(getApi('GET_MEMBERSHIPS', membershipUrl));
	}, [dispatch, branchId, selectedService]);

	useEffect(() => {
		getMembership();
	}, [selectedService, getMembership]);

	useEffect(() => {
		getServices();
	}, [getServices]);

	useEffect(() => {
		generatePaginationForServerSide(paginationDetails);
		// generatePagination(tableData);
	}, [paginationDetails]);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_CUSTOMER === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_CUSTOMER'));
			setSelectedRowKeys([]);
			doIt = true;
		}
		if (doIt) {
			getCustomers(true);
		}
	}, [globalRedux.apiStatus.DELETE_CUSTOMER, dispatch, getCustomers]);

	useEffect(() => {
		setTableData(customerRedux?.customers);
	}, [customerRedux.customers]);

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}

		return tableData.filter((customer) => {
			return (
				(customer?.customerName || '')?.toLowerCase().includes(searchKey.toLowerCase().trim()) ||
				(customer?.mobile || '')?.toLowerCase().includes(searchKey.toLowerCase().trim()) ||
				(customer?.email || '')?.toLowerCase().includes(searchKey.toLowerCase().trim()) ||
				(customer?.address || '')?.toString().toLowerCase().includes(searchKey.toLowerCase().trim())
				// (customer?.outstandingBalance || '')?.toString().toLowerCase().includes(searchKey.toLowerCase().trim())
			);
		});
	}, [tableData, searchKey]);

	const handleDrawer = (rowData) => {
		setEditCustomer(rowData);
		setCustomerAddModal(true);
	};

	useEffect(() => {
		setPaginationDetails(customerRedux?.paginationDetails)
	}, [customerRedux?.paginationDetails])

	const handlePay = (rowData) => {
		setEditCustomer(rowData);
		setPayNowAddModal(true);
	};

	const handleAddCustomer = () => {
		setEditCustomer(null);
		setCustomerAddModal(true);
	};

	const viewCustomerDetails = (row) => {
		setViewDetails(true);
		setSelectedCustomerId(row.id);
	};

	// const excelColumn = useMemo(
	// 	() => [
	// 		{
	// 			title: '#',
	// 			dataIndex: 'customerNumber',
	// 		},
	// 		{
	// 			title: 'Joined On',
	// 			dataIndex: 'joiningDate',
	// 		},
	// 		{
	// 			title: 'Name',
	// 			dataIndex: 'customerName',
	// 		},
	// 		{
	// 			title: 'Mobile',
	// 			dataIndex: 'mobile',
	// 		},
	// 		{
	// 			title: 'Aadhaar',
	// 			dataIndex: 'aadhaarNo',
	// 		},
	// 		{
	// 			title: 'Service Type',
	// 			dataIndex: 'payments',
	// 		},
	// 		{
	// 			title: 'Membership',
	// 			dataIndex: 'payments',
	// 		},
	// 		{
	// 			title: 'Email',
	// 			dataIndex: 'email',
	// 		},
	// 		{
	// 			title: 'DOB',
	// 			dataIndex: 'dob',
	// 		},
	// 		{
	// 			title: 'Age',
	// 			dataIndex: 'age',
	// 		},
	// 		{
	// 			title: 'Address',
	// 			dataIndex: 'address',
	// 		},
	// 		{
	// 			title: 'On Boarded By',
	// 			dataIndex: 'coach',
	// 		},
	// 		{
	// 			title: 'Balance',
	// 			dataIndex: 'payments',
	// 		},
	// 		{
	// 			title: 'Payment Method',
	// 			dataIndex: 'payments',
	// 		},
	// 		{
	// 			title: 'Next Bill',
	// 			dataIndex: 'payments',
	// 			// render: (value) => (value?.[0]?.nextBillDate ? getDateFormat(value?.[0]?.nextBillDate, DATE_FORMAT.DD_MM_YYYY) : '-'),
	// 		},
	// 	],
	// 	[]
	// );

	const getStartingValue = useCallback(() => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	}, [currentPage, pageSize]);

	const getEndingValue = () => {
		if (currentPage === 1) return paginationDetails.totalRecords < pageSize ? paginationDetails.totalRecords : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > paginationDetails.totalRecords ? paginationDetails.totalRecords : end;
		}
	};

	// const setTableLoading = useCallback(() => {
	// 	// dispatch(setApiStatus('GET_CUSTOMERS', API_STATUS.PENDING));
	// 	setTableData([]);
	// }, []);

	const setSorter = useCallback((sortBy, order) => {
		setSortBy(sortBy);
		setSortByOrder(order === "descend" ? "DESC" : "ASC");
		getCustomers();
	}, [getCustomers]);


	useEffect(() => {
		// dispatch(setApiStatus("GET_CUSTOMERS", API_STATUS.PENDING));
		const handler = setTimeout(() => {
			// setTableLoading();
			setDebouncedSearchKey(searchKey);
		}, 500);
	
		// Cleanup the timeout if the component re-renders before the timeout is complete
		return () => {
			clearTimeout(handler);
		};
	}, [searchKey]);

	const onTableChange = (pagination, filters, sorter) => {
		setSorter(sorter.field, sorter.order);
	};


	const column = useMemo(
		() => [
			{
				title: '#',
				dataIndex: 'customerNumber',
				key: 'customerNumber',
				width: 50,
				fixed: 'left',
				render: (value, record, index) => <div>{getStartingValue() + index}</div>,
			},
			{
				title: 'Pic',
				dataIndex: 'customerName',
				key: 'customerName',
				width: 50,
				fixed: 'left',
				render: (value, record) => {
					return (
						<Row align={'middle'}>
							<Col
								style={{
									paddingRight: '10px',
								}}>
								{record?.image?.[0]?.url ? (
									<Image
										src={SERVER_IP + record?.image?.[0]?.url}
										alt="Avatar"
										style={{ width: '40px', height: '40px', borderRadius: 5 }}
									/>
								) : (
									<img
										src={record.gender === 'male' ? MaleSVG : FemaleSVG}
										alt="Avatar"
										style={{ width: '40px', height: '40px', borderRadius: 5 }}
									/>
								)}
							</Col>
						</Row>
					);
				},
			},
			{
				title: 'Name',
				dataIndex: 'customerName',
				key: 'customerName',
				// sorter: (a, b) => a?.customerName?.localeCompare(b?.customerName),
				// sorter: (a,b, order) => setSorter('customerName', order),
				sorter: true,
				fixed: 'left',
				width: isTablet ? 175 : 200,
				render: (value, record) => {
					return (
						<Row align={'middle'}>
							<Col
								style={{
									paddingRight: '10px',
								}}>
								<Tooltip title="View Details">
									<div
										onClick={() => viewCustomerDetails(record)}
										style={{
											color: '#4d7cfe',
											cursor: 'pointer',
										}}>
										<InfoCircleFilled />
									</div>
									{/* <Button type="primary" onClick={() => viewCustomerDetails(record)} shape="circle" icon={} /> */}
								</Tooltip>
							</Col>
							<div style={{ fontWeight: 'bold' }}>
								<HighlightComponent
									highlightClassName="highlightClass"
									searchWords={[searchKey.trim()]}
									autoEscape={true}
									textToHighlight={value || '-'}
								/>
							</div>
							{/* <Button onClick={() => viewCustomerDetails(record)}>View Details</Button> */}
						</Row>
					);
				},
			},
			{
				title: 'Joined On',
				dataIndex: 'joiningDate',
				align: 'left',
				...(!isTablet && {
					fixed: 'left',
				}),
				width: 120,
				sorter: true,
				render: (value) => getDateFormat(value, DATE_FORMAT.DD_MM_YYYY),
				// sorter: (a, b) => new Date(a.joiningDate) - new Date(b.joiningDate),
				// sorter: () => setSorter('joiningDate'),
			},
			{
				title: 'Mobile',
				dataIndex: 'mobile',
				align: 'left',
				width: 100,
				...(!isTablet && {
					fixed: 'left',
				}),
				render: (value, record) => (
					// eslint-disable-next-line
					<div
						className="link"
						onClick={() => {
							window.open(
								getWhatsAppUrl(
									value,
									`
Hi *${record?.customerName}*! 

You've made fantastic progress. Your membership expired. 

Let's keep the momentum going—renew now!

from *${globalRedux?.selectedBranch?.branchName}* Team
`
								)
							);
						}}
						style={{ fontWeight: 'bold' }}
						// href={getWhatsAppUrl(
						// 	value,
						// 	`Hi ${record?.customerName}! You've made fantastic progress. Your membership expired. Let's keep the momentum going—renew now!`
						// )}
						// rel="nofollow noopener"
						// target="_blank"
					>
						<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey.trim()]} autoEscape={true} textToHighlight={value} />
					</div>
				),
				// render: (value) => <HighlightComponent searchWords={[searchKey.trim()]} autoEscape={true} textToHighlight={value} />,
			},
			{
				title: 'Aadhaar',
				dataIndex: 'aadhaarNo',
				align: 'left',
				width: 150,
				render: (value) => <HighlightComponent searchWords={[searchKey.trim()]} autoEscape={true} textToHighlight={value} />,
			},
			{
				title: 'Service Type',
				dataIndex: 'membership',
				align: 'left',
				width: 150,
				render: (value) => (
					<HighlightComponent searchWords={[searchKey.trim()]} autoEscape={true} textToHighlight={value?.service?.serviceName || '-'} />
				),
			},
			{
				title: 'Membership',
				dataIndex: 'membership',
				align: 'left',
				width: 150,
				render: (value) =>
					(value?.membership?.membershipName || '') && (
						<span className="membership-button">{value?.membership?.membershipName || '-'}</span>
					),
			},
			{
				title: 'Email',
				dataIndex: 'email',
				sorter: true,
				// sorter: (a, b) => a?.email?.localeCompare(b?.email),
				// sorter: () => setSorter('email'),
				align: 'left',
				width: 150,
				render: (value) => <HighlightComponent searchWords={[searchKey.trim()]} autoEscape={true} textToHighlight={value} />,
			},
			{
				title: 'DOB',
				dataIndex: 'dob',
				align: 'left',
				width: 100,
				render: (value) => getDateFormat(value, DATE_FORMAT.DD_MM_YYYY),
			},
			{
				title: 'Age',
				dataIndex: 'age',
				align: 'left',
				width: 100,
			},
			{
				title: 'Address',
				dataIndex: 'address',
				sorter: true,
				// sorter: (a, b) => a?.address?.localeCompare(b?.address),
				// sorter: () => setSorter('address'),
				align: 'left',
				width: 150,
				render: (value) => <HighlightComponent searchWords={[searchKey.trim()]} autoEscape={true} textToHighlight={value || '-'} />,
			},
			{
				title: 'On Boarded By',
				dataIndex: 'coach',
				width: 100,
				render: (value) => value?.coachName || ' - ',
			},
			{
				title: 'Balance',
				dataIndex: 'balance',
				align: 'right',
				width: 100,
				render: (value) =>
					value ? (
						<div
							style={{
								color: 'red',
								fontWeight: 'bold',
							}}>
							{convertToIndianRupees(value)}
						</div>
					) : (
						' - '
					),
			},
			{
				title: 'Next Bill',
				dataIndex: 'nextBillDate',
				align: 'left',
				width: 100,
				fixed: 'right',
				sorter: true,
				render: (value) => (value ? getDateFormat(value, DATE_FORMAT.DD_MM_YYYY) : '-'),
				// sorter: (a, b) => new Date(a.nextBillDate) - new Date(b.nextBillDate),
				// sorter: () => setSorter('nextBillDate'),
			},
			{
				title: 'Action',
				align: 'left',
				width: 100,
				fixed: 'right',
				dataIndex: 'membership',
				render: (value, row) => {
					let showPayNow = false;
					let nextBillDate = row.membership?.nextBillDate;

					if (getDifferenceDays(new Date(nextBillDate), new Date()) <= 6) {
						showPayNow = true;
					}

					return (
						<Row justify="start">
							<Col className="edit_icon" onClick={() => handleDrawer(row)}>
								<EditOutlined />
							</Col>
							{showPayNow && (
								<Col>
									<Button className="edit_icon" style={{ backgroundColor: '#3cc13b' }} onClick={() => handlePay(row)}>
										<WalletOutlined />
									</Button>
								</Col>
							)}
						</Row>
					);
				},
			},
		],
		[getStartingValue, searchKey, globalRedux?.selectedBranch?.branchName]
	);

	const handlePaginationChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage);
		setPageSize(pageSize);
		// setTableLoading();
		setPaginationDetails({
			...paginationDetails,
			currentPage,
			pageSize,
		});

		getCustomers();
	};

	const tableLoading = useMemo(
		() => globalRedux.apiStatus.GET_CUSTOMERS === API_STATUS.PENDING || globalRedux.apiStatus.DELETE_CUSTOMER === API_STATUS.PENDING,
		[globalRedux.apiStatus]
	);

	const onSelectChange = (selectedRowKeys) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		fixed: true,
		selectedRowKeys,
		onChange: onSelectChange,
		// fixed: true,
	};

	const onCloseCustomerDetails = () => {
		setViewDetails(false);
		setSelectedCustomerId(null);
		getCustomers(true);
	};

	const handleClose = useCallback(() => {
		setCustomerAddModal(false);
		setPayNowAddModal(false);
		setEditCustomer(null);
	}, [setCustomerAddModal, setEditCustomer]);

	const exportData = [];
	// const exportData = useMemo(() => {
	// 	return {
	// 		headers: excelColumn?.filter((col) => col?.title !== 'Action').map((col) => col?.title) || [],
	// 		data:
	// 			filteredData.map((stock, index) => ({
	// 				[excelColumn[0]?.title]: index + 1,
	// 				[excelColumn[1]?.title]: moment(stock?.joiningDate).format(DATE_FORMAT.DD_MM_YYYY),
	// 				[excelColumn[2]?.title]: stock?.customerName,
	// 				[excelColumn[3]?.title]: stock?.mobile,
	// 				[excelColumn[4]?.title]: stock?.aadhaarNo || '-',
	// 				[excelColumn[5]?.title]: stock?.serviceId || '-',
	// 				[excelColumn[6]?.title]: stock?.membershipId || '-',
	// 				// [excelColumn[5]?.title]: stock?.payments?.[0]?.service?.serviceName || '-',
	// 				// [excelColumn[6]?.title]: stock?.payments?.[0]?.membership?.membershipName || '-',
	// 				[excelColumn[7]?.title]: stock?.email || '-',
	// 				[excelColumn[8]?.title]: stock?.dob ? moment(stock?.dob).format(DATE_FORMAT.DD_MM_YYYY) : '-',
	// 				[excelColumn[9]?.title]: stock?.age || '-',
	// 				[excelColumn[10]?.title]: stock?.address || '-',
	// 				[excelColumn[11]?.title]: stock?.coach?.coachName || '-',
	// 				[excelColumn[12]?.title]: stock?.payments?.[0]?.balance || '-',
	// 				[excelColumn[13]?.title]: stock?.payments?.[0]?.paymentMethod || '-',
	// 				[excelColumn[14]?.title]: stock?.payments?.[0]?.nextBillDate
	// 					? moment(stock?.payments?.[0]?.nextBillDate).format(DATE_FORMAT.DD_MM_YYYY)
	// 					: '-',

	// 				// [column[1]?.title]: stock?.customerName,
	// 				// [column[2]?.title]: stock?.mobile,
	// 				// [column[3]?.title]: stock?.email,
	// 				// [column[4]?.title]: stock?.address,
	// 				// [column[5]?.title]: moment(stock?.joiningDate).format(DATE_FORMAT.DD_MM_YYYY),
	// 				// [column[6]?.title]: stock?.onBoardedBy?.coachName,
	// 				// [column[7]?.title]: moment(stock?.paymentId?.nextBillDate).format(DATE_FORMAT.DD_MM_YYYY),
	// 				// [column[8]?.title]: moment(stock?.created_at).format(DATE_FORMAT.DD_MM_YYYY),
	// 			})) || [],
	// 	};
	// }, [filteredData, excelColumn]);
	// console.log("🚀 ~ CustomerListFunctional ~ setCustomerAddModal:", exportData)

	return (
		<>
			<CustomerListPresentational
				{...{
					filteredData,
					column,
					tableLoading,
					rowSelection,
					selectedRowKeys,
					handleAddCustomer,
					currentPage,
					pageSize,
					initialPageSizeOptions,
					initialPageSize,
					handlePaginationChange,
					paginationDetails,
					setSearchKey,
					getStartingValue,
					getEndingValue,
					customerAddModal,
					setCustomerAddModal,
					payNowAddModal,
					setPayNowAddModal,
					refreshList: getCustomers,
					editCustomer,
					handleClose,
					services,
					selectedService,
					setSelectedService,
					memberships,
					selectedMembership,
					setSelectedMembership,
					setIsExpiredCheck,
					isExpiredCheck,
					isInActiveCheck,
					setIsInActiveCheck,
					exportData,
					onTableChange
				}}
			/>
			<CustomerDetails id={selectedCustomerId} viewDetails={viewDetails} onCloseCustomerDetails={onCloseCustomerDetails} />
		</>
	);
});

export default CustomerListFunctional;
