import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ProfilePresentational from './profile-presentational';

const getSrcFromFile = (file) => {
	return new Promise((resolve) => {
		const reader = new FileReader();
		reader.readAsDataURL(file.originFileObj);
		reader.onload = () => resolve(reader.result);
	});
};

const ProfileFunctional = () => {
	const userDetails = useSelector((state) => state.loginRedux);
	const [fileList,] = useState([
		{
			uid: '-1',
			url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
		},
	]);

	const onChange = (e) => {
		console.log("🚀 ~ file: profile-functional.js:23 ~ onChange ~ e:", e)
		
	};
	// const onChange = ({ fileList: newFileList }) => {
	// 	setFileList(newFileList);
	// };

	const onPreview = async (file) => {
		const src = file.url || (await getSrcFromFile(file));
		const imgWindow = window.open(src);

		if (imgWindow) {
			const image = new Image();
			image.src = src;
			imgWindow.document.write(image.outerHTML);
		} else {
			window.location.href = src;
		}
	};
	return <ProfilePresentational {...{ userDetails, fileList, onChange, onPreview }} />;
};

export default ProfileFunctional;
