import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col } from 'antd';
import moment from 'moment';
import { EditOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { SERVER_IP } from 'assets/Config';
import HighlightComponent from 'components/HighlightComponent';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { formQueryStringFromObject, generatePagination } from 'helpers';
import { API_STATUS, DATE_FORMAT } from 'constants/app-constants';
import CoachListPresentational from './coach-list-presentational';
// import { getDateFormat } from 'services/Utils';

const initialPageSize = 10;
const initialPageSizeOptions = [10, 15, 20];

const CoachListFunctional = React.memo(() => {
	const coachRedux = useSelector((state) => state.coachRedux);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [coachAddModal, setCoachAddModal] = useState(false);
	const [searchKey, setSearchKey] = useState('');
	const [tableData, setTableData] = useState(coachRedux.coaches);
	const [editCoach, setEditCoach] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const dispatch = useDispatch();

	const getCoaches = useCallback(() => {
		const string = formQueryStringFromObject({
			branchId: globalRedux?.selectedBranch?.id,
		});
		let url = `${SERVER_IP}api/coaches?${string}`;
		dispatch(getApi('GET_COACHES', url));
	}, [dispatch, globalRedux?.selectedBranch?.id]);

	useEffect(() => {
		getCoaches();
	}, [getCoaches]);

	useEffect(() => {
		generatePagination(tableData);
	}, [tableData]);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_COACH === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_COACH'));
			setSelectedRowKeys([]);
			doIt = true;
		}
		if (doIt) {
			getCoaches();
		}
	}, [globalRedux.apiStatus.DELETE_COACH, dispatch, getCoaches]);

	useEffect(() => {
		setTableData(coachRedux.coaches);
	}, [coachRedux.coaches]);

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((coach) => {
			return (
				(coach?.coachName || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(coach?.mobile || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(coach?.email || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(coach?.address || '')?.toString().toLowerCase().includes(searchKey.toLowerCase())
				// (coach?.outstandingBalance || '')?.toString().toLowerCase().includes(searchKey.toLowerCase())
			);
		});
	}, [tableData, searchKey]);

	const handleDrawer = (rowData) => {
		setEditCoach(rowData);
		setCoachAddModal(true);
	};

	const handleAddCoach = () => {
		setEditCoach(null);
		setCoachAddModal(true);
	};

	const column = [
		{
			title: '#',
			dataIndex: 'coachNumber',
			key: 'coachNumber',
			// fixed: 'left',
			render: (value, record, index) => <div>{index + 1}</div>,
		},
		{
			title: 'Coach Name',
			dataIndex: 'coachName',
			key: 'coachName',
			sorter: (a, b) => a?.coachName?.localeCompare(b?.coachName),
			// fixed: 'left',
			render: (value) => (
				<div style={{ fontWeight: 'bold' }}>
					<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />
				</div>
			),
		},
		{
			title: 'Mobile',
			dataIndex: 'mobile',
			sorter: (a, b) => a?.mobile - b?.mobile,
			align: 'left',
			render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />,
		},
		{
			title: 'Email',
			dataIndex: 'email',
			sorter: (a, b) => a?.email?.localeCompare(b?.email),
			align: 'left',
			width: '15%',
			render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />,
		},
		{
			title: 'Address',
			dataIndex: 'address',
			sorter: (a, b) => a?.address?.localeCompare(b?.address),
			align: 'left',
			width: '15%',
			render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />,
		},
		{
			title: 'Joined on',
			dataIndex: 'joiningDate',
			align: 'left',
			width: '15%',
			render: (value) => (
				<HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={moment(value).format(DATE_FORMAT.DD_MM_YYYY)} />
			),
		},
		// {
		// 	title: 'Created Date',
		// 	dataIndex: 'created_at',
		// 	align: 'left',
		// 	width: '15%',
		// 	render: (value) => getDateFormat(value),
		// },
		{
			title: 'Action',
			align: 'center',
			dataIndex: 'coachName',
			render: (value, row, index) => {
				return (
					<Row justify="center">
						<Col className="edit_icon" onClick={() => handleDrawer(row)}>
							<EditOutlined />
						</Col>
					</Row>
				);
			},
		},
	];

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_COACHES === API_STATUS.PENDING, [globalRedux.apiStatus]);

	const onSelectChange = (selectedRowKeys) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		// fixed: true,
	};

	const handleClose = useCallback(() => {
		setCoachAddModal(false);
		setEditCoach(null);
	}, [setCoachAddModal, setEditCoach]);

	return (
		<CoachListPresentational
			{...{
				filteredData,
				column,
				tableLoading,
				rowSelection,
				selectedRowKeys,
				handleAddCoach,
				currentPage,
				pageSize,
				initialPageSizeOptions,
				initialPageSize,
				handleTableChange,
				setSearchKey,
				getStartingValue,
				getEndingValue,
				coachAddModal,
				setCoachAddModal,
				refreshList: getCoaches,
				editCoach,
				handleClose,
			}}
		/>
	);
});

export default CoachListFunctional;
