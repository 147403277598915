import React, { useEffect, useCallback } from 'react';
import { Row, Col, Input, Button, Form, Drawer, InputNumber, Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { putApi } from 'redux/sagas/putApiSaga';
import { API_STATUS } from 'constants/app-constants';
import { SERVER_IP } from 'assets/Config';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { formQueryStringFromObject } from 'helpers';

const AddMembership = ({ membershipAddModal, width = '40%', editMembership, setMembershipAddModal, refreshList, handleClose, serviceId }) => {
	const [form] = Form.useForm();
	const globalRedux = useSelector((state) => state.globalRedux);
	const services = useSelector((state) => state?.serviceRedux?.services || []);
	const branchId = globalRedux?.selectedBranch?.id;
	// const serviceIdValue = Form.useWatch('serviceId', form);
	const dispatch = useDispatch();

	const getServices = useCallback((url) => {
		dispatch(getApi('GET_SERVICES', url));
	}, [dispatch]);

	useEffect(() => {
		if(membershipAddModal) {
			const string = formQueryStringFromObject({
				branchId,
			});
			let serviceUrl = `${SERVER_IP}api/services?${string}`;
			getServices(serviceUrl);
		}
		else form.resetFields();
	}, [getServices, membershipAddModal, branchId, form]);

	useEffect(() => {
		if (editMembership) {
			form.setFieldsValue({
				membershipName: editMembership?.membershipName || '',
				serviceId: serviceId || editMembership?.serviceId || '',
				days: editMembership?.days || '',
				originalPrice: editMembership?.originalPrice || '',
				offerPrice: editMembership?.offerPrice || '',
			});
		} else {
			form?.resetFields();
		}
	}, [editMembership, form, serviceId]);

	const handleSubmit = (values) => {
		let data = {
			membershipName: values?.membershipName || '',
			serviceId: serviceId || values?.serviceId || '',
			days: values?.days || '',
			originalPrice: values?.originalPrice || '',
			offerPrice: values?.offerPrice || '',
			branchId,
		};

		if (!editMembership) {
			dispatch(postApi(data, 'ADD_MEMBERSHIP'));
		} else {
			let url = `${SERVER_IP}api/memberships/${editMembership.id}`;
			dispatch(putApi(data, 'EDIT_MEMBERSHIP', url));
		}
	};

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_MEMBERSHIP === 'SUCCESS' || globalRedux.apiStatus.EDIT_MEMBERSHIP === 'SUCCESS') {
			dispatch(resetApiStatus(editMembership ? 'EDIT_MEMBERSHIP' : 'ADD_MEMBERSHIP'));
			refreshList?.();
			handleClose?.();
			form?.resetFields();
		}
	}, [globalRedux.apiStatus, editMembership, setMembershipAddModal, dispatch, refreshList, handleClose, form]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const loading = globalRedux.apiStatus.ADD_MEMBERSHIP === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_MEMBERSHIP === API_STATUS.PENDING;

	return (
		<Drawer
			placement="right"
			title={`${editMembership ? 'Edit' : 'New'} Membership`}
			width={width || '40%'}
			visible={membershipAddModal}
			closable
			onClose={() => setMembershipAddModal(false)}
			destroyOnClose={true}>
			<Row>
				<Col span={24}>
					{/* <Row style={{ backgroundColor: '#fff', padding: '10px 0px' }}>
						<Col>
							<h6 style={{ marginBottom: '0px' }}>{editMembership ? 'Edit' : 'New'} Membership</h6>
						</Col>
					</Row>
					<Divider /> */}
					<Row style={{ marginTop: 20 }}>
						<Form
							name="add-membership"
							className="required_in_right"
							style={{ width: '100%' }}
							colon={true}
							labelAlign="left"
							form={form}
							onFinish={handleSubmit}
							initialValues={{}}
							{...layer1FormCol}>
							{!!!serviceId && <Form.Item
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}
								label="Service type"
								name="serviceId">
								<Select placeholder="Service type">
									{services?.map((service, index) => (
										<Select.Option key={index} value={service._id}>
											{service?.serviceName}
										</Select.Option>
									))}
								</Select>
							</Form.Item>}
							<Form.Item
								label="Membership Name"
								name="membershipName"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<Input placeholder="Membership name" />
							</Form.Item>
							<Form.Item
								label="Days"
								name="days"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<InputNumber style={{ width: '100%' }} placeholder="Days" />
							</Form.Item>
							<Form.Item
								label="Original Price"
								name="originalPrice"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<InputNumber style={{ width: '100%' }} placeholder="Original price" />
							</Form.Item>
							<Form.Item
								label="Offer Price"
								name="offerPrice"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<InputNumber style={{ width: '100%' }} placeholder="Offer price" />
							</Form.Item>
							<Form.Item
								wrapperCol={{
									offset: 0,
									span: 24,
								}}>
								<Row className="space-between" style={{ paddingTop: 20, width: '100%', margin: 0 }}>
									<Button danger style={{ width: '49%' }} onClick={() => setMembershipAddModal(false)}>
										Cancel
									</Button>
									<Button loading={loading} style={{ width: '49%' }} type="primary" htmlType="submit">
										{editMembership ? 'Update' : 'Save'}
									</Button>
								</Row>
							</Form.Item>
						</Form>
					</Row>
				</Col>
			</Row>
		</Drawer>
	);
};

export default AddMembership;
