import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row } from 'antd';
// import { EditOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { SERVER_IP } from 'assets/Config';
import HighlightComponent from 'components/HighlightComponent';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import moment from 'moment';
import { convertToIndianRupees, formQueryStringFromObject, generatePagination } from 'helpers';
import { API_STATUS, DATE_FORMAT } from 'constants/app-constants';
import { getDateFormat } from 'services/Utils';
import PersonalTrainingListPresentational from './personal-training-list-presentational';

const initialPageSize = 10;
const initialPageSizeOptions = [10, 15, 20];

const PersonalTrainingListFunctional = React.memo(() => {
	const personalTrainingRedux = useSelector((state) => state.personalTrainingRedux);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const dispatch = useDispatch();

	const [personalTrainingAddModal, setPersonalTrainingAddModal] = useState(false);
	const [searchKey, setSearchKey] = useState('');
	const [tableData, setTableData] = useState(personalTrainingRedux.personalTraining);
	const [editPersonalTraining, setEditPersonalTraining] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);

	const getPeriods = useCallback(() => {
		const string = formQueryStringFromObject({
			branchId: globalRedux?.selectedBranch?.id,
		});
		let url = `${SERVER_IP}api/period?${string}`;
		dispatch(getApi('GET_PERIODS', url));
	}, [dispatch, globalRedux?.selectedBranch?.id]);

	useEffect(() => {
		getPeriods();
	}, [getPeriods]);

	const getPersonalTraining = useCallback(() => {
		const string = formQueryStringFromObject({
			branchId: globalRedux?.selectedBranch?.id,
		});
		let url = `${SERVER_IP}api/personalTraining?${string}`;
		dispatch(getApi('GET_PERSONAL_TRAINING', url));
	}, [dispatch, globalRedux?.selectedBranch?.id]);

	useEffect(() => {
		getPersonalTraining();
	}, [getPersonalTraining]);

	useEffect(() => {
		generatePagination(tableData);
	}, [tableData]);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_PT === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_PT'));
			setSelectedRowKeys([]);
			doIt = true;
		}
		if (doIt) {
			getPersonalTraining();
		}
	}, [globalRedux.apiStatus.DELETE_PT, dispatch, getPersonalTraining]);

	useEffect(() => {
		setTableData(personalTrainingRedux.personalTraining);
	}, [personalTrainingRedux.personalTraining]);

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((personalTraining) => {
			return (personalTraining?.personalTrainingName || '')?.toLowerCase().includes(searchKey.toLowerCase());
		});
	}, [tableData, searchKey]);

	// eslint-disable-next-line
	const handleDrawer = (rowData) => {
		setEditPersonalTraining(rowData);
		setPersonalTrainingAddModal(true);
	};

	const handleAddPersonalTraining = () => {
		setEditPersonalTraining(null);
		setPersonalTrainingAddModal(true);
	};

	const column = [
		{
			title: '#',
			dataIndex: 'personalTrainingNumber',
			key: 'personalTrainingNumber',
			// fixed: 'left',
			render: (value, record, index) => <div>{index + 1}</div>,
		},
		{
			title: 'Customer Name',
			dataIndex: 'customer',
			key: 'customer',
			sorter: (a, b) => a?.customer?.customerName?.localeCompare(b?.customer?.customerName),
			render: (value, record) => (
				<Row align={'middle'}>
					<div style={{ fontWeight: 'bold' }}>
						<HighlightComponent
							highlightClassName="highlightClass"
							searchWords={[searchKey]}
							autoEscape={true}
							textToHighlight={value?.customerName || '-'}
						/>
					</div>
				</Row>
			),
		},
		{
			title: 'Trainer Name',
			dataIndex: 'coach',
			key: 'coach',
			sorter: (a, b) => a?.coach?.coachName?.localeCompare(b?.coach?.coachName),
			render: (value, record) => (
				<Row align={'middle'}>
					<div style={{ fontWeight: 'bold' }}>
						<HighlightComponent
							highlightClassName="highlightClass"
							searchWords={[searchKey]}
							autoEscape={true}
							textToHighlight={value?.coachName || '-'}
						/>
					</div>
				</Row>
			),
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			align: 'right',
			// width: '7%',
			render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={convertToIndianRupees(value || 0)} />,
		},
		{
			title: 'Balance',
			dataIndex: 'balance',
			align: 'right',
			// width: '7%',
			render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={convertToIndianRupees(value || 0)} />,
		},
		{
			title: 'Paid On',
			dataIndex: 'paidOn',
			align: 'left',
			// width: '10%',
			render: (value) => (
				<HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={moment(value).format(DATE_FORMAT.DD_MM_YYYY)} />
			),
		},
		{
			title: 'Next Bill Date',
			dataIndex: 'nextBillDate',
			align: 'left',
			// width: '15%',
			render: (value) => getDateFormat(value, DATE_FORMAT.DD_MM_YYYY),
		},
		// {
		// 	title: 'Payment Meth.',
		// 	dataIndex: 'paymentMethod',
		// 	// width: '10%',
		// 	render: (value) => (
		// 		<div style={{ fontWeight: 'bold' }}>
		// 			<HighlightComponent
		// 				highlightClassName="highlightClass"
		// 				searchWords={[searchKey]}
		// 				autoEscape={true}
		// 				textToHighlight={value || '-'}
		// 			/>
		// 		</div>
		// 	),
		// },
		{
			title: 'Created Date',
			dataIndex: 'created_at',
			align: 'left',
			// width: '15%',
			render: (value) => getDateFormat(value),
		},
		// {
		// 	title: 'Action',
		// 	align: 'center',
		// 	dataIndex: 'personalTrainingName',
		// 	render: (value, row, index) => {
		// 		return (
		// 			<Row justify="center">
		// 				<Col className="edit_icon" onClick={() => handleDrawer(row)}>
		// 					<EditOutlined />
		// 				</Col>
		// 			</Row>
		// 		);
		// 	},
		// },
	];

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_PERSONAL_TRAINING === API_STATUS.PENDING, [globalRedux.apiStatus]);

	const onSelectChange = (selectedRowKeys) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		// fixed: true,
	};

	const handleClose = useCallback(() => {
		setPersonalTrainingAddModal(false);
		setEditPersonalTraining(null);
	}, [setPersonalTrainingAddModal, setEditPersonalTraining]);

	return (
		<PersonalTrainingListPresentational
			{...{
				filteredData,
				column,
				tableLoading,
				rowSelection,
				selectedRowKeys,
				handleAddPersonalTraining,
				currentPage,
				pageSize,
				initialPageSizeOptions,
				initialPageSize,
				handleTableChange,
				setSearchKey,
				getStartingValue,
				getEndingValue,
				personalTrainingAddModal,
				setPersonalTrainingAddModal,
				refreshList: getPersonalTraining,
				editPersonalTraining,
				handleClose,
			}}
		/>
	);
});

export default PersonalTrainingListFunctional;
