import React, { useEffect } from 'react';
import { Row, Col, Input, Button, Form, Drawer } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { putApi } from 'redux/sagas/putApiSaga';
import { API_STATUS } from 'constants/app-constants';
import { SERVER_IP } from 'assets/Config';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';

const AddService = ({ serviceAddModal, width = '40%', editService, setServiceAddModal, refreshList, handleClose }) => {
	const [form] = Form.useForm();
	const globalRedux = useSelector((state) => state.globalRedux);
	const branchId = globalRedux?.selectedBranch?.id;
	const dispatch = useDispatch();

	useEffect(() => {
		if (editService) {
			form.setFieldsValue({
				serviceName: editService?.serviceName || '',
			});
		} else {
			form?.resetFields();
		}
	}, [editService, form]);

	useEffect(() => {
		if (!serviceAddModal) {
			form?.resetFields();
		}
	}, [serviceAddModal, form]);

	const handleSubmit = (values) => {
		let data = {
			serviceName: values?.serviceName || '',
			branchId,
		};

		if (!editService) {
			dispatch(postApi(data, 'ADD_SERVICE'));
		} else {
			let url = `${SERVER_IP}api/services/${editService.id}`;
			dispatch(putApi(data, 'EDIT_SERVICE', url));
		}
	};

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_SERVICE === 'SUCCESS' || globalRedux.apiStatus.EDIT_SERVICE === 'SUCCESS') {
			dispatch(resetApiStatus(editService ? 'EDIT_SERVICE' : 'ADD_SERVICE'));
			refreshList?.();
			handleClose?.();
			form?.resetFields();
		}
	}, [globalRedux.apiStatus, editService, setServiceAddModal, dispatch, refreshList, handleClose, form]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const loading = globalRedux.apiStatus.ADD_SERVICE === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_SERVICE === API_STATUS.PENDING;

	return (
		<Drawer
			placement="right"
			title={`${editService ? 'Edit' : 'New'} Service`}
			width={width || '40%'}
			visible={serviceAddModal}
			closable
			onClose={() => setServiceAddModal(false)}
			destroyOnClose={true}>
			<Row>
				<Col span={24}>
					{/* <Row style={{ backgroundColor: '#fff', padding: '10px 0px' }}>
						<Col>
							<h6 style={{ marginBottom: '0px' }}>{editService ? 'Edit' : 'New'} Service</h6>
						</Col>
					</Row>
					<Divider /> */}
					<Row style={{ marginTop: 20 }}>
						<Form
							name="add-service"
							className="required_in_right"
							style={{ width: '100%' }}
							colon={true}
							labelAlign="left"
							form={form}
							onFinish={handleSubmit}
							initialValues={{}}
							{...layer1FormCol}>
							<Form.Item
								label="Service Name"
								name="serviceName"
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<Input placeholder="Service Name" />
							</Form.Item>
							<Form.Item
								wrapperCol={{
									offset: 0,
									span: 24,
								}}>
								<Row className="space-between" style={{ paddingTop: 20, width: '100%', margin: 0 }}>
									<Button danger style={{ width: '49%' }} onClick={() => setServiceAddModal(false)}>
										Cancel
									</Button>
									<Button loading={loading} style={{ width: '49%' }} type="primary" htmlType="submit">
										{editService ? 'Update' : 'Save'}
									</Button>
								</Row>
							</Form.Item>
						</Form>
					</Row>
				</Col>
			</Row>
		</Drawer>
	);
};

export default AddService;
