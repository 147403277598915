export const OPEN_PAYMENT_MODAL = 'OPEN_PAYMENT_MODAL';
export const CLOSE_PAYMENT_MODAL = 'CLOSE_PAYMENT_MODAL';
export const SET_PAYMENTS = 'SET_PAYMENTS';
export const SET_OUTSTANDING = 'SET_OUTSTANDING';
export const GET_EDIT_PAYMENT = 'GET_EDIT_PAYMENT';
export const SET_EDIT_PAYMENT = 'SET_EDIT_PAYMENT';
export const GET_EDIT_PERSONAL_TRAINING = 'GET_EDIT_PERSONAL_TRAINING';
export const SET_EDIT_PERSONAL_TRAINING = 'SET_EDIT_PERSONAL_TRAINING';
export const GET_EDIT_PERSONAL_TRAINING_PAYMENT = 'GET_EDIT_PERSONAL_TRAINING_PAYMENT';
export const SET_EDIT_PERSONAL_TRAINING_PAYMENT = 'SET_EDIT_PERSONAL_TRAINING_PAYMENT';

// Gym Payments
export const OPEN_GYM_PAYMENT_MODAL = 'OPEN_GYM_PAYMENT_MODAL';
export const CLOSE_GYM_PAYMENT_MODAL = 'CLOSE_GYM_PAYMENT_MODAL';
export const SET_GYM_PAYMENTS = 'SET_GYM_PAYMENTS';
export const SET_GYM_OUTSTANDING = 'SET_GYM_OUTSTANDING';
export const GET_EDIT_GYM_PAYMENT = 'GET_EDIT_GYM_PAYMENT';
export const SET_EDIT_GYM_PAYMENT = 'SET_EDIT_GYM_PAYMENT';
export const SET_GYM_PAYMENTS_PAGINATION = 'SET_GYM_PAYMENTS_PAGINATION';
export const GET_GYM_SB = 'GET_GYM_SB';
export const SET_GYM_SB = 'SET_GYM_SB';
export const ADD_GYM_PAYMENT = 'ADD_GYM_PAYMENT';