
function setProjects(data){
    return{
        type : "SET_PROJECTS",
        payload : data
    }
}

function setSelectedProject(data){
    return{
        type : "SET_SELECTED_PROJECT",
        payload : data
    }
}


export {  
    setProjects,
    setSelectedProject,
}