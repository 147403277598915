import React from 'react'
import ProfileFunctional from './components/profile-functional'
import './profile.scss'

const Profile = () => {
    return (
        <ProfileFunctional />
    )
}

export default Profile