import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Image, Row } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { SERVER_IP } from 'assets/Config';
import HighlightComponent from 'components/HighlightComponent';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { convertToIndianRupees, formQueryStringFromObject, generatePaginationForServerSide } from 'helpers';
import { API_STATUS, DATE_FORMAT } from 'constants/app-constants';
import PaymentListPresentational from './payment-list-presentational';
import MaleSVG from '../../../../assets/images/male.svg';
import FemaleSVG from '../../../../assets/images/female.svg';
import { getDateFormat } from 'services/Utils';

const initialPageSize = 10;
const initialPageSizeOptions = [10, 20, 40, 60];

const PaymentListFunctional = React.memo(({ paymentType }) => {
	const paymentRedux = useSelector((state) => state.paymentRedux);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const dispatch = useDispatch();
	const [paginationDetails, setPaginationDetails] = useState(paymentRedux?.paginationDetails);
	const branchId = globalRedux?.selectedBranch?.id;

	const [paymentAddModal, setPaymentAddModal] = useState(false);
	const [searchKey, setSearchKey] = useState('');
	const [debouncedSearchKey, setDebouncedSearchKey] = useState(searchKey);
	const [tableData, setTableData] = useState(paymentRedux.payments);
	const [editPaymentId, setEditPaymentId] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const [type] = useState('membership');
	const [prevString, setPrevString] = useState(null);
	const [sortBy, setSortBy] = useState('updated_at');
	const [sortByOrder, setSortByOrder] = useState('DESC');

	const getString = useCallback(() => formQueryStringFromObject({
		branchId,
		paymentType,
		limit: pageSize,
		offset: (currentPage - 1) * pageSize,
		sortBy,
		sortByOrder,
		searchKey: debouncedSearchKey
	}), [branchId, paymentType, pageSize, currentPage, sortBy, sortByOrder, debouncedSearchKey]);

	const getPayments = useCallback((forceReload = false) => {
			const string = getString();
			console.log({string, prevString})
			if (forceReload || string !== prevString) {
				const string = getString();
				let url = `${SERVER_IP}api/payments?${string}`;
				branchId && dispatch(getApi('GET_PAYMENTS', url));
				setPrevString(string);
			}
		},
		[getString, prevString, branchId, dispatch]
	);

	useEffect(() => {
		getPayments();
	}, [getPayments]);

	useEffect(() => {
		generatePaginationForServerSide(paginationDetails);
	}, [paginationDetails]);


	// const setTableLoading = useCallback(() => {
	// 	// dispatch(setApiStatus('GET_PAYMENTS', API_STATUS.PENDING));
	// 	setTableData([]);
	// }, []);

	useEffect(() => {
		// dispatch(setApiStatus("GET_CUSTOMERS", API_STATUS.PENDING));
		const handler = setTimeout(() => {
			// setTableLoading();
			setDebouncedSearchKey(searchKey);
		}, 500);
	
		// Cleanup the timeout if the component re-renders before the timeout is complete
		return () => {
		clearTimeout(handler);
		};
	}, [searchKey]);


	const setSorter = useCallback((sortBy, order) => {
		setSortBy(sortBy);
		setSortByOrder(order === "descend" ? "DESC" : "ASC");
		getPayments();
	}, [getPayments]);


	const onTableChange = (pagination, filters, sorter) => {
		setSorter(sorter.field, sorter.order);
	};


	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_PAYMENT === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_PAYMENT'));
			setSelectedRowKeys([]);
			doIt = true;
		}
		if (doIt) {
			getPayments(type);
		}
	}, [globalRedux.apiStatus.DELETE_PAYMENT, dispatch, getPayments, type]);

	useEffect(() => {
		setTableData(paymentRedux.payments);
	}, [paymentRedux.payments]);

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((payment) => {
			return (
				(payment?.customer?.customerName || '')?.toLowerCase().includes(searchKey.toLowerCase().trim()) ||
				(payment?.service?.serviceName || '')?.toLowerCase().includes(searchKey.toLowerCase().trim()) ||
				(payment?.membership?.membershipName || '')?.toLowerCase().includes(searchKey.toLowerCase().trim()) ||
				(payment?.coach?.coachName || '')?.toLowerCase().includes(searchKey.toLowerCase().trim()) ||
				(payment?.product?.productName || '')?.toLowerCase().includes(searchKey.toLowerCase().trim())
				// (payment?.outstandingBalance || '')?.toString().toLowerCase().includes(searchKey.toLowerCase().trim())
			);
		});
	}, [tableData, searchKey]);

	const handleDrawer = (id) => {
		setEditPaymentId(id);
		setPaymentAddModal(true);
	};

	const handleEditPaymentId = () => {
		setEditPaymentId(null);
		setPaymentAddModal(true);
	};

	// const getDynamicColumns = () => {
	// 	if (type === 'product') {
	// 		return [
	// 			{
	// 				title: 'Product',
	// 				dataIndex: 'product',
	// 				width: '20%',
	// 				render: (value) => (
	// 					<div style={{ fontWeight: 'bold' }}>
	// 						<HighlightComponent
	// 							highlightClassName="highlightClass"
	// 							searchWords={[searchKey.trim()]}
	// 							autoEscape={true}
	// 							textToHighlight={value?.productName}
	// 						/>
	// 					</div>
	// 				),
	// 			},
	// 			{
	// 				title: 'Qty',
	// 				dataIndex: 'qty',
	// 				align: 'right',
	// 				render: (value) => <HighlightComponent searchWords={[searchKey.trim()]} autoEscape={true} textToHighlight={value?.toString()} />,
	// 			},
	// 			{
	// 				title: 'Price',
	// 				dataIndex: 'product',
	// 				align: 'right',
	// 				render: (value) => (
	// 					<HighlightComponent
	// 						searchWords={[searchKey.trim()]}
	// 						autoEscape={true}
	// 						textToHighlight={value?.offerPrice ? value?.offerPrice?.toString() : value?.originalPrice?.toString()}
	// 					/>
	// 				),
	// 			},
	// 		];
	// 	} else {
	// 		return [
	// 			{
	// 				title: 'Service',
	// 				dataIndex: 'service',
	// 				fixed: 'left',
	// 				width: '13%',
	// 				render: (value) => (value?.serviceName || '') && <span className="membership-button">{value?.serviceName}</span>,
	// 			},
	// 			{
	// 				title: 'Membership',
	// 				dataIndex: 'membership',
	// 				align: 'left',
	// 				width: '12%',
	// 				render: (value) => (value?.membershipName || '') && <span className="membership-button">{value?.membershipName}</span>,
	// 			},
	// 			{
	// 				title: 'Next Bill Date',
	// 				dataIndex: 'nextBillDate',
	// 				align: 'left',
	// 				width: '12%',
	// 				render: (value) => (
	// 					<HighlightComponent
	// 						searchWords={[searchKey.trim()]}
	// 						autoEscape={true}
	// 						textToHighlight={moment(value).format(DATE_FORMAT.DD_MM_YYYY)}
	// 					/>
	// 				),
	// 			},
	// 		];
	// 	}
	// };

	const columns = {
		product: [
			{
				title: '#',
				dataIndex: 'paymentNumber',
				key: 'paymentNumber',
				// width: 100,
				render: (value, record, index) => <div>{index + 1}</div>,
			},
			{
				title: 'Pic',
				dataIndex: 'customer',
				key: 'customer',
				width: 50,
				fixed: 'left',
				render: (value, record) => {
					return (
						<Row align={'middle'}>
							<Col
								style={{
									paddingRight: '10px',
								}}>
								{record?.customer?.image?.[0]?.url ? (
									<Image
										src={SERVER_IP + record?.customer?.image?.[0]?.url}
										alt="Avatar"
										style={{ width: '40px', height: '40px', borderRadius: 5 }}
									/>
								) : (
									<img
										src={record?.customer?.gender === 'male' ? MaleSVG : FemaleSVG}
										alt="Avatar"
										style={{ width: '40px', height: '40px', borderRadius: 5 }}
									/>
								)}
							</Col>
						</Row>
					);
				},
			},
			{
				title: 'Customer Name',
				dataIndex: 'customer',
				width: '13%',
				fixed: 'left',
				// sorter: (a, b) => a?.customer.customerName?.localeCompare(b?.customer.customerName),
				// sorter: true,
				render: (value, record) => {
					return (
						<Row align={'middle'}>
							<div style={{ fontWeight: 'bold' }}>
								<HighlightComponent
									highlightClassName="highlightClass"
									searchWords={[searchKey.trim()]}
									autoEscape={true}
									textToHighlight={value?.customerName || '-'}
								/>
							</div>
						</Row>
					);
				},
			},
			{
				title: 'Product',
				dataIndex: 'product',
				render: value => {
					return (
						<Row align={'middle'}>
							<div style={{ fontWeight: 'bold' }}>
								<HighlightComponent
									highlightClassName="highlightClass"
									searchWords={[searchKey.trim()]}
									autoEscape={true}
									textToHighlight={value?.productName || '-'}
								/>
							</div>
						</Row>
					);
				},
			},
			{
				title: 'Received By',
				dataIndex: 'coach',
				render: value => {
					return (
						<Row align={'middle'}>
							<div style={{ fontWeight: 'bold' }}>
								<HighlightComponent
									highlightClassName="highlightClass"
									searchWords={[searchKey.trim()]}
									autoEscape={true}
									textToHighlight={value?.coachName || '-'}
								/>
							</div>
						</Row>
					);
				},
			},
			{
				title: 'Qty',
				dataIndex: 'qty',
				render: (value) => (value || '-') && <span className="membership-button">{value || '-'}</span>,
			},
			{
				title: 'Amount - Total',
				dataIndex: 'amount',
				align: 'right',

				render: (value) => (
					<HighlightComponent searchWords={[searchKey.trim()]} autoEscape={true} textToHighlight={convertToIndianRupees(value || 0)} />
				),
			},
			{
				title: 'Amount - Paid',
				dataIndex: 'paid',
				align: 'right',

				render: (value) => (
					<HighlightComponent searchWords={[searchKey.trim()]} autoEscape={true} textToHighlight={convertToIndianRupees(value || 0)} />
				),
			},
			{
				title: 'Balance',
				dataIndex: 'balance',
				align: 'right',
				width: '7%',
				render: (value) => (
					<HighlightComponent searchWords={[searchKey.trim()]} autoEscape={true} textToHighlight={convertToIndianRupees(value || 0)} />
				),
			},
			{
				title: 'Paid On',
				dataIndex: 'paidOn',
				align: 'left',

				render: (value) => (
					<HighlightComponent searchWords={[searchKey.trim()]} autoEscape={true} textToHighlight={moment(value).format(DATE_FORMAT.DD_MM_YYYY)} />
				),
			},
			{
				title: 'Payment Meth.',
				dataIndex: 'paymentMethod',

				render: (value) => (
					<div style={{ fontWeight: 'bold' }}>
						<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey.trim()]} autoEscape={true} textToHighlight={value} />
					</div>
				),
			},
			{
				title: 'UPI #',
				dataIndex: 'upiTransactionId',
				align: 'left',
				render: (value) => <HighlightComponent searchWords={[searchKey.trim()]} autoEscape={true} textToHighlight={(value || '-')?.toString()} />,
			},
			{
				title: 'Remarks',
				dataIndex: 'remarks',
				align: 'left',
				render: (value) => <HighlightComponent searchWords={[searchKey.trim()]} autoEscape={true} textToHighlight={(value || '-')?.toString()} />,
			},
			{
				title: 'Action',
				align: 'center',
				dataIndex: 'id',
				render: (value) => {
					return (
						<Row justify="center">
							<Col className="edit_icon" onClick={() => handleDrawer(value)}>
								<EditOutlined />
							</Col>
						</Row>
					);
				},
			},
		],
		membership: [
			{
				title: '#',
				dataIndex: 'paymentNumber',
				key: 'paymentNumber',
				width: '5%',
				render: (value, record, index) => <div>{index + 1}</div>,
			},
			{
				title: 'Pic',
				dataIndex: 'customer',
				key: 'customer',
				width: 50,
				fixed: 'left',
				render: (value, record) => {
					return (
						<Row align={'middle'}>
							<Col
								style={{
									paddingRight: '10px',
								}}>
								{record?.customer?.image?.[0]?.url ? (
									<Image
										src={SERVER_IP + record?.customer?.image?.[0]?.url}
										alt="Avatar"
										style={{ width: '40px', height: '40px', borderRadius: 5 }}
									/>
								) : (
									<img
										src={record?.customer?.gender === 'male' ? MaleSVG : FemaleSVG}
										alt="Avatar"
										style={{ width: '40px', height: '40px', borderRadius: 5 }}
									/>
								)}
							</Col>
						</Row>
					);
				},
			},
			{
				title: 'Customer Name',
				dataIndex: 'customer',
				width: '13%',
				fixed: 'left',
				// sorter: (a, b) => a?.customer.customerName?.localeCompare(b?.customer.customerName),
				// sorter: true,
				render: value => {
					return (
						<Row align={'middle'}>
							<div style={{ fontWeight: 'bold' }}>
								<HighlightComponent
									highlightClassName="highlightClass"
									searchWords={[searchKey.trim()]}
									autoEscape={true}
									textToHighlight={value?.customerName || '-'}
								/>
							</div>
						</Row>
					);
				},
			},
			{
				title: 'Service',
				dataIndex: 'service',
				width: '13%',
				render: value => {
					return (
						<Row align={'middle'}>
							<div style={{ fontWeight: 'bold' }}>
								<HighlightComponent
									highlightClassName="highlightClass"
									searchWords={[searchKey.trim()]}
									autoEscape={true}
									textToHighlight={value?.serviceName || '-'}
								/>
							</div>
						</Row>
					);
				},
			},
			{
				title: 'Membership',
				dataIndex: 'membership',
				align: 'left',
				width: '12%',
				render: value => {
					return (
						<Row align={'middle'}>
							<div style={{ fontWeight: 'bold' }}>
								<HighlightComponent
									highlightClassName="highlightClass"
									searchWords={[searchKey.trim()]}
									autoEscape={true}
									textToHighlight={value?.membershipName || '-'}
								/>
							</div>
						</Row>
					);
				},
			},
			{
				title: 'Received By',
				dataIndex: 'coach',
				render: value => {
					return (
						<Row align={'middle'}>
							<div style={{ fontWeight: 'bold' }}>
								<HighlightComponent
									highlightClassName="highlightClass"
									searchWords={[searchKey.trim()]}
									autoEscape={true}
									textToHighlight={value?.coachName || '-'}
								/>
							</div>
						</Row>
					);
				},
			},
			{
				title: 'Amount - Total',
				dataIndex: 'amount',
				align: 'right',
				width: '7%',
				render: (value) => (
					<HighlightComponent searchWords={[searchKey.trim()]} autoEscape={true} textToHighlight={convertToIndianRupees(value || 0)} />
				),
			},
			{
				title: 'Amount - Paid',
				dataIndex: 'paid',
				align: 'right',
				width: '7%',
				render: (value) => (
					<HighlightComponent searchWords={[searchKey.trim()]} autoEscape={true} textToHighlight={convertToIndianRupees(value || 0)} />
				),
			},
			{
				title: 'Balance',
				dataIndex: 'balance',
				align: 'right',
				width: '7%',
				render: (value) => (
					<HighlightComponent searchWords={[searchKey.trim()]} autoEscape={true} textToHighlight={convertToIndianRupees(value || 0)} />
				),
			},
			// {
			// 	title: 'Joined on',
			// 	dataIndex: 'customerId',
			// 	align: 'left',
			// 	width: '10%',
			// 	render: (value) => (
			// 		<HighlightComponent
			// 			searchWords={[searchKey.trim()]}
			// 			autoEscape={true}
			// 			textToHighlight={moment(value?.joiningDate).format(DATE_FORMAT.DD_MM_YYYY)}
			// 		/>
			// 	),
			// },
			{
				title: 'Paid On',
				dataIndex: 'paidOn',
				align: 'left',
				width: '10%',
				render: (value) => (
					<HighlightComponent searchWords={[searchKey.trim()]} autoEscape={true} textToHighlight={moment(value).format(DATE_FORMAT.DD_MM_YYYY)} />
				),
			},
			{
				title: 'Next Bill Date',
				dataIndex: 'nextBillDate',
				align: 'left',
				width: '15%',
				render: (value) => getDateFormat(value, DATE_FORMAT.DD_MM_YYYY),
			},
			{
				title: 'Payment Meth.',
				dataIndex: 'paymentMethod',
				width: '10%',
				render: (value) => (
					<div style={{ fontWeight: 'bold' }}>
						<HighlightComponent
							highlightClassName="highlightClass"
							searchWords={[searchKey.trim()]}
							autoEscape={true}
							textToHighlight={value || '-'}
						/>
					</div>
				),
			},
			{
				title: 'UPI #',
				dataIndex: 'upiTransactionId',
				align: 'left',
				width: '10%',
				render: (value) => <HighlightComponent searchWords={[searchKey.trim()]} autoEscape={true} textToHighlight={(value || '-')?.toString()} />,
			},
			{
				title: 'Remarks',
				dataIndex: 'remarks',
				align: 'left',
				width: '15%',
				render: (value) => <HighlightComponent searchWords={[searchKey.trim()]} autoEscape={true} textToHighlight={(value || '-')?.toString()} />,
			},
			{
				title: 'Action',
				align: 'center',
				dataIndex: 'id',
				render: (value) => {
					return (
						<Row justify="center">
							<Col className="edit_icon" onClick={() => handleDrawer(value)}>
								<EditOutlined />
							</Col>
						</Row>
					);
				},
			},
		],
		personalTraining: [
			{
				title: '#',
				dataIndex: 'paymentNumber',
				key: 'paymentNumber',
				// width: 100,
				render: (value, record, index) => <div>{index + 1}</div>,
			},
			{
				title: 'Pic',
				dataIndex: 'customer',
				key: 'customer',
				width: 50,
				fixed: 'left',
				render: (value, record) => {
					return (
						<Row align={'middle'}>
							<Col
								style={{
									paddingRight: '10px',
								}}>
								{record?.customer?.image?.[0]?.url ? (
									<Image
										src={SERVER_IP + record?.customer?.image?.[0]?.url}
										alt="Avatar"
										style={{ width: '40px', height: '40px', borderRadius: 5 }}
									/>
								) : (
									<img
										src={record?.customer?.gender === 'male' ? MaleSVG : FemaleSVG}
										alt="Avatar"
										style={{ width: '40px', height: '40px', borderRadius: 5 }}
									/>
								)}
							</Col>
						</Row>
					);
				},
			},
			{
				title: 'Customer Name',
				dataIndex: 'customer',
				width: '13%',
				fixed: 'left',
				// sorter: (a, b) => a?.customer.customerName?.localeCompare(b?.customer.customerName),
				// sorter: true,
				render: (value, record) => {
					return (
						<Row align={'middle'}>
							<div style={{ fontWeight: 'bold' }}>
								<HighlightComponent
									highlightClassName="highlightClass"
									searchWords={[searchKey.trim()]}
									autoEscape={true}
									textToHighlight={value?.customerName || '-'}
								/>
							</div>
						</Row>
					);
				},
			},
			{
				title: 'Coach Name',
				dataIndex: 'coach',
				render: (value) => (value?.coachName || '-') && <span className="membership-button">{value?.coachName || '-'}</span>,
			},
			{
				title: 'Received By',
				dataIndex: 'received',
				
				render: value => {
					return (
						<Row align={'middle'}>
							<div style={{ fontWeight: 'bold' }}>
								<HighlightComponent
									highlightClassName="highlightClass"
									searchWords={[searchKey.trim()]}
									autoEscape={true}
									textToHighlight={value?.coachName || '-'}
								/>
							</div>
						</Row>
					);
				},
			},
			{
				title: 'Amount - Total',
				dataIndex: 'amount',
				align: 'right',
				render: (value) => (
					<HighlightComponent searchWords={[searchKey.trim()]} autoEscape={true} textToHighlight={convertToIndianRupees(value || 0)} />
				),
			},
			{
				title: 'Amount - Paid',
				dataIndex: 'paid',
				align: 'right',
				render: (value) => (
					<HighlightComponent searchWords={[searchKey.trim()]} autoEscape={true} textToHighlight={convertToIndianRupees(value || 0)} />
				),
			},
			{
				title: 'Balance',
				dataIndex: 'balance',
				align: 'right',
				width: '7%',
				render: (value) => (
					<HighlightComponent searchWords={[searchKey.trim()]} autoEscape={true} textToHighlight={convertToIndianRupees(value || 0)} />
				),
			},
			{
				title: 'Paid On',
				dataIndex: 'paidOn',
				align: 'left',
				render: (value) => (
					<HighlightComponent searchWords={[searchKey.trim()]} autoEscape={true} textToHighlight={moment(value).format(DATE_FORMAT.DD_MM_YYYY)} />
				),
			},
			{
				title: 'Payment Meth.',
				dataIndex: 'paymentMethod',

				render: (value) => (
					<div style={{ fontWeight: 'bold' }}>
						<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey.trim()]} autoEscape={true} textToHighlight={value} />
					</div>
				),
			},
			{
				title: 'UPI #',
				dataIndex: 'upiTransactionId',
				align: 'left',
				render: (value) => <HighlightComponent searchWords={[searchKey.trim()]} autoEscape={true} textToHighlight={(value || '-')?.toString()} />,
			},
			{
				title: 'Remarks',
				dataIndex: 'remarks',
				align: 'left',
				render: (value) => <HighlightComponent searchWords={[searchKey.trim()]} autoEscape={true} textToHighlight={(value || '-')?.toString()} />,
			},
			{
				title: 'Action',
				align: 'center',
				dataIndex: 'id',
				render: (value) => {
					return (
						<Row justify="center">
							<Col className="edit_icon" onClick={() => handleDrawer(value)}>
								<EditOutlined />
							</Col>
						</Row>
					);
				},
			},
		],
	};

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	useEffect(() => {
		setPaginationDetails(paymentRedux?.paginationDetails)
	}, [paymentRedux?.paginationDetails])


	const handlePaginationChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage);
		setPageSize(pageSize);
		// setTableLoading();
		setPaginationDetails({
			...paginationDetails,
			currentPage,
			pageSize,
		});

		getPayments();
	};

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_PAYMENTS === API_STATUS.PENDING, [globalRedux.apiStatus]);

	const onSelectChange = (selectedRowKeys) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		// fixed: true,
	};

	const handleClose = useCallback(() => {
		setPaymentAddModal(false);
		setEditPaymentId(null);
	}, [setPaymentAddModal, setEditPaymentId]);

	// const onTypeChange = (type) => {
	// 	setType(type);
	// };

	return (
		<PaymentListPresentational
			{...{
				filteredData,
				column: columns[paymentType],
				paymentFor: paymentType,
				tableLoading,
				rowSelection,
				selectedRowKeys,
				handleEditPaymentId,
				currentPage,
				pageSize,
				initialPageSizeOptions,
				initialPageSize,
				handleTableChange,
				setSearchKey,
				getStartingValue,
				getEndingValue,
				paymentAddModal,
				setPaymentAddModal,
				refreshList: getPayments,
				editPaymentId,
				handleClose,
				paymentType,
				onTableChange,
				handlePaginationChange,
				paginationDetails
			}}
		/>
	);
});

export default PaymentListFunctional;
