
function setPaymentReports(data){
    return{
        type : "SET_PAYMENT_REPORTS",
        payload : data
    }
}

export {  
    setPaymentReports
}