import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { SERVER_IP } from 'assets/Config';
import HighlightComponent from 'components/HighlightComponent';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { formQueryStringFromObject, generatePagination } from 'helpers';
import { API_STATUS } from 'constants/app-constants';
import PeriodListPresentational from './periods-list-presentational';
import { getDateFormat } from 'services/Utils';

const initialPageSize = 10;
const initialPageSizeOptions = [10, 15, 20];

const PeriodListFunctional = React.memo(() => {
	const periodRedux = useSelector((state) => state?.periodRedux);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const dispatch = useDispatch();

	const [periodAddModal, setPeriodAddModal] = useState(false);
	const [searchKey, setSearchKey] = useState('');
	const [tableData, setTableData] = useState(periodRedux?.periods);
	const [editPeriod, setEditPeriod] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);

	const getPeriods = useCallback(() => {
		const string = formQueryStringFromObject({
			branchId: globalRedux?.selectedBranch?.id,
		});
		let url = `${SERVER_IP}api/period?${string}`;
		dispatch(getApi('GET_PERIODS', url));
	}, [dispatch, globalRedux?.selectedBranch?.id]);

	useEffect(() => {
		getPeriods();
	}, [getPeriods]);

	useEffect(() => {
		generatePagination(tableData);
	}, [tableData]);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_PERIOD === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_PERIOD'));
			setSelectedRowKeys([]);
			doIt = true;
		}
		if (doIt) {
			getPeriods();
		}
	}, [globalRedux.apiStatus.DELETE_PERIOD, dispatch, getPeriods]);

	useEffect(() => {
		setTableData(periodRedux?.periods);
	}, [periodRedux?.periods]);

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((period) => {
			return (period?.name || '')?.toLowerCase().includes(searchKey.toLowerCase());
		});
	}, [tableData, searchKey]);

	const handleDrawer = (rowData) => {
		setEditPeriod(rowData);
		setPeriodAddModal(true);
	};

	const handleAddPeriod = () => {
		setEditPeriod(null);
		setPeriodAddModal(true);
	};

	const column = [
		{
			title: '#',
			dataIndex: 'periodNumber',
			key: 'periodNumber',
			// fixed: 'left',
			width: '5%',
			render: (value, record, index) => <div>{index + 1}</div>,
		},
		{
			title: 'Period Name',
			dataIndex: 'name',
			key: 'name',
			sorter: (a, b) => a?.name?.localeCompare(b?.name),
			// fixed: 'left',
			render: (value) => (
				<div style={{ fontWeight: 'bold' }}>
					<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />
				</div>
			),
		},
		{
			title: 'Days',
			dataIndex: 'days',
			key: 'days',
			sorter: (a, b) => a?.days?.localeCompare(b?.days),
			// fixed: 'left',
			render: (value) => (
				<div style={{ fontWeight: 'bold' }}>
					<HighlightComponent
						highlightClassName="highlightClass"
						searchWords={[searchKey]}
						autoEscape={true}
						textToHighlight={(value || 0)?.toString()}
					/>
				</div>
			),
		},
		{
			title: 'Created Date',
			dataIndex: 'created_at',
			align: 'left',
			width: '25%',
			render: (value) => getDateFormat(value),
		},
		{
			title: 'Action',
			align: 'center',
			width: '10%',
			dataIndex: 'name',
			render: (value, row, index) => {
				return (
					<Row justify="center">
						<Col className="edit_icon" onClick={() => handleDrawer(row)}>
							<EditOutlined />
						</Col>
					</Row>
				);
			},
		},
	];

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_PERIODS === API_STATUS.PENDING, [globalRedux.apiStatus]);

	const onSelectChange = (selectedRowKeys) => {
		console.log('🚀 ~ onSelectChange ~ selectedRowKeys:', selectedRowKeys);
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		// fixed: true,
	};

	const handleClose = useCallback(() => {
		setPeriodAddModal(false);
		setEditPeriod(null);
	}, [setPeriodAddModal, setEditPeriod]);

	return (
		<PeriodListPresentational
			{...{
				filteredData,
				column,
				tableLoading,
				rowSelection,
				selectedRowKeys,
				handleAddPeriod,
				currentPage,
				pageSize,
				initialPageSizeOptions,
				initialPageSize,
				handleTableChange,
				setSearchKey,
				getStartingValue,
				getEndingValue,
				periodAddModal,
				setPeriodAddModal,
				refreshList: getPeriods,
				editPeriod,
				handleClose,
			}}
		/>
	);
});

export default PeriodListFunctional;
