import React, { useEffect, useMemo, useState } from 'react';
import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import useEventListener from 'hooks/handlePasteFiles';

const FileUpload = (props) => {
	useEventListener('paste', (e) => {
		if (e.clipboardData.files.length > 0) {
			// const files = Array.from(e.clipboardData.files);
			// console.log('🚀 ~ useEventListener ~ e.clipboardData.files:', e.clipboardData.files);
			// props.beforeUpload(files[0]);
		}
	});

	const fileList1 = useMemo(() => (props.fileList ? props.fileList : []), [props.fileList]);
	const [fileList, setFileList] = useState(fileList1);

	const onChange = ({ fileList: newFileList }) => {
		console.log('newFileList', newFileList, fileList);
		setFileList(newFileList);
		props.afterUpload(newFileList);
	};

	useEffect(() => {
		setFileList(fileList1);
	}, [fileList1]);

	const onPreview = async (file) => {
		let src = file.url;
		if (!src) {
			src = await new Promise((resolve) => {
				const reader = new FileReader();
				reader.readAsDataURL(file.originFileObj);
				reader.onload = () => resolve(reader.result);
			});
		}
		const image = new Image();
		image.src = src;
		const imgWindow = window.open(src);
		imgWindow.document.write(image.outerHTML);
	};
	const count = props.max ? props.max : 1;

	const beforeUpload = (file) => {
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			setFileList((prevList) => {
				for (let i = 0; i < prevList.length; i++) {
					if (prevList[i].uid === file.uid) {
						prevList[i].thumbUrl = reader.result;
					}
				}

				props.setFileList(prevList);
				return prevList;
			});
		};
		// props.beforeUpload(file, setFileList);
		if (props.action === '/') {
			return false;
		}
		// return false;
	};

	return (
		<ImgCrop key={props.key} showGrid rotationSlider aspectSlider showReset>
			<Upload
				// `${SERVER_IP}/api/media/upload?userId=${loginRedux?.id}`
				action={props.action ? props.action : null}
				listType={props.listType ? props.listType : 'picture-card'}
				fileList={fileList}
				className={props.className}
				onChange={onChange}
				beforeUpload={beforeUpload}
				onPreview={onPreview}>
				{fileList.length < count && `+ Upload`}
			</Upload>
		</ImgCrop>
	);
};

// ReactDOM.render(<FileUpload />, mountNode);
export default FileUpload;
