import moment from 'moment';
// import lodash from 'lodash'

const base64Converter = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			let result = reader.result;
			result = result.replace('data:image/jpeg;base64,', '');
			result = result.replace('data:image/jpg;base64,', '');
			result = result.replace('data:image/png;base64,', '');
			result = result.replace('data:application/pdf;base64,', '');
			resolve(result);
		};
		reader.onerror = (error) => reject(error);
	});

const getCurrentDate = (format) => {
	return moment(new Date()).format(format || 'DD-MM-YYYY');
};

const getTodaysDate = () => {
	return moment().date();
};

const getCurrentYear = () => {
	return moment().year();
};

const getDateAfterYears = (years, format) => {
	return moment(new Date())
		.add(years, 'years')
		.format(format || 'DD-MM-YYYY');
};

const getDateBeforeYears = (years, format) => {
	return moment(new Date())
		.subtract(years, 'years')
		.format(format || 'DD-MM-YYYY');
};

// const isIdentical = (json1,json2) => lodash.isEqual(json1,json2);

const getDateFormat = (data, format = 'DD/MM/YYYY h:mm A') => (!!data ? moment(data || '').format(format) : 'N/A');

/*
Get start date of the month if the moment date is  given
*/
const getStartDateOfMonth = (date) => {
	// console.log('date', date);
	return moment(date).startOf('month').format('YYYY-MM-DD');
};

/*
	Get Difference days between 2 dates given
	@param date1 : start date
	@param date2 : end date
	@return difference in days
*/
const getDifferenceDays = (date1, date2) => {
	return moment(date1).diff(moment(date2), 'days');
};

const getINRMoneyFormat = (amount) => {
	let formatter = new Intl.NumberFormat('en-IN', {
		style: 'currency',
		currency: 'INR'
	});
	return formatter.format(amount);
}

const capitalizeFirstLetter = (str) => {
	return str.charAt(0).toUpperCase() + str.slice(1);
}


export {
	base64Converter,
	getCurrentDate,
	getCurrentYear,
	getTodaysDate,
	getDateAfterYears,
	getDateBeforeYears,
	getStartDateOfMonth,
	// isIdentical,
	getDateFormat,
	getDifferenceDays,
	getINRMoneyFormat,
	capitalizeFirstLetter
};
