import React from 'react';
import CountUp from 'react-countup';

const AnimatedNumber = ({ value, showRupee = false }) => {
	return (
		<div>
			{showRupee ? '₹' : ''}
			<CountUp end={value} duration={1} />
		</div>
	);
};

export default AnimatedNumber;
