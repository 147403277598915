import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { SERVER_IP } from 'assets/Config';
import HighlightComponent from 'components/HighlightComponent';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { convertToIndianRupees, formQueryStringFromObject, generatePagination } from 'helpers';
import { API_STATUS } from 'constants/app-constants';
import MembershipListPresentational from './membership-list-presentational';
import { getDateFormat } from 'services/Utils';

const initialPageSize = 10;
const initialPageSizeOptions = [10, 15, 20];

const MembershipListFunctional = React.memo(({ serviceId }) => {
	const membershipRedux = useSelector((state) => state?.membershipRedux);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const dispatch = useDispatch();

	const [membershipAddModal, setMembershipAddModal] = useState(false);
	const [searchKey, setSearchKey] = useState('');
	const [tableData, setTableData] = useState(membershipRedux?.memberships);
	const [editMembership, setEditMembership] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);

	const getMemberships = useCallback(() => {
		const string = formQueryStringFromObject({
			branchId: globalRedux?.selectedBranch?.id,
			serviceId,
		});
		let url = `${SERVER_IP}api/memberships?${string}`;
		dispatch(getApi('GET_MEMBERSHIPS', url));
	}, [dispatch, globalRedux?.selectedBranch?.id, serviceId]);

	useEffect(() => {
		getMemberships();
	}, [getMemberships]);

	useEffect(() => {
		generatePagination(tableData);
	}, [tableData]);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_MEMBERSHIP === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_MEMBERSHIP'));
			setSelectedRowKeys([]);
			doIt = true;
		}
		if (doIt) {
			getMemberships();
		}
	}, [globalRedux.apiStatus.DELETE_MEMBERSHIP, dispatch, getMemberships]);

	useEffect(() => {
		setTableData(membershipRedux?.memberships?.filter((membership) => membership?.serviceId === serviceId));
	}, [membershipRedux?.memberships, serviceId]);

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((membership) => {
			return (membership?.membershipName || '')?.toLowerCase().includes(searchKey.toLowerCase());
		});
	}, [tableData, searchKey]);

	const handleDrawer = (rowData) => {
		setEditMembership(rowData);
		setMembershipAddModal(true);
	};

	const handleAddMembership = () => {
		setEditMembership(null);
		setMembershipAddModal(true);
	};

	const column = [
		{
			title: '#',
			dataIndex: 'membershipNumber',
			key: 'membershipNumber',
			// fixed: 'left',
			render: (value, record, index) => <div>{index + 1}</div>,
		},
		{
			title: 'Membership Name',
			dataIndex: 'membershipName',
			key: 'membershipName',
			sorter: (a, b) => a?.membershipName?.localeCompare(b?.membershipName),
			// fixed: 'left',
			render: (value) => (
				<div style={{ fontWeight: 'bold' }}>
					<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />
				</div>
			),
		},
		// {
		// 	title: 'Service Type',
		// 	dataIndex: 'serviceId',
		// 	align: 'left',
		// 	width: '15%',
		// 	render: (value) => (
		// 		<div style={{ fontWeight: 'bold' }}>
		// 			<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value?.serviceName} />
		// 		</div>
		// 	),
		// },
		{
			title: 'Days',
			dataIndex: 'days',
			align: 'left',
			width: '8%',
		},
		{
			title: 'Original Price',
			dataIndex: 'originalPrice',
			align: 'left',
			width: '15%',
			render: (value) => <span>{convertToIndianRupees(value || 0)}</span>,
		},
		{
			title: 'Offer Price',
			dataIndex: 'offerPrice',
			align: 'left',
			width: '15%',
			render: (value) => <span>{convertToIndianRupees(value || 0)}</span>,
		},
		{
			title: 'Created Date',
			dataIndex: 'created_at',
			align: 'left',
			width: '15%',
			render: (value) => getDateFormat(value),
		},
		{
			title: 'Action',
			align: 'center',
			dataIndex: 'membershipName',
			render: (value, row, index) => {
				return (
					<Row justify="center">
						<Col className="edit_icon" onClick={() => handleDrawer(row)}>
							<EditOutlined />
						</Col>
					</Row>
				);
			},
		},
	];

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_MEMBERSHIPS === API_STATUS.PENDING, [globalRedux.apiStatus]);

	const onSelectChange = (selectedRowKeys) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		// fixed: true,
	};

	const handleClose = useCallback(() => {
		setMembershipAddModal(false);
		setEditMembership(null);
	}, [setMembershipAddModal, setEditMembership]);

	return (
		<MembershipListPresentational
			{...{
				filteredData,
				column,
				tableLoading,
				rowSelection,
				selectedRowKeys,
				handleAddMembership,
				currentPage,
				pageSize,
				initialPageSizeOptions,
				initialPageSize,
				handleTableChange,
				setSearchKey,
				getStartingValue,
				getEndingValue,
				membershipAddModal,
				setMembershipAddModal,
				refreshList: getMemberships,
				editMembership,
				handleClose,
				serviceId,
			}}
		/>
	);
});

export default MembershipListFunctional;
