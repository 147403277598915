import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox } from 'antd';
import { formQueryStringFromObject } from 'helpers';
import { useDispatch, useSelector } from 'react-redux';
import { SERVER_IP } from 'assets/Config';
import { getApi } from 'redux/sagas/getApiDataSaga';
import RoleManagementPresentational from './role-management-presentational';
import { setCoachAccess } from 'redux/reducers/access/accessAction';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { API_STATUS } from 'constants/app-constants';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';

const RoleManagementFunctional = () => {
	const dispatch = useDispatch();
	const coaches = useSelector((state) => state.coachRedux.coaches);
	const accesses = useSelector((state) => state.accessRedux.access);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [activeCoach, setActiveCoach] = useState(null);
	const [dataSource, setDataSource] = useState([]);

	const getCoaches = useCallback(() => {
		const query = formQueryStringFromObject({
			branchId: globalRedux?.selectedBranch?.id,
		});
		const url = `${SERVER_IP}api/coaches?${query}`;
		dispatch(getApi('GET_COACHES', url));
	}, [dispatch, globalRedux?.selectedBranch?.id]);

	useEffect(() => {
		getCoaches();
	}, [getCoaches]);

	useEffect(() => {
		if (coaches.length > 0) {
			setActiveCoach(coaches[0]?.id);
		}
	}, [coaches]);

	const getAccessForCoach = useCallback(
		(coachId) => {
			const query = formQueryStringFromObject({ coachId });
			const url = `${SERVER_IP}api/access?${query}`;
			dispatch(setCoachAccess([]));
			dispatch(getApi('GET_ACCESS_FOR_COACH', url));
		},
		[dispatch]
	);

	useEffect(() => {
		if (activeCoach) {
			getAccessForCoach(activeCoach);
		}
	}, [activeCoach, getAccessForCoach]);

	useEffect(() => {
		if (globalRedux.apiStatus.GET_ACCESS_FOR_COACH === API_STATUS.SUCCESS) {
			setDataSource(accesses);
		}
	}, [globalRedux.apiStatus.GET_ACCESS_FOR_COACH, accesses]);

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_ACCESS === API_STATUS.SUCCESS) {
			if (activeCoach) {
				getAccessForCoach(activeCoach);
				dispatch(resetApiStatus('ADD_ACCESS'));
			}
		}
	}, [globalRedux.apiStatus.ADD_ACCESS, getAccessForCoach, activeCoach, dispatch]);

	const handleCheckboxChange = (e, moduleName, field) => {
		const { checked } = e.target;
		setDataSource((prevData) => prevData.map((item) => (item.moduleName === moduleName ? { ...item, [field]: checked } : item)));
	};

	const handleSave = () => {
		const data = {
			coachId: activeCoach,
			accessJson: dataSource,
		};
		dispatch(postApi(data, 'ADD_ACCESS'));
	};

	const columns = [
		{
			title: 'Module Name',
			dataIndex: 'moduleName',
			key: 'moduleName',
		},
		{
			title: 'View',
			dataIndex: 'view',
			key: 'view',
			render: (text, record) => <Checkbox checked={text} onChange={(e) => handleCheckboxChange(e, record.moduleName, 'view')} />,
		},
		{
			title: 'Create',
			dataIndex: 'create',
			key: 'create',
			render: (text, record) => <Checkbox checked={text} onChange={(e) => handleCheckboxChange(e, record.moduleName, 'create')} />,
		},
		{
			title: 'Edit',
			dataIndex: 'edit',
			key: 'edit',
			render: (text, record) => <Checkbox checked={text} onChange={(e) => handleCheckboxChange(e, record.moduleName, 'edit')} />,
		},
		{
			title: 'Delete',
			dataIndex: 'delete',
			key: 'delete',
			render: (text, record) => <Checkbox checked={text} onChange={(e) => handleCheckboxChange(e, record.moduleName, 'delete')} />,
		},
	];

	const loading = globalRedux.apiStatus.GET_ACCESS_FOR_COACH === API_STATUS.PENDING;
	
	return (
		<RoleManagementPresentational
			activeCoach={activeCoach}
			setActiveCoach={setActiveCoach}
			columns={columns}
			coaches={coaches}
			loading={loading}
			dataSource={dataSource}
			handleSave={handleSave}
		/>
	);
};

export default RoleManagementFunctional;
