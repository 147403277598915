import React, { useEffect, useMemo } from 'react';
import { Form, Input, Button, Row, Col, Select, InputNumber } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { STATES_CITIES } from 'constants/states-cities';

const AddOrganization = ({ refreshList, closeModal }) => {
	const loginRedux = useSelector((state) => state.loginRedux);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [addFormInstance] = Form.useForm();
	const stateValue = Form.useWatch('state', addFormInstance);
	const dispatch = useDispatch();

	const cities = useMemo(() => {
		addFormInstance.setFieldsValue({
			city: '',
		});
		return STATES_CITIES?.find((obj) => obj?.state === stateValue)?.districts || [];
	}, [stateValue, addFormInstance]);

	const FormCol = {
		labelCol: {
			span: 24,
		},
		wrapperCol: {
			span: 24,
		},
	};

	const onSubmit = (values) => {
		const request = {
			userId: loginRedux?.id,
			branchName: values?.branchName || '',
			gstin: values?.gstin || '',
			location: {
				address: values?.address || '',
				state: values?.state || '',
				city: values?.city || '',
				postalCode: values?.postalCode || '',
			},
		};
		dispatch(postApi(request, 'ADD_BRANCH'));
	};

	useEffect(() => {
		console.log('globalRedux.apiStatus', globalRedux.apiStatus);
		if (globalRedux.apiStatus.ADD_BRANCH === 'SUCCESS') {
			addFormInstance.resetFields();
			refreshList();
			dispatch(resetApiStatus('ADD_BRANCH'));
		}
	}, [globalRedux.apiStatus, dispatch, addFormInstance, refreshList]);

	return (
		<Form
			colon={true}
			layout="vertical"
			labelAlign="left"
			form={addFormInstance}
			onFinish={onSubmit}
			{...FormCol}>
			<Form.Item
				label="Branch Name"
				name="branchName"
				rules={[
					{
						required: true,
						message: 'This Field is Required!',
					},
				]}>
				<Input placeholder="Enter Branch Name" />
			</Form.Item>
			<Form.Item
				label="GSTIN"
				name="gstin"
				rules={[
					{
						required: true,
						message: 'This Field is Required!',
					},
				]}>
				<Input placeholder="Enter GSTIN" />
			</Form.Item>
			<Form.Item
				label="Address"
				name="address"
				rules={[
					{
						required: true,
						message: 'This Field is Required!',
					},
				]}>
				<Input.TextArea placeholder="Enter Address" />
			</Form.Item>
			<Form.Item
				label="State"
				name="state"
				rules={[
					{
						required: true,
						message: 'This Field is Required!',
					},
				]}>
				<Select showSearch placeholder="select state">
					{STATES_CITIES?.map((obj) => (
						<Select.Option value={obj?.state}>{obj?.state}</Select.Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item
				label="City"
				name="city"
				rules={[
					{
						required: true,
						message: 'This Field is Required!',
					},
				]}>
				<Select showSearch placeholder="select city">
					{cities?.map((city) => city && <Select.Option value={city}>{city}</Select.Option>)}
				</Select>
			</Form.Item>
			<Form.Item
				label="Postal Code"
				name="postalCode"
				rules={[
					{
						required: true,
						message: 'This Field is Required!',
					},
				]}>
				<InputNumber style={{ width: '100%' }} placeholder="Enter Postal Code" />
			</Form.Item>
			<Form.Item
				wrapperCol={{
					offset: 0,
					// span: 16,
				}}>
				<Row gutter={[10, 10]}>
					<Col span={12}>
						<Button
							loading={globalRedux.apiStatus.ADD_ITEM === 'PENDING'}
							disabled={globalRedux.apiStatus.ADD_ITEM === 'PENDING'}
							type="primary"
							style={{ width: '100%' }}
							htmlType="submit">
							Create
						</Button>
					</Col>
					<Col span={12}>
						<Button
							style={{ width: '100%' }}
							onClick={() => {
								closeModal();
								addFormInstance.resetFields();
							}}
							danger>
							Cancel
						</Button>
					</Col>
				</Row>
			</Form.Item>
		</Form>
	);
};

export default AddOrganization;
