import React from 'react';
import { Row, Col, Tabs } from 'antd';
import OutstandingListFunctional from './outstanding-list-functional';

const { TabPane } = Tabs;

const OutstandingTab = () => {
	return (
		<Row className="payments-tab-container" style={{ height: '80%' }}>
			<Col xl={24} className="container">
				<Tabs defaultActiveKey="All" destroyInactiveTabPane>
					<TabPane tab={'Memberships outstanding'} key="Memberships">
						<OutstandingListFunctional reportType="membership" />
					</TabPane>
					<TabPane tab={'Products outstanding'} key="Products">
						<OutstandingListFunctional reportType="product" />
					</TabPane>
					<TabPane tab={'Personal Training'} key="Personal Training">
						<OutstandingListFunctional reportType="personalTraining" />
					</TabPane>
				</Tabs>
			</Col>
		</Row>
	);
};

export default OutstandingTab;
