import { Row, Col, Tabs } from 'antd';
import CustomerReportList from './customer-report-list';
import PaymentReportList from './payment-report-list';

const { TabPane } = Tabs;

const MASTER_TAB = [
	{
		tabName: 'Customer wise report',
		component: CustomerReportList,
	},
	{
		tabName: 'Payment wise report',
		component: PaymentReportList,
	},
];

const ReportsTab = () => {
	return (
		<Row style={{ padding: 20 }}>
			<Col xl={24}>
				<Tabs defaultActiveKey="0" destroyInactiveTabPane>
					{MASTER_TAB.map(({ tabName, component }, index) => {
						const Component = component;
						return (
							<TabPane tab={tabName} key={index}>
								<Component />
							</TabPane>
						);
					})}
				</Tabs>
			</Col>
		</Row>
	);
};

export default ReportsTab;
