import { SET_GYM_SB } from "../constants";

function setUsers(data) {
	return {
		type: 'SET_USERS',
		payload: data,
	};
}

function setSelectedUser(data) {
	return {
		type: 'SET_SELECTED_USER',
		payload: data,
	};
}

export const setGymsSB = (data) => {
	return {
		type: SET_GYM_SB,
		payload: data,
	};
}

export { setUsers, setSelectedUser };
