import React from 'react';
import { Button, Col, Row, Tabs } from 'antd';
import TableComponent from 'components/table-component';

const { TabPane } = Tabs;

const RoleManagementPresentational = ({ activeCoach, setActiveCoach, columns, coaches, dataSource, handleSave, loading }) => {
	return (
		<Row>
			<Col span={24} style={{ padding: 20 }}>
				<TableComponent
					className="custom-table"
					style={{ width: '100%' }}
					columns={columns}
					bordered
					rowKey={(record) => record.id}
					dataSource={dataSource}
					pagination={false}
					loading={loading}
					title={() => (
						<Tabs defaultActiveKey={coaches?.[0].id} destroyInactiveTabPane onChange={setActiveCoach} activeCoach={activeCoach}>
							{coaches.map((coach) => (
								<TabPane tab={coach.coachName} key={coach.id}></TabPane>
							))}
						</Tabs>
					)}
					footer={() => (
						<Row justify={'end'}>
							{/* <button className="btn btn-primary">Save</button> */}
							<Col>
								<Button type="primary" onClick={handleSave}>
									Save
								</Button>
							</Col>
						</Row>
					)}
				/>
			</Col>
		</Row>
	);
};

export default RoleManagementPresentational;
