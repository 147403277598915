import React from 'react';
import { Row, Col, Tabs } from 'antd';
import VisitorListFunctional from './visitor-list-functional';

const { TabPane } = Tabs;

const VisitorsTab = () => {
	return (
		<Row className="visitors-tab-container" style={{ height: '80%' }}>
			<Col xl={24} className="container">
				<Tabs defaultActiveKey="All" destroyInactiveTabPane>
					<TabPane tab={'All'} key="All">
						<VisitorListFunctional />
					</TabPane>
					<TabPane tab={'Converted'} key="Converted">
						<VisitorListFunctional converted />
					</TabPane>
				</Tabs>
			</Col>
		</Row>
	);
};

export default VisitorsTab;
