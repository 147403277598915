import React, { useCallback, useEffect } from 'react';
import { Row, Col, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { SERVER_IP } from 'assets/Config';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { formQueryStringFromObject } from 'helpers';
import NoData from 'components/no-data';
import MembershipListFunctional from './membership-list-functional';

const { TabPane } = Tabs;

const MembershipTab = () => {
	const globalRedux = useSelector((state) => state.globalRedux);
	const services = useSelector((state) => state?.serviceRedux?.services || []);
	const branchId = globalRedux?.selectedBranch?.id;
	const dispatch = useDispatch();
	const getServices = useCallback(
		(url) => {
			dispatch(getApi('GET_SERVICES', url));
		},
		[dispatch]
	);

	useEffect(() => {
		const string = formQueryStringFromObject({
			branchId,
		});
		let serviceUrl = `${SERVER_IP}api/services?${string}`;
		getServices(serviceUrl);
	}, [getServices, branchId]);
	return (
		<Row className="membership-tab-container" style={{ height: !!services?.length ? 'auto' : '80%' }}>
			<Col xl={24} className="container">
				{services?.length ? (
					<Tabs defaultActiveKey="0" destroyInactiveTabPane>
						{services?.map(({ serviceName, id }) => (
							<TabPane tab={serviceName} key={id}>
								<MembershipListFunctional serviceId={id} />
							</TabPane>
						))}
					</Tabs>
				) : (
					<NoData />
				)}
			</Col>
		</Row>
	);
};

export default MembershipTab;
